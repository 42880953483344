import styled from 'styled-components'

export const ContainerParallelogram = styled.div`
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;
gap: 5px;
`


export const Parallelogram = styled.div`
margin: 3px 0 5px;
width: 20px;
height: 7px;
-webkit-transform: skew(-20deg);
-moz-transform: skew(-20deg);
-o-transform: skew(-20deg);
//background: #06113C;
background: var(--darkblue);
background-color: ${({itemBackground}) => itemBackground || '#06113C'};
`
export const ParallelogramFree = styled.div`
width: 20px;
height: 7px;
-webkit-transform: skew(-20deg);
-moz-transform: skew(-20deg);
-o-transform: skew(-20deg);
background-color: ${({itemBackground}) => itemBackground || '#ccc'};
`
export default Parallelogram;
