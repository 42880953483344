import styled from 'styled-components'


const HalfWrapper = styled.div`
width: 46%;
@media (max-width: 1220px) {
width: 40%;
    } 
@media (max-width: 1024px) {
width: 43%;
    } 
@media (max-width: 800px) {
width: 98%;
    }  
`;
export default HalfWrapper;
