import styled from 'styled-components'

export const ContainerDots = styled.div`
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;
gap: 5px;
`


export const Dots = styled.div`
width: 10px;
height: 10px;
border-radius: 10px;
margin-top: 3px;
background: ${({itemBackground}) => itemBackground || '#06113C'};
`
export const DotsFree = styled.div`
width: 10px;
height: 10px;
border-radius: 10px;
margin-top: 3px;
background-color: ${({itemBackground}) => itemBackground || '#ccc'};
`
export default Dots;
