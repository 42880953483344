import React from 'react'
import Wrapper from "../form.styles/Wrapper";
import HalfWrapper from "../form.styles/HalfWrapper";
import LabelText from "../form.styles/LabelText";
import InputText from "../form.styles/InputText";
import {ResumeContext} from "../ResumeContext";



const Courses = (props) => {
  const [course, setCourse] = React.useState('');
  const [courseInstitution, setCourseInstitution] = React.useState('');
  const [courseStartDate, setCourseStartDate] = React.useState('');
  const [courseEndDate, setCourseEndDate] = React.useState('');


  const getObj = () => ({
    course,
    courseInstitution,
    courseStartDate,
    courseEndDate
  });
  React.useEffect(() => {
    props.onChange(getObj());
  },[course,
    courseInstitution,
    courseStartDate,
    courseEndDate])

  return(
    <>

      <Wrapper>
        <HalfWrapper>
          <LabelText>School/University</LabelText>
          <InputText
            type='text'
            value={course}
            onChange={(e) => setCourse(e.target.value)}/>
        </HalfWrapper>
        <HalfWrapper>
          <LabelText>Course title</LabelText>
          <InputText
            type='text'
            value={courseInstitution}
            onChange={(e) => setCourseInstitution(e.target.value)}/>
        </HalfWrapper>


      </Wrapper>
      <Wrapper>
        <HalfWrapper>
          <LabelText>Start & End Date</LabelText>
          <HalfWrapper>
            <InputText
              type='month'
              value={courseStartDate}
              onChange={(e) => setCourseStartDate(e.target.value)}/>
          </HalfWrapper>
          <HalfWrapper>
            <InputText
              type='month'
              value={courseEndDate}
              onChange={(e) => setCourseEndDate(e.target.value)}/>
          </HalfWrapper>
        </HalfWrapper>
      </Wrapper>
      </>
  )
}
export default Courses;