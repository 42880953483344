import styled from 'styled-components'


export const Container = styled.div`
padding: 10px;
font-family: 'Times New Roman';
height: ${({itemHeight}) => `${itemHeight || '1123'}px`};
//line-height: 1.6;


p {
font-size: 12pt;
line-height: 14pt;
}

img{
width: 200px;
height: 200px;
border-radius:50%;
object-fit: cover;
}
h2{
font-size: 1.5em;
margin-top: 0;
margin-bottom: 5px;
}
h3{
font-size: 16px;
margin-top: 0;
margin-bottom: -10px;
text-transform: uppercase;
}
h4{
font-size: 16px;
margin-top: 0;
margin-bottom: 10px;
}
strong{
color: ${({itemBackground}) => itemBackground || '#ffaca3'};
font-weight: bold;
}
a{
color: #06113C;
text-decoration: none;
}
`

export const ContainerRow = styled.div`
display: flex;
flex-direction: row;
`
export const ContainerColumn = styled.div`
display: flex;
flex-direction: column;
padding: 10px;
`
export const ColorContainer = styled.div`
// background-color: ${({itemBackground}) => itemBackground || 'burlywood'};
background-color: ${({itemBackground}) => itemBackground || '#ffaca3'};
width: 100%;
`
export const SideContainer = styled.div`
width: 200px;
word-wrap: break-word;
word-break: break-all;
`
export const PaddingContainer = styled.div`
padding: 10px 10px 15px 10px;
`
export const TextCenter = styled.div`
text-align: center;
`

export const ProgressBarWrapper = styled.div`
  background: none;
  padding-top: 3px;
  height: 3px;
  width: 100%;
`

ProgressBarWrapper.ProgressBar = styled.div`
  background-color: black;
  height: 100%;
  width:  ${({itemWidth}) => `${itemWidth || '100'}%`}; 
`
export const SkillsStyle = styled.ul`
padding-left: 15px;
li{

}
`;

