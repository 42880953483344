import React from 'react';
import {ResumeContext} from "../ResumeContext";
import {
  Child, ColorContainer,
  ColorText,
  Container, ContainerColumn, ContainerHeader, ContainerMain,
  ContainerRow, ContainerRowChild, ContainerRowHeader, HeaderChild, HorizontallLine,
  PaddingContainer, PaddingContainerSidebar,
  ProgressBarWrapper, SkillsStyle, StyledFontAwesomeIcon,
  TextCenter, VerticalLine
} from "./Template17Style";
import CenterBlock from "../form.styles/CenterBlock";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarDays, faClock} from "@fortawesome/free-regular-svg-icons";
import {faCar, faEnvelope, faGlobe, faLink, faLocationDot, faPhone} from "@fortawesome/free-solid-svg-icons";
import {ProgressBarBox} from "./Template10Style";
import Parallelogram, {ContainerParallelogram, ParallelogramFree} from "./Parallelogram";
import Dots, {DotsFree} from "./Dots";
import {faGithub, faLinkedin, faTelegram, faTwitter} from "@fortawesome/free-brands-svg-icons";



const Template17 = React.forwardRef((props, ref) => {
  const context = React.useContext(ResumeContext)
  let height

  const [customHeight, setCustomHeight] = React.useState(1122);
  React.useEffect(() => {
    let rect = document.getElementById('container').getBoundingClientRect();
    height = rect.height;
    if(height >= 1123){

      setCustomHeight(2245)
    } else
    if (height < 1123){
      setCustomHeight(1123)
    }
  }, [context]);

  return (
    <>

      <div ref={ref}>
        <Container itemHeight={customHeight}>
          <div id='container'>




            <ContainerRow>
              <ContainerColumn>
                <br/>
                <br/>
                {context.userAvatar === '' ? null
                  : <CenterBlock><img src={context.userAvatar}/></CenterBlock>}
                <ColorContainer>
                  <br/>
                  <h1>{context.userFirstName} {context.userLastName}</h1>
                  <h2>{context.userWantedJobTitle}</h2>
                  <br/>
                </ColorContainer>
              </ContainerColumn>
            </ContainerRow>
            <ContainerRow>


              <ContainerColumn>
                <PaddingContainer>


                  {context.professionalSummary === '' ? null :
                    <><ColorText itemColor={context.textColor}>
                      {/*<hr itemHrColor={context.textColor}/>*/}
                      <h3>Summary</h3>
                    </ColorText>
                      <div dangerouslySetInnerHTML={{__html: context.professionalSummary}}/>
                      <br/>
                    </>}




                  {context.jobsExperiences[0].jobExperienceJobTitle === '' && context.jobsExperiences[0].jobExperienceStartDate === '' && context.jobsExperiences[0].jobExperienceEndDate === '' &&
                  context.jobsExperiences[0].jobExperienceEmployer === '' && context.jobsExperiences[0].jobExperienceCity === '' && context.jobsExperiences[0].jobExperienceDescription === '' ?
                    null
                    :
                    <><HorizontallLine></HorizontallLine>
                      <ColorText itemColor={context.textColor}>
                      {/*<hr itemHrColor={context.textColor}/>*/}
                      <h3>Job Experience</h3>

                    </ColorText>
                      {context.jobsExperiences.map((el, index) => <>

                        <p><b>{el.jobExperienceJobTitle} - {el.jobExperienceEmployer}</b> <br/> {el.jobExperienceStartDate === '' && el.jobExperienceEndDate === '' ?
                          null :
                          <> {new Date(el.jobExperienceStartDate).toLocaleString('en-us',{month:'short', year:'numeric'})} - {new Date(el.jobExperienceEndDate).toLocaleString('en-us',{month:'short', year:'numeric'})}<br/></>}

                          {el.jobExperienceCity}
                          <div dangerouslySetInnerHTML={{__html: el.jobExperienceDescription}}/>

                        </p>

                      </>)} </>}


                  {context.educations[0].educationSchool === '' && context.educations[0].educationCity === '' && context.educations[0].educationDegree === '' &&
                  context.educations[0].educationStartDate === '' && context.educations[0].educationEndDate === '' && context.educations[0].educationDescription === '' ?
                    null
                    :
                    <>
                      <HorizontallLine></HorizontallLine>
                      <ColorText itemColor={context.textColor}>
                        {/*<hr itemHrColor={context.textColor}/>*/}
                        <h3>Education</h3>
                      </ColorText>

                      {context.educations.map((el, index) =>
                        <>

                          <p>
                            <b>{el.educationDegree}</b><br/>
                            {el.educationStartDate === '' && el.educationEndDate === '' ?
                              null :
                              <> {new Date(el.educationStartDate).toLocaleString('en-us',{month:'short', year:'numeric'})} - {new Date(el.educationEndDate).toLocaleString('en-us',{month:'short', year:'numeric'})}<br/></>}
                            {el.educationSchool} {el.educationCity}<br/>
                            <div dangerouslySetInnerHTML={{__html: el.educationDescription}}/>
                          </p>

                        </>)} <br/>
                    </>}


                  {context.internships[0].internshipsJobTitle === '' && context.internships[0].internshipsStartDate === '' && context.internships[0].internshipsEndDate === '' &&
                  context.internships[0].internshipsEmployer === '' && context.internships[0].internshipsCity === '' && context.internships[0].internshipsDescription === '' ?
                    null
                    :
                    <>
                      <HorizontallLine></HorizontallLine>
                      <ColorText itemColor={context.textColor}>
                        {/*<hr itemHrColor={context.textColor}/>*/}
                        <h3>Internships</h3>
                      </ColorText>
                      {context.internships.map((el, index) =>
                        <>

                          <p><b>{el.internshipsJobTitle}</b>
                            {el.internshipsStartDate === '' && el.internshipsEndDate === '' ?
                              null :
                              <> {new Date(el.internshipsStartDate).toLocaleString('en-us',{month:'short', year:'numeric'})} - {new Date(el.internshipsEndDate).toLocaleString('en-us',{month:'short', year:'numeric'})}<br/></>}
                            {el.internshipsEmployer} {el.internshipsCity}
                            <div dangerouslySetInnerHTML={{__html: el.internshipsDescription}}/>
                          </p>

                        </>
                      )}
                    </>}<br/>


                  {context.courses[0].course === '' && context.courses[0].courseInstitution === '' && context.courses[0].courseStartDate === '' &&
                  context.courses[0].courseEndDate === '' ?
                    null
                    :
                    <>
                      <HorizontallLine></HorizontallLine>
                      <ColorText itemColor={context.textColor}>
                        {/*<hr itemHrColor={context.textColor}/>*/}
                        <h3>Courses</h3>

                      </ColorText>
                      {context.courses.map((el, index) => <>

                        <p><b> {el.course}</b> {el.courseStartDate === '' && el.courseEndDate === '' ?
                          null :
                          <> {new Date(el.courseStartDate).toLocaleString('en-us',{month:'short', year:'numeric'})} - {new Date(el.courseEndDate).toLocaleString('en-us',{month:'short', year:'numeric'})}<br/></>}
                          {el.courseInstitution}
                        </p>

                      </>)} <br/>
                    </>}


                  {context.untitled[0].untitledTitle === '' && context.untitled[0].untitledActivity === '' && context.untitled[0].untitledCity === '' &&
                  context.untitled[0].untitledStartDate === '' && context.untitled[0].untitledEndDate === '' && context.untitled[0].untitledDescription === '' ?
                    null
                    : <>
                      <HorizontallLine></HorizontallLine>
                      {context.untitled.map((el, index) => <>
                        <ColorText itemColor={context.textColor}>
                          {/*<hr itemHrColor={context.textColor}/>*/}
                          <h3>{el.untitledTitle}</h3>

                        </ColorText>

                        <p><b>{el.untitledActivity}</b>
                          {el.untitledStartDate === '' && el.untitledEndDate === '' ?
                            null :
                            <> {new Date(el.untitledStartDate).toLocaleString('en-us',{month:'short', year:'numeric'})} - {new Date(el.untitledEndDate).toLocaleString('en-us',{month:'short', year:'numeric'})}<br/></>}
                          {el.untitledCity}
                          <div dangerouslySetInnerHTML={{__html: el.untitledDescription}}/>
                        </p>
                        <br/>
                      </>)}
                    </>}

                  {context.hobby.length > 0 ?
                    <>
                      <HorizontallLine></HorizontallLine>
                      {/*<ColorContainer style={{backgroundColor: `${context.textColor}`}}>*/}
                      <ColorText itemColor={context.textColor}>
                        {/*<hr itemHrColor={context.textColor}/>*/}
                        <h3>Hobbies</h3>

                      </ColorText>
                      <p>
                        <div dangerouslySetInnerHTML={{__html: context.hobby}}/>
                      </p>
                     <br/>
                    </>
                    : null}

                </PaddingContainer>
              </ContainerColumn>


              <VerticalLine></VerticalLine>

              <ContainerColumn itemColumnWidth='400px'>

                <PaddingContainerSidebar>


                  {context.userPhone.length > 0 ?
                    <p><StyledFontAwesomeIcon icon={faPhone}/>   <a href="tel:context.userPhone"> {context.userPhone}</a></p> : null}

                  {context.userEmail === '' ? null :
                    <p><StyledFontAwesomeIcon icon={faEnvelope}/>   <a href="mailto:context.userEmail">{context.userEmail}</a></p>}


                  {context.userLinkedin === '' ? null :
                    <p>    <StyledFontAwesomeIcon icon={faLinkedin}/> {context.userLinkedin}</p>}

                  {context.userTwitter === '' ? null :
                    <p>    <StyledFontAwesomeIcon icon={faTwitter}/> {context.userTwitter}</p>}

                  {context.userTelegram === '' ? null :
                    <p>    <StyledFontAwesomeIcon icon={faTelegram}/> {context.userTelegram}</p>}

                  {context.userGithub === '' ? null :
                    <p>    <StyledFontAwesomeIcon icon={faGithub}/> {context.userGithub}</p>}

                  {context.userWeb === '' ? null :
                    <p>    <StyledFontAwesomeIcon icon={faGlobe}/> {context.userWeb}</p>}


                  {context.userCountry === '' && context.userCity === '' && context.userAddress === '' && context.userPostalCode === '' ?
                    null : <p><StyledFontAwesomeIcon icon={faLocationDot}/>   {context.userCountry} {context.userCity} {context.userAddress} {context.userPostalCode}</p>}


                  {context.userDateOfBirth.length > 0 ?
                    <p><StyledFontAwesomeIcon icon={faCalendarDays}/>   {context.userDateOfBirth}</p> : null}

                  {context.userDrivingLicense === '' ? null :
                    <p><StyledFontAwesomeIcon icon={faCar}/>   Driving licence: {context.userDrivingLicense}</p>}



                  {context.socials.length > 0 ? <>
                    {context.socials.map((el, index) =><p> <a href={el.socialLink} target='_blank'>{el.socialLabel} </a></p>)}
                  </> : null}




                  {context.languages[0].language === '' && context.languages[0].languageLevel === '' ?
                    null
                    :
                    <>
                      <HorizontallLine></HorizontallLine>
                      <ColorText itemColor={context.textColor}>
                        <h3>Languages</h3>
                      </ColorText>
                      {context.languages.map((el, index) => <>
                        {el.language} - {el.languageLevel}
                        {/*<ProgressBarBox>{el.language}*/}
                        {/*  <ProgressBarWrapper>*/}
                        {/*    <ProgressBarWrapper.ProgressBar itemColor={context.textColor}*/}
                        {/*                                    itemWidth={el.languageLevel === 'Beginner' ? 16 :*/}
                        {/*                                      el.languageLevel === 'Pre Intermediate' ? 36 :*/}
                        {/*                                        el.languageLevel === 'Intermediate' ? 60 :*/}
                        {/*                                          el.languageLevel === 'Proficient' ? 80 :*/}
                        {/*                                            el.languageLevel === 'Fluent' ? 92 :*/}
                        {/*                                              el.languageLevel === 'Native' ? 100 : null*/}
                        {/*                                    }*/}
                        {/*      // {el.languageLevel === 'Beginner' ? 16 : null}*/}
                        {/*      //*/}
                        {/*      // 'Beginner', 'Pre Intermediate', 'Intermediate', 'Proficient', 'Fluent', 'Native'*/}
                        {/*    />*/}
                        {/*  </ProgressBarWrapper>*/}
                        {/*</ProgressBarBox> */}
                        <br/>
                      </>)}
                    </>}<br/>


                  {context.skills[0].skill === '' ?
                    null : <>
                      <HorizontallLine></HorizontallLine>
                      <ColorText itemColor={context.textColor}>
                        {/*<hr itemHrColor={context.textColor}/>*/}
                        <h3>Skills</h3>

                      </ColorText>
                      <SkillsStyle>{context.skills.map((el, index) => <>
                        <li>{el.skill}</li>
                      </>)}</SkillsStyle>
                    </>}

                  {context.professionalSkills[0].professionalSkill === '' && context.professionalSkills[0].professionalSkillPercent === '' ?
                    null
                    :
                    <>
                      <HorizontallLine></HorizontallLine>
                      <ColorText itemColor={context.textColor}>
                        {/*<hr itemHrColor={context.textColor}/>*/}
                        <h3>Professional Skills</h3>
                      </ColorText>

                      {context.professionalSkills.map((el, index) => <>
                        {el.professionalSkill}
                        {parseInt(el.professionalSkillPercent) > 0 && parseInt(el.professionalSkillPercent) <= 20 ? <ContainerParallelogram>
                          <Dots itemBackground='#ddc6a4'/>
                          <DotsFree/>
                          <DotsFree/>
                          <DotsFree/>
                          <DotsFree/></ContainerParallelogram> : null}
                        {parseInt(el.professionalSkillPercent) > 21 && parseInt(el.professionalSkillPercent) <= 40 ? <ContainerParallelogram>
                          <Dots itemBackground='#ddc6a4'/>
                          <Dots itemBackground='#ddc6a4'/>
                          <DotsFree/>
                          <DotsFree/>
                          <DotsFree/></ContainerParallelogram> : null}
                        {parseInt(el.professionalSkillPercent) > 41 && parseInt(el.professionalSkillPercent) <= 60 ? <ContainerParallelogram>
                          <Dots itemBackground='#ddc6a4'/>
                          <Dots itemBackground='#ddc6a4'/>
                          <Dots itemBackground='#ddc6a4'/>
                          <DotsFree/>
                          <DotsFree/></ContainerParallelogram> : null}
                        {parseInt(el.professionalSkillPercent) > 61 && parseInt(el.professionalSkillPercent) <= 80 ? <ContainerParallelogram>
                          <Dots itemBackground='#ddc6a4'/>
                          <Dots itemBackground='#ddc6a4'/>
                          <Dots itemBackground='#ddc6a4'/>
                          <Dots itemBackground='#ddc6a4'/>
                          <DotsFree/></ContainerParallelogram> : null}
                        {parseInt(el.professionalSkillPercent) > 81 && parseInt(el.professionalSkillPercent) <= 100 ? <ContainerParallelogram>
                          <Dots itemBackground='#ddc6a4'/>
                          <Dots itemBackground='#ddc6a4'/>
                          <Dots itemBackground='#ddc6a4'/>
                          <Dots itemBackground='#ddc6a4'/>
                          <Dots itemBackground='#ddc6a4'/></ContainerParallelogram> : null}
                      </>)} <br/></> }


                  {/*<h3>Professional skills</h3>*/}
                  {/*<p>{context.professionalSkills.map((el, index) => <>*/}
                  {/*  {el.professionalSkill}*/}
                  {/*  {parseInt(el.professionalSkillPercent) > 0 && parseInt(el.professionalSkillPercent) <= 20 ? <ContainerParallelogram>*/}
                  {/*    <Parallelogram/>*/}
                  {/*    <ParallelogramFree/>*/}
                  {/*    <ParallelogramFree/>*/}
                  {/*    <ParallelogramFree/>*/}
                  {/*    <ParallelogramFree/></ContainerParallelogram> : null}*/}
                  {/*  {parseInt(el.professionalSkillPercent) > 21 && parseInt(el.professionalSkillPercent) <= 40 ? <ContainerParallelogram>*/}
                  {/*    <Parallelogram/>*/}
                  {/*    <Parallelogram/>*/}
                  {/*    <ParallelogramFree/>*/}
                  {/*    <ParallelogramFree/>*/}
                  {/*    <ParallelogramFree/></ContainerParallelogram> : null}*/}
                  {/*  {parseInt(el.professionalSkillPercent) > 41 && parseInt(el.professionalSkillPercent) <= 60 ? <ContainerParallelogram>*/}
                  {/*    <Parallelogram/>*/}
                  {/*    <Parallelogram/>*/}
                  {/*    <Parallelogram/>*/}
                  {/*    <ParallelogramFree/>*/}
                  {/*    <ParallelogramFree/></ContainerParallelogram> : null}*/}
                  {/*  {parseInt(el.professionalSkillPercent) > 61 && parseInt(el.professionalSkillPercent) <= 80 ? <ContainerParallelogram>*/}
                  {/*    <Parallelogram/>*/}
                  {/*    <Parallelogram/>*/}
                  {/*    <Parallelogram/>*/}
                  {/*    <Parallelogram/>*/}
                  {/*    <ParallelogramFree/></ContainerParallelogram> : null}*/}
                  {/*  {parseInt(el.professionalSkillPercent) > 81 && parseInt(el.professionalSkillPercent) <= 100 ? <ContainerParallelogram>*/}
                  {/*    <Parallelogram/>*/}
                  {/*    <Parallelogram/>*/}
                  {/*    <Parallelogram/>*/}
                  {/*    <Parallelogram/>*/}
                  {/*    <Parallelogram/></ContainerParallelogram> : null}*/}
                  {/*</>)}</p></>}*/}




                  {context.references[0].referenceName === '' && context.references[0].referencePosition === '' && context.references[0].referencePhone === '' &&
                  context.references[0].referenceEmail === '' ?
                    null
                    :
                    <>
                      <HorizontallLine></HorizontallLine>
                      <ColorText itemColor={context.textColor}>
                        {/*<hr itemHrColor={context.textColor}/>*/}
                        <h3>References</h3>
                      </ColorText>


                      <div><p> {context.references.map((el, index) => <>
                        <b>{el.referenceName} - {el.referencePosition}</b> <br/>
                        <a href="tel:{el.referencePhone}">{el.referencePhone}</a> - <a href="mailto:el.referenceEmail">{el.referenceEmail}</a><br/><br/>
                      </>)}</p></div>



                    </>}


                </PaddingContainerSidebar>

              </ContainerColumn>


            </ContainerRow>

          </div>
        </Container>
      </div>
    </>
  )
})
export default Template17;