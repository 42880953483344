import styled from 'styled-components'

const InputText = styled.input`
width: 100%;
border-radius: 3px;
padding: 10px 12px;
display: block;
caret-color: var(--orange);
background-color: rgb(239, 242, 249);
outline: currentcolor none medium;
border: 0px none;
transition: color 0.1s ease 0s;
font-size: 14px;
&:focus{
border-bottom: 2px solid var(--orange);
}
`;

export const InputFile = styled.input.attrs({
  type: 'file',
})`
width: 100%;
border-radius: 3px;
padding: 10px 12px;
display: block;
caret-color: var(--orange);
background-color: rgb(239, 242, 249);
outline: currentcolor none medium;
border: 0px none;
transition: color 0.1s ease 0s;
font-size: 14px;
&:focus{
border-bottom: 2px solid var(--orange);
}
`;
export default InputText;
