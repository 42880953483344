import React from 'react';
import jobsData from "../data/JobsData";
import {observer} from "mobx-react-lite";

const CompaniesLogo = () => {
  React.useEffect(() => {
    jobsData.updateLogos();
  }, [])
  let logosNew = jobsData.logos.filter((v,i) => jobsData.logos.indexOf(v) === i)

  // const names = ['John', 'Paul', 'George', 'Ringo', 'John'];
  let newr =  [...new Set(jobsData.logos)]
  console.log(newr.length)

//   let x = (names) => names.filter((v,i) => names.indexOf(v) === i)
//   let are = x(names); // 'John', 'Paul', 'George', 'Ringo'
// console.log(are)
  return(
    <>
      <h1>Companies Logos</h1>
      {/*{arr.map(el => <p>{el}</p>)}*/}
      {/*{x.map(el => <p>{el}</p>)}*/}
      {/*{jobsData.logos.map(el => <img src={el}/>)}*/}
      {newr.map(el => <img src={el}/>)}


      </>
  )
}
export default observer(CompaniesLogo);