import styled from 'styled-components'

export const AccordionContainer = styled.div`
display: flex;
flex-direction: row;

padding: 0 20px;

`;
export const AccordionTitle = styled.button`
  background-color: white;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
`;

export const AccordionBody = styled.div`
  padding: 0 18px;
  background-color: white;
  overflow: hidden;
`

