import React from 'react';
import jobsData from "../data/JobsData";
import {observer} from "mobx-react-lite";
import {Link} from "react-router-dom";


const Categories = () => {
  React.useEffect(() => {
    jobsData.getCategories()
  }, [])
  return(
    <>
      <h1>Categories</h1>
      {/*<p><Link key={job.id} to={`${job.id}`}>View job</Link></p>*/}
      {jobsData.categories.map((category, id) => ( <Link key={category.name} to={`${category.name}`}>{category.name}</Link>))}
      {/*{jobsData.categories.map(category => category)}*/}
      {/*{jobsData.jobs.map((el) => el.category)}*/}
      </>
  )
}
export default observer(Categories);