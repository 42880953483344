import styled from 'styled-components'


const ContainerRowStart = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: flex-start;
`
export default ContainerRowStart;
