import React from 'react'
import Wrapper from "../form.styles/Wrapper";
import HalfWrapper from "../form.styles/HalfWrapper";
import LabelText from "../form.styles/LabelText";
import InputText from "../form.styles/InputText";
import TextEditor from "../editor/TextEditor";
import {ResumeContext} from "../ResumeContext";


const Internships = (props) => {
  const [internshipsJobTitle, setInternshipsJobTitle] = React.useState('');
  const [internshipsEmployer, setInternshipsEmployer] = React.useState('');
  const [internshipsStartDate, setInternshipsStartDate] = React.useState('');
  const [internshipsEndDate, setInternshipsEndDate] = React.useState('');
  const [internshipsCity, setInternshipsCity] = React.useState('');
  const [internshipsDescription, setInternshipsDescription] = React.useState('');


  const getObj = () => ({
    internshipsJobTitle,
    internshipsEmployer,
    internshipsStartDate,
    internshipsEndDate,
    internshipsCity,
    internshipsDescription
  });
  React.useEffect(() => {
    props.onChange(getObj());
  },[internshipsJobTitle,
    internshipsEmployer,
    internshipsStartDate,
    internshipsEndDate,
    internshipsCity,
    internshipsDescription])
  return(
    <>

      <Wrapper>
        <HalfWrapper>
          <LabelText>Job Title</LabelText>
          <InputText
            type='text'
            value={internshipsJobTitle}
            onChange={(e) => setInternshipsJobTitle(e.target.value)}/>
        </HalfWrapper>
        <HalfWrapper>
          <LabelText>Employer</LabelText>
          <InputText
            type='text'
            value={internshipsEmployer}
            onChange={(e) => setInternshipsEmployer(e.target.value)}/>
        </HalfWrapper>


      </Wrapper>
      <Wrapper>
        <HalfWrapper>
          <LabelText>Start & End Date</LabelText>
          <HalfWrapper>
            <InputText
              type='date'
              value={internshipsStartDate}
              onChange={(e) => setInternshipsStartDate(e.target.value)}/>
          </HalfWrapper>
          <HalfWrapper>
            <InputText
              type='date'
              value={internshipsEndDate}
              onChange={(e) => setInternshipsEndDate(e.target.value)}/>
          </HalfWrapper>
        </HalfWrapper>
        <HalfWrapper>
          <LabelText>City</LabelText>
          <InputText
            type='text'
            value={internshipsCity}
            onChange={(e) => setInternshipsCity(e.target.value)}/>
        </HalfWrapper>
      </Wrapper>
      <LabelText>Description</LabelText>

      <div>
        <TextEditor
          html={internshipsDescription}
          onChange={(e) => setInternshipsDescription(e.target.value)}/>
      </div>
      </>
  )
}
export default Internships