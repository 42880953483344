import React from 'react';
import Edit from "./Edit";
import Content from 'react-contenteditable'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faAlignCenter, faAlignJustify, faAlignLeft, faAlignRight,
  faBold, faFont,
  faItalic, faListOl, faListUl,
  faMinus,
  faRotateLeft,
  faStrikethrough, faTextHeight,
  faUnderline
} from "@fortawesome/free-solid-svg-icons";
import ContentStyle from "../form.styles/ContentStyle";

import {ButtonDropdown,
  DropDownHeader,
  DropDownList,
  DropDownListContainer,
  ListItem} from "../form.styles/ButtonDropdown";
import {EditStyleContainer} from "../form.styles/EditStyleContainer";
import EditLink from "./EditLink";

const style = {
  border: 'solid 1px',
  minHeight: '300px',
  borderRadius: 6,
  fontSize: 16,
  padding: 10,
  marginBottom: 10,
}

const TextEditor = (props) => {

  const bold = <FontAwesomeIcon icon={faBold}/>
  const italic = <FontAwesomeIcon icon={faItalic}/>
  const underline = <FontAwesomeIcon icon={faUnderline}/>
  const strikethrough = <FontAwesomeIcon icon={faStrikethrough}/>
  const minus = <FontAwesomeIcon icon={faMinus}/>
  const undo = <FontAwesomeIcon icon={faRotateLeft}/>
  const center = <FontAwesomeIcon icon={faAlignCenter}/>
  const justify = <FontAwesomeIcon icon={faAlignJustify}/>
  const left = <FontAwesomeIcon icon={faAlignLeft}/>
  const right = <FontAwesomeIcon icon={faAlignRight}/>
  const unorderedList = <FontAwesomeIcon icon={faListUl}/>
  const orderedList = <FontAwesomeIcon icon={faListOl}/>
  const fontFamily = <FontAwesomeIcon icon={faFont}/>
  const fontSize = <FontAwesomeIcon icon={faTextHeight}/>





  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState(null);
  const toggling = () => {
    setIsOpen(!isOpen);
  };
  const [isOpenFontSize, setIsOpenFontSize] = React.useState(false);
  const [selectedOptionFontSize, setSelectedOptionFontSize] = React.useState(null);
  const togglingFontSize = () => {
    setIsOpenFontSize(!isOpenFontSize);
  };

  const ref = React.useRef()
  const refFontSize = React.useRef()

  React.useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isOpen && ref.current && !ref.current.contains(e.target)) {
        setIsOpen(false)
      }
      if (isOpenFontSize && refFontSize.current && !refFontSize.current.contains(e.target)) {
        setIsOpenFontSize(false)
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [isOpen, isOpenFontSize])


  // React.useEffect(() => {
  //   const checkIfClickedOutside = e => {
  //     // If the menu is open and the clicked target is not within the menu,
  //     // then close the menu
  //     if (isOpenFontSize && ref.current && !ref.current.contains(e.target)) {
  //       setIsOpenFontSize(false)
  //     }
  //   }
  //   document.addEventListener("mousedown", checkIfClickedOutside)
  //   return () => {
  //     // Cleanup the event listener
  //     document.removeEventListener("mousedown", checkIfClickedOutside)
  //   }
  // }, [isOpenFontSize])

  return(
    <>
      <EditStyleContainer>
      <Edit tooltip='Bold' name={bold} type="bold"/>
      <Edit tooltip='Italic' name={italic} type="italic"/>
      <Edit tooltip='Underline' name={underline} type="underline"/>
      <Edit tooltip='Strike through' name={strikethrough} type="strikethrough"/>
      <Edit tooltip='Unordered list' name={unorderedList} type="insertUnorderedList"/>
      <Edit tooltip='Ordered list' name={orderedList} type="insertOrderedList"/>
      <Edit tooltip='Align justify' name={center} type="justifyCenter"/>
      <Edit tooltip='Align center' name={justify} type="justifyFull"/>
      <Edit tooltip='Align left' name={left} type="justifyLeft"/>
      <Edit tooltip='Align right' name={right} type="justifyRight"/>
      <Edit tooltip='Undo' name={undo} type="undo"/>
      {/*<Edit tooltip='Font family' name={fontFamily} type="fontName"/>*/}

        <div className="hover-text">
        <ButtonDropdown ref={ref}>
          <DropDownHeader onClick={toggling}>
            {fontFamily}
          </DropDownHeader>
          {isOpen && (
            <DropDownListContainer>
              <DropDownList>
            <ListItem><EditLink tooltip='Font family' name='Arial' type="fontName" el={'Arial'}/></ListItem>
            <ListItem><EditLink tooltip='Font family' name='Times New Roman' type="fontName" el={'Times New Roman'}/></ListItem>
            <ListItem><EditLink tooltip='Font family' name='Lucida Console' type="fontName" el={'Lucida Console'}/></ListItem>
            <ListItem><EditLink tooltip='Font family' name='Verdana' type="fontName" el={'Verdana'}/></ListItem>
            <ListItem><EditLink tooltip='Font family' name='Helvetica' type="fontName" el={'Helvetica'}/></ListItem>
            <ListItem><EditLink tooltip='Font family' name='Tahoma' type="fontName" el={'Tahoma'}/></ListItem>
            <ListItem><EditLink tooltip='Font family' name='Trebuchet MS' type="fontName" el={'Trebuchet MS'}/></ListItem>
            <ListItem><EditLink tooltip='Font family' name='Georgia' type="fontName" el={'Georgia'}/></ListItem>
            <ListItem><EditLink tooltip='Font family' name='Garamond' type="fontName" el={'Garamond'}/></ListItem>
            <ListItem><EditLink tooltip='Font family' name='Courier New' type="fontName" el={'Courier New'}/></ListItem>
            <ListItem><EditLink tooltip='Font family' name='Brush Script MT' type="fontName" el={'Brush Script MT'}/></ListItem>
              </DropDownList>
            </DropDownListContainer>
          )}
        </ButtonDropdown>
          <span className="tooltip-text bottom">Font family</span>
        </div>
        <div className="hover-text">
        <ButtonDropdown ref={refFontSize}>
          <DropDownHeader onClick={togglingFontSize}>
            {fontSize}
            <span className="tooltip-text bottom">Font size</span>
          </DropDownHeader>
          {isOpenFontSize && (
            <DropDownListContainer>
              <DropDownList>
                <ListItem><EditLink name='8pt' type="fontSize" el={'1'}/></ListItem>
                <ListItem><EditLink name='10pt' type="fontSize" el={'2'}/></ListItem>
                <ListItem><EditLink name='12pt' type="fontSize" el={'3'}/></ListItem>
                <ListItem><EditLink name='14pt' type="fontSize" el={'4'}/></ListItem>
                <ListItem><EditLink name='16pt' type="fontSize" el={'5'}/></ListItem>
              </DropDownList>
            </DropDownListContainer>
          )}
        </ButtonDropdown>
          <span className="tooltip-text bottom">font size</span>
        </div>
      </EditStyleContainer>

      <ContentStyle
        contentEditable
        onChange={props.onChange}
        html={props.html}
      />


      </>
  )
}
export default TextEditor;