import styled from 'styled-components'

export const LoadMoreBox = styled.div`
width: 100%;
display: flex;
flex-direction: row;
justify-content: center;
`

export const ButtonLoadMore = styled.button`
width: 200px;
background-color: var(--orange);
border: none;
color: white;
padding: 10px;
font-size: 16px;
font-weight: 600;
margin-top: 30px;
margin-left: auto;
margin-right: auto;

&:hover{
background-position: 100% 0;
  background-image: linear-gradient(to right , #f5ce62, #FF8C32);
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
  font-weight: bold;
}
`;