import React from 'react'
import LabelText from "../form.styles/LabelText";
import InputText from "../form.styles/InputText";
import HalfWrapper from "../form.styles/HalfWrapper";
import {ResumeContext} from "../ResumeContext";

const Skills = (props) => {
  const [skill, setSkill] = React.useState('');

  const getObj = () => ({
    skill
  });
  React.useEffect(() => {
    props.onChange(getObj());
  },[skill])

  return(
    <>

      <LabelText>Skill name</LabelText>
      <InputText
        type='text'
        value={skill}
        onChange={(e) => setSkill(e.target.value)}/>

    </>
  )
}
export default Skills;