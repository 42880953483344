import styled from 'styled-components'
import {Link} from "react-router-dom";

export const Breadcrumbs = styled.div`
padding: 10px 0;
font-size: 12px;
color:var(darkgrey);
`;
Breadcrumbs.Link = styled(Link)`
text-decoration: none;
color:var(darkgrey);
padding: 0 10px;
&:hover{
color:var(--orange);
text-decoration: underline;
}
&:active{
text-decoration: underline;
}
`
export default Breadcrumbs;
