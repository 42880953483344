import React from 'react'
import Container from "../styles/Container";
import ContainerRow from "../styles/ContainerRow";
import Column from "../styles/Column";
import CardList from "../styles/CardList";
import TextOrange from "../styles/TextOrange";
import img1 from "./images/foto6.jpg";
import {BlogImage} from "./BlogImage";
import img2 from './images/foto1.jpg'
import img3 from "./images/foto3.jpg";
import img4 from "./images/foto9.jpg";
import img5 from "./images/foto13.jpg";
import img6 from "./images/foto14.jpg";
import {ContainerRowMain, ContainerRowMainReverse} from "../styles/ContainerRowMain";
import {Link} from "react-router-dom";
import ButtonOrange from "../styles/ButtonOrange";
import createresume from "../images/create-resume.png";
import findjob from "../images/find-job.png";
import companies from "../images/companies.png";
import blog from "../images/blog.png";
import LinkStyle from "../styles/LinkStyle";
import NavStyle from "../header/NavStyle";
import Breadcrumbs from "../styles/Breadcrumbs";
import Helmet from "react-helmet";

const Blog = () => {
  const onClickToTop = () => {
    window.scrollTo(0,0);
  }

  return(
    <>
      <Helmet>
        <title>Top Work | Blog</title>
        <meta name="description" content="Useful articles on resume writing, interview questions and much more for your successful employment" />
        <meta name='keywords' content="Interview Questions, Resume Tips, interview skills, Job Template, skills for resumes, Hired Now"/>
      </Helmet>
      <Container>
        <Breadcrumbs>
          <Breadcrumbs.Link to='/'>Home</Breadcrumbs.Link> >
          <Breadcrumbs.Link to=''>Blog</Breadcrumbs.Link>
        </Breadcrumbs>
        <h1>Blog</h1>
        <ContainerRow>
          <Column itemWidth='31%'>
            <LinkStyle to='./interview-questions' onClick={onClickToTop}><CardList>
              <BlogImage src={img1}/>
              <h2>Interview Questions</h2>
            </CardList></LinkStyle>
          </Column>
          <Column itemWidth='31%'>
            <LinkStyle to='./resume-tips' onClick={onClickToTop}><CardList>
              <BlogImage src={img2}/>
              <h2>The Best Resume Tips</h2>
            </CardList></LinkStyle>
          </Column>
          <Column itemWidth='31%'>
            <LinkStyle to='./interview-skills' onClick={onClickToTop}><CardList>
              <BlogImage src={img3}/>
              <h2>7 interview skills </h2>
            </CardList></LinkStyle>
          </Column>
        </ContainerRow>


        <ContainerRow>
          <Column itemWidth='31%'>
            <LinkStyle to='./job-template' onClick={onClickToTop}> <CardList>

              <BlogImage src={img4}/>
              <h2>Job Template</h2>
            </CardList></LinkStyle>
          </Column>
          <Column itemWidth='31%'>
            <LinkStyle to='./skills-for-resume' onClick={onClickToTop}><CardList>
              <BlogImage src={img5}/>
              <h2>Top 10 skills for resumes</h2>
            </CardList></LinkStyle>
          </Column>
          <Column itemWidth='31%'>
            <LinkStyle to='./hired-now' onClick={onClickToTop}><CardList>
              <BlogImage src={img6}/>
              <h2>How To Get Hired Now</h2>
            </CardList></LinkStyle>
          </Column>
        </ContainerRow>




      </Container>

      </>
  )
}
export default Blog;