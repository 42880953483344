import styled from 'styled-components'


const ButtonEdit = styled.button`
background-color: rgb(239, 242, 249);
border: 1px solid var(--grey);
border-bottom: 2px solid var(--darkgrey);
color: var(--darkblue);
padding: 7px;
&:hover{
color:var(--orange);
}
`;
export default ButtonEdit;
