import styled from 'styled-components'


const StickyContainer = styled.div`
position: fixed;
background-color: var(--grey);
padding: 20px;
min-width: 20%;

img{
display: block;
margin-left: auto;
margin-right: auto;
}
  @media(max-width: 1100px) {
position: relative;
width: 90%;

  }
`


export default StickyContainer;
