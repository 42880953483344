import styled from 'styled-components'


export const Container = styled.div`

font-family: 'Arial';
font-size: 10pt;
//line-height: 1.6;
height: ${({itemHeight}) => `${itemHeight || '1123'}px`};

p {
font-size: 10pt;
line-height: 14pt;
}

img{
width: 90px;
object-fit: cover;
//margin-top: 10px;
display: block;
margin-left: auto;
margin-right: auto;

}
h2{
font-size: 2em;
font-weight: bold;
margin-top: 0;
margin-bottom: 0px;
text-transform: uppercase;
}
h3{
font-size: 1.2em;
font-weight: 900;
margin-top: 0;
margin-bottom: 10px;
margin-left: 0em;
text-transform: uppercase;
}
h4{
font-size: 16pt;
margin-top: 0;
margin-bottom: 10px;
}
hr{
border-top: 1px solid;
border-color: ${({itemHrColor}) => itemHrColor || '#476d7b'};
margin-bottom: 15px;
margin-top: 15px;
}
a{
text-decoration: none;
color: #393E46;
}
strong{
color: #393E46;
}
`
export const ContainerMain = styled.div`
padding: 20px 70px 70px 70px;
`
export const ContainerHeader = styled.div`
padding: 50px 70px 10px 70px;

a{
text-decoration: none;
color: white;
}
`
export const ContainerRow = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
gap: 20px;
`
export const ContainerRowHeader = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: space-between;
width: 100%;

//gap: 10px 0px;
`
export const HeaderChild = styled.div`
width: 31%;
text-align: center;
padding-top: 10px;
`

export const ContainerRowChild = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: space-between;
width: 100%;
gap: 10px 0px;
`
export const Child = styled.div`
margin-left: 0px;
margin-right: 10px;
width: 45%;
`
export const ContainerColumn = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
width: ${({itemColumnWidth}) => itemColumnWidth || '100%'};

`
export const ColorContainer = styled.div`
// background-color: ${({itemBackground}) => itemBackground || 'burlywood'};
background-color: ${({itemBackground}) => itemBackground || '#476d7b'};
width: 100%;
bottom: 0;
color: white;

hr{
border-top: 1px solid;
border-color: ${({itemHrColor}) => itemHrColor || 'white'};
margin-bottom: 10px;
margin-top: 15px;
}
`
export const ColorText = styled.div`
// background-color: ${({itemBackground}) => itemBackground || 'burlywood'};
color: ${({itemColor}) => itemColor || '#476d7b'};
width: 100%;
`
export const PaddingContainer = styled.div`
padding: 5px 10px;
`
export const TextCenter = styled.div`
text-align: center;
`

export const ProgressBarWrapper = styled.div`
  background: lightgrey;
  margin-top: 5px;
  height: 5px;
  width: 100%;
`

ProgressBarWrapper.ProgressBar = styled.div`
  background-color: ${({itemColor}) => itemColor || '#476d7b'};
  height: 5px;
  width:  ${({itemWidth}) => `${itemWidth || '100'}%`}; 
`
export const SkillsStyle = styled.ul`
padding-left: 15px;
margin-top: 0px;
li{
padding-left: 0px;
}
`
const Section = styled.div`
padding: 20px 0;
`
;