import React from 'react';
import {observer} from "mobx-react-lite";
import jobsData from "../data/JobsData";
import ContainerRow from "../styles/ContainerRow";
import CardList from "../styles/CardList";
import {Link} from "react-router-dom";
import LinkStyle from "../styles/LinkStyle";
import ButtonOrange from "../styles/ButtonOrange";
import ContainerRowCompanies from "../styles/ContainerRowCompanies";
import Container from "../styles/Container";
import Breadcrumbs from "../styles/Breadcrumbs";
import Helmet from "react-helmet";
import {ResumeContext} from "../exportpdf/ResumeContext";
import InputText from "../exportpdf/form.styles/InputText";
import {InputSearch, SearchBar, SearchIcon} from "../exportpdf/form.styles/InputSearch";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass, faPhone} from "@fortawesome/free-solid-svg-icons";
import TextOrange from "../styles/TextOrange";
import {ButtonLoadMore, LoadMoreBox} from "../exportpdf/form.styles/ButtonLoadMore";




const itemPerRow = 24;

const CompaniesList = () => {
  const [next, setNext] = React.useState(itemPerRow);
  const [query, setQuery] = React.useState("");

  const handleMoreItems = () => {
    setNext(next + itemPerRow);
  }

  React.useEffect(() => {
    jobsData.updateCompanies();
  }, [])

  return(
    <>
      <Helmet>
        <title>Top Work | Companies</title>
        <meta name="description" content="You can find a huge list of international companies in different fields that are looking for you!" />
        <meta name='keywords' content="companies, company, find company, find work, remote job, famous companies"/>
      </Helmet>
      <Container>
        <Breadcrumbs>
          <Breadcrumbs.Link to='/'>Home</Breadcrumbs.Link> >
          <Breadcrumbs.Link to=''>Companies</Breadcrumbs.Link>
        </Breadcrumbs>
        <SearchBar>
          <SearchIcon><TextOrange><FontAwesomeIcon icon={faMagnifyingGlass}/></TextOrange></SearchIcon>
          <InputSearch placeholder="Search..." onChange={event => setQuery(event.target.value)}/>
        </SearchBar>

      <h1> Companies</h1>
      <ContainerRowCompanies>
        {jobsData.companies.filter(company => {
          if (query === '') {
            return company;
          } else if (company.name.toLowerCase().includes(query.toLowerCase().trim()) ) {
            return company;
          }
        }).slice(0,next).map(company =>
          <LinkStyle key={company.name} to={`./${company.name}`}>
            <CardList>
            <CardList.Company>
            <img src={company.logo}/>
              <h4>{company.name}</h4>
            {/*<p>{company.category}</p>*/}
            {/*<p>{company.job}</p>*/}
              <Link key={company.name} to={`./${company.name}`}><ButtonOrange>VIEW JOBS</ButtonOrange></Link>
            </CardList.Company>
          </CardList></LinkStyle>
        )}

      </ContainerRowCompanies>
        <LoadMoreBox>{next < jobsData.companies.length && (
          <ButtonLoadMore onClick={handleMoreItems}>Load more...</ButtonLoadMore>
        )}
        </LoadMoreBox>
      </Container>
      </>
  )
}
export default observer(CompaniesList)