import React from 'react'
import ContainerRow from "../../styles/ContainerRow";
import Column from "../../styles/Column";
import Wrapper from "../form.styles/Wrapper";
import HalfWrapper from "../form.styles/HalfWrapper";
import LabelText from "../form.styles/LabelText";
import InputText from "../form.styles/InputText";
import TextEditor from "../editor/TextEditor";
import ContentStyle from "../form.styles/ContentStyle";
import {ResumeContext} from "../ResumeContext";

const EmploymentHistory = (props) => {
  const [jobExperienceJobTitle, setJobExperienceJobTitle] = React.useState('');
  const [jobExperienceEmployer, setJobExperienceEmployer] = React.useState('');
  const [jobExperienceStartDate, setJobExperienceStartDate] = React.useState('');
  const [jobExperienceEndDate, setJobExperienceEndDate] = React.useState('');
  const [jobExperienceCity, setJobExperienceCity] = React.useState('');
  const [jobExperienceDescription, setJobExperienceDescription] = React.useState('');

  const getObj = () => ({
    jobExperienceJobTitle,
    jobExperienceEmployer,
    jobExperienceStartDate,
    jobExperienceEndDate,
    jobExperienceCity,
    jobExperienceDescription,
  });
  React.useEffect(() => {
    props.onChange(getObj());
  },[jobExperienceJobTitle,
    jobExperienceEmployer,
    jobExperienceStartDate,
    jobExperienceEndDate,
    jobExperienceCity,
    jobExperienceDescription])

  return (
    <>

      <Wrapper>
        <HalfWrapper>
          <LabelText>Job Title</LabelText>
          <InputText
            type='text'
            value={jobExperienceJobTitle}
            onChange={(e) => setJobExperienceJobTitle(e.target.value)}/>
        </HalfWrapper>
        <HalfWrapper>
          <LabelText>Employer</LabelText>
          <InputText
            type='text'
            value={jobExperienceEmployer}
            onChange={(e) => setJobExperienceEmployer(e.target.value)}/>
        </HalfWrapper>


      </Wrapper>
      <Wrapper>
        <HalfWrapper>
          <LabelText>Start & End Date</LabelText>
          <HalfWrapper>
            <InputText
              type='month'
              value={jobExperienceStartDate}
              onChange={(e) => setJobExperienceStartDate(e.target.value)}/>
          </HalfWrapper>
          <HalfWrapper>
            <InputText
              type='month'
              value={jobExperienceEndDate}
              onChange={(e) => setJobExperienceEndDate(e.target.value)}/>
          </HalfWrapper>
        </HalfWrapper>
        <HalfWrapper>
          <LabelText>City</LabelText>
          <InputText
            type='text'
            value={jobExperienceCity}
            onChange={(e) => setJobExperienceCity(e.target.value)}/>
        </HalfWrapper>
      </Wrapper>
      <LabelText>Description</LabelText>
      {/*<div>*/}
      {/*  <TextEditor onChange={props.handleChange}*/}
      {/*              html={props.html}/>*/}
      {/*</div>*/}

      <div>
        <TextEditor
          html={jobExperienceDescription}
          onChange={(e) => setJobExperienceDescription(e.target.value)}/>
      </div>


    </>
  )
}
export default EmploymentHistory;