import React from 'react';
import jobsData from "../data/JobsData";
import {observer} from "mobx-react-lite";
import CardCategory from "../styles/CardCategory";
import ContainerRowStart from "../styles/ContainerRowStart";
import ButtonOrange from "../styles/ButtonOrange";
import {Link} from "react-router-dom";


const CategoriesName = () => {
  return(
    <>
      <ContainerRowStart>
        {jobsData.categoriesName.map(el => el == 'All categories' ? <Link key={el} to={`categories`}><CardCategory>{el}</CardCategory></Link>
          : <Link key={el} to={`/categories/${el.replace('/ ',' ')}`}><CardCategory>{el}</CardCategory></Link>)}
      </ContainerRowStart>
      </>
  )
}
export default observer(CategoriesName);