import React from 'react'
import Wrapper from "../form.styles/Wrapper";
import HalfWrapper from "../form.styles/HalfWrapper";
import LabelText from "../form.styles/LabelText";
import InputText from "../form.styles/InputText";
import {ResumeContext} from "../ResumeContext";

const Social = (props) => {
  const [socialLabel, setSocialLabel] = React.useState('');
  const [socialLink, setSocialLink] = React.useState('');

  const getObj = () => ({
    socialLabel,
    socialLink
  });
  React.useEffect(() => {
    props.onChange(getObj());
  },[socialLabel,
    socialLink])

  return(
    <>

      <Wrapper>
        <HalfWrapper>
          <LabelText>Label</LabelText>
          <InputText
            type='text'
            value={socialLabel}
            onChange={(e) => setSocialLabel(e.target.value)}/>
        </HalfWrapper>
        <HalfWrapper>
          <LabelText>Link</LabelText>
          <InputText
            type='text'
            value={socialLink}
            onChange={(e) => setSocialLink(e.target.value)}/>
        </HalfWrapper>
      </Wrapper>
      </>
  )
}
export default Social;