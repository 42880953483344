import React from 'react';
import jobsData from "../data/JobsData";
import {observer} from "mobx-react-lite";
import ContainerRow from "../styles/ContainerRow";
import CardLogo from "../styles/CardLogo";


const CompaniesName = () => {

  React.useEffect(() => {
    jobsData.updateCompanies();
  }, [])


let countArray = jobsData.companies.length;
  return (
    <>
      <h1>{countArray} companies</h1>
      {/*{arr.map(el => <p>{el}</p>)}*/}
      <CardLogo>{jobsData.companies.map(el => <CardLogo.Card><img src={el.logo}/><p>{el.name}</p></CardLogo.Card>)}</CardLogo>
      {/*{jobsData.companies.filter((v,i) => jobsData.companies.indexOf(v) === i)}*/}

    </>
  )
}
export default observer(CompaniesName);