import React from 'react';
import {useParams} from "react-router";
import jobsData from "../data/JobsData";
import {observer} from "mobx-react-lite";
import Container from "../styles/Container";
import ContainerRowTop from "../styles/ContainerRowTop";
import Column from "../styles/Column";
import TextOrange from "../styles/TextOrange";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCircleCheck,
  faClock,
  faEarthAmericas,
  faFileInvoiceDollar
} from "@fortawesome/free-solid-svg-icons";
import CardList from "../styles/CardList";
import {faCalendarDays} from "@fortawesome/free-regular-svg-icons";
import {Link} from "react-router-dom";
import CompanyProfileStyle from "../styles/CompanyProfileStyle";
import ButtonOrange from "../styles/ButtonOrange";
import LinkStyle from "../styles/LinkStyle";
import Breadcrumbs from "../styles/Breadcrumbs";

const OneCompany = () => {
  let {company} = useParams();
  const [companyJobs, setCompanyJobs] = React.useState();


  React.useEffect(() => {
    jobsData.getCompanyJobs(company).then(setCompanyJobs)
  }, [company])

  if (!companyJobs) return null;

  const companyObject = companyJobs.find(job => job.company_name === company)
  return (
    <>
      <Container>
        <Breadcrumbs>
          <Breadcrumbs.Link to='/'>Home</Breadcrumbs.Link> >
          <Breadcrumbs.Link to='/companies'>Companies</Breadcrumbs.Link> >
          <Breadcrumbs.Link to=''>{company}</Breadcrumbs.Link>
        </Breadcrumbs>
        <CompanyProfileStyle>

          <h1>{company}</h1>
          <img src={companyObject.company_logo}/>

        </CompanyProfileStyle>
        <h2>{companyJobs.length} jobs</h2>

        {companyJobs.map(job =>
          <LinkStyle key={job.id} to={`/jobs-list/${job.id}`}>
            <CardList>
          <ContainerRowTop>
            <Column itemWidth='80%'>
              <h2>{job.title}</h2>
              <h4><TextOrange><FontAwesomeIcon icon={faBuilding}/></TextOrange> {job.company_name}</h4>
              {job.candidate_required_location !== '' ? <p> <TextOrange><FontAwesomeIcon icon={faEarthAmericas}/></TextOrange>  {job.candidate_required_location}</p> : null}
              <p><TextOrange><FontAwesomeIcon icon={faCircleCheck}/> {job.category}</TextOrange></p>
            </Column>
            <Column itemWidth='20%'>
              <CardList.Logo> <img src={job.company_logo}/></CardList.Logo>
            </Column>
          </ContainerRowTop>
          <ContainerRowTop>
            <Column itemWidth='50%'>
              {job.job_type !== '' ? <p><TextOrange><FontAwesomeIcon icon={faClock}/></TextOrange> {job.job_type.replace('_',' ')}</p> : null}
              <p><TextOrange><FontAwesomeIcon icon={faCalendarDays}/></TextOrange> Published: {job.publication_date.slice(0, 10)}</p>
            </Column>
            <Column itemWidth='20%'>
              <p><Link key={job.id} to={`/jobs-list/${job.id}`}><ButtonOrange>VIEW JOB</ButtonOrange></Link></p>
            </Column>
          </ContainerRowTop>
          <Column>
            {job.salary !== '' ? <p><TextOrange><FontAwesomeIcon icon={faFileInvoiceDollar}/></TextOrange> {job.salary}</p> : null}
          </Column>
          {/*<CardList.TagsContainer>*/}
          {/*  {job.tags.map((tag) => <li>{tag}</li>)}*/}
          {/*</CardList.TagsContainer>*/}
        </CardList>
          </LinkStyle>)}



      </Container>


    </>
  )
}
export default observer(OneCompany);