import styled from 'styled-components'

const ButtonOrange = styled.button`
background-color: var(--orange);
border: none;
color: white;
padding: 10px;
font-size: 16px;
font-weight: 600;
width: ${({itemWidth}) => itemWidth || '100%'};
margin: auto;

&:hover{
background-position: 100% 0;
  background-image: linear-gradient(to right , #f5ce62, #FF8C32);
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
  font-weight: bold;
}
`;
export default ButtonOrange;
