import styled from 'styled-components'


export const ButtonDropdown = styled("span")`
position: relative;
  //width: 10.5em;
  //margin: 0 auto;
`;

export const DropDownHeader = styled("button")`

background-color: rgb(239, 242, 249);
border: 1px solid var(--grey);
border-bottom: 2px solid var(--darkgrey);
color: var(--darkblue);
padding: 7px;
&:hover{
color:var(--orange);
}
`;

export const DropDownListContainer = styled("div")`
z-index: 1;
position: absolute;
width: 200px;
`;

export const DropDownList = styled("ul")`
background-color: rgb(239, 242, 249);
width: 100%;
  padding: 0;
  margin: 0;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  color: black;
 
  &:first-child {
    padding: 10px 12px;
  }
`;

export const ListItem = styled("li")`
list-style: none;
 &:hover{
color: var(--orange);
  }
`;
