import React from "react"

export const ResumeContext = React.createContext({
  //PERSONAL INFORMATION
  userWantedJobTitle: '',
  setUserWantedJobTitle: (newUserWantedJobTitle) => {},
  userAvatar: '',
  setUserAvatar: (newUserAvatar) => {},
  userFirstName: '',
  setUserFirstName: (newUserFirstName) => {},
  userLastName: '',
  setUserLastName: (newUserLastName) => {},
  userEmail: '',
  setUserEmail: (newUserEmail) => {},
  userPhone: '',
  setUserPhone: (newUserPhone) => {},
  userCountry: '',
  setUserCountry: (newUserCountry) => {},
  userCity: '',
  setUserCity: (newUserCity) => {},
  userAddress: '',
  setUserAddress: (newUserAddress) => {},
  userPostalCode: '',
  setUserPostalCode: (newUserPostalCode) => {},
  userDrivingLicense: '',
  setUserDrivingLicense: (newUserDrivingLicense) => {},
  // userNationality: '',
  // setUserNationality: (newUserNationality) => {},
  // userPlaceOfBirth: '',
  // setUserPlaceOfBirth: (newUserPlaceOfBirth) => {},
  userDateOfBirth: '',
  setUserDateOfBirth: (newUserDateOfBirth) => {},
  userLinkedin: '',
  setUserLinkedin: (newUserLinkedin) => {},
  userTwitter: '',
  setUserTwitter: (newUserTwitter) => {},
  userWeb: '',
  setUserWeb: (newUserWeb) => {},
  userTelegram: '',
  setUserTelegram: (newUserTelegram) => {},
  userGithub: '',
  setUserGithub: (newUserGithub) => {},

  //PROFESSIONAL SUMMARY
  professionalSummary: '',
  setProfessionalSummary: (newProfessionalSummary) => {},

  //JOB EXPERIENCE
  // jobExperienceJobTitle: '',
  // setJobExperienceJobTitle: (newJobExperienceJobTitle) => {},
  // jobExperienceEmployer: '',
  // setJobExperienceEmployer: (newJobExperienceEmployer) => {},
  // jobExperienceStartDate: '',
  // setJobExperienceStartDate: (newJobExperienceStartDate) => {},
  // jobExperienceEndDate: '',
  // setJobExperienceEndDate: (newJobExperienceEndDate) => {},
  // jobExperienceCity: '',
  // setJobExperienceCity: (newJobExperienceCity) => {},
  // jobExperienceDescription: '',
  // setJobExperienceDescription: (newJobExperienceDescription) => {},

  //EXPERIENCES
  jobsExperiences: [],
  setJobsExperiences: (newJobsExperiences) => [],
  // jobExperience: {},
  // setJobExperience: (newJobsExperiences) => {},

  //EDUCATION EXPERIENCE
  educations: [],
  setEducations: (newEducations) => [],
  // educationSchool: '',
  // setEducationSchool: (newEducationSchool) => {},
  // educationDegree: '',
  // setEducationDegree: (newEducationDegree) => {},
  // educationStartDate: '',
  // setEducationStartDate: (newEducationStartDate) => {},
  // educationEndDate: '',
  // setEducationEndDate: (newEducationEndDate) => {},
  // educationCity: '',
  // setEducationCity: (newEducationCity) => {},
  // educationDescription: '',
  // setEducationDescription: (newEducationDescription) => {},

  //INTERNSHIPS
  internships: [],
  setInternships: (newInternships) => [],
  // internshipsJobTitle: '',
  // setInternshipsJobTitle: (newInternshipsJobTitle) => {},
  // internshipsEmployer: '',
  // setInternshipsEmployer: (newInternshipsEmployer) => {},
  // internshipsStartDate: '',
  // setInternshipsStartDate: (newInternshipsStartDate) => {},
  // internshipsEndDate: '',
  // setInternshipsEndDate: (newInternshipsEndDate) => {},
  // internshipsCity: '',
  // setInternshipsCity: (newInternshipsCity) => {},
  // internshipsDescription: '',
  // setInternshipsDescription: (newInternshipsDescription) => {},

  //SOCIAL
  socials: [],
  setSocials: (newSocials) => [],
  // socialLabel: '',
  // setSocialLabel: (newSocialLabel) => {},
  // socialLink: '',
  // setSocialLink: (newSocialLink) => {},


  //LANGUAGES
  languages: [],
  setLanguages: (newLanguages) => [],
  // language: '',
  // setLanguage: (newLanguage) => {},
  // languageLevel: '',
  // setLanguageLevel: (newLanguageLevel) => {},


  //SKILLS
  skills: '',
  setSkills: (newSkills) => {},

  //PROFESSIONAL SKILLS
  professionalSkills: [],
  setProfessionalSkills: (newProfessionalSkills) => [],
  // professionalSkill: '',
  // setProfessionalSkill: (newProfessionalSkill) => {},
  // professionalSkillPercent: '',
  // setProfessionalSkillPercent: (newProfessionalSkillPercent) => {},

  //COURSES
  courses: [],
  setCourses: (newCourses) => [],
  // course: '',
  // setCourse: (newCourse) => {},
  // courseInstitution: '',
  // setCourseInstitution: (newCourseInstitution) => {},
  // courseStartDate: '',
  // setCourseStartDate: (newCourseStartDate) => {},
  // courseEndDate: '',
  // setCourseEndDate: (newCourseEndDate) => {},

  //UNTITLED
  untitled: [],
  setUntitled: (newUntitledTitle) => [],
  // untitledTitle: '',
  // setUntitledTitle: (newUntitledTitle) => {},
  // untitledActivity: '',
  // setUntitledActivity: (newUntitledActivity) => {},
  // untitledStartDate: '',
  // setUntitledStartDate: (newUntitledStartDate) => {},
  // untitledEndDate: '',
  // setUntitledEndDate: (newUntitledEndDate) => {},
  // untitledCity: '',
  // setUntitledCity: (newUntitledCity) => {},
  // untitledDescription: '',
  // setUntitledDescription: (newUntitledDescription) => {},

  //REFERENCES
  references: [],
  setReferences: (newReferences) => [],

  //HOBBIES
  hobby: '',
  setHobby: (newHobby) => {},

//COLORS
  textColor: '',
  setTextColor: (newTextColor) => {},
  backgroundColor: '',
  setBackgroundColor: (newBackgroundColor)  => {},


});