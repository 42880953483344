import styled from 'styled-components'


export const Container = styled.div`
padding: 20px;
font-family: 'Arial';
font-size: 10pt;
//line-height: 1.6;
height: ${({itemHeight}) => `${itemHeight || '1123'}px`};

p {
font-size: 10pt;
line-height: 14pt;
}

img{
width: 220px;
object-fit: cover;

}
h2{
font-size: 2em;
font-weight: bold;
margin-top: 0;
margin-bottom: 5px;
text-transform: uppercase;
}
h3{
font-size: 1.2em;
margin-top: 0;
margin-bottom: 0;
margin-left: 0em;
text-transform: uppercase;
}
h4{
font-size: 16pt;
margin-top: 0;
margin-bottom: 10px;
}
hr{
border-top: 1px solid;
// border-color: ${({itemHrColor}) => itemHrColor || '#f26bf5'};
}
a{
text-decoration: none;
}
`

export const ContainerRow = styled.div`
display: flex;
flex-direction: row;
`
export const ContainerRowChild = styled.div`
display: flex;
flex-wrap: wrap;
margin-left: 0px;
`
export const Child = styled.div`
width: 30%;
padding-right: 10px;
`
export const ContainerColumn = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
width: ${({itemColumnWidth}) => itemColumnWidth || '100%'};
padding-right: 20px;
`
export const ColorContainer = styled.div`
// background-color: ${({itemBackground}) => itemBackground || 'burlywood'};
background-color: ${({itemBackground}) => itemBackground || '#f26bf5'};
width: 100%;
bottom: 0;
`
export const ColorText = styled.div`
// background-color: ${({itemBackground}) => itemBackground || 'burlywood'};
color: ${({itemColor}) => itemColor || '#f26bf5'};
width: 100%;
`
export const PaddingContainer = styled.div`
padding: 5px 10px;
`
export const TextCenter = styled.div`
text-align: center;
`

export const ProgressBarWrapper = styled.div`
  background: none;
  padding-top: 3px;
  height: 5px;
  width: 100%;
`

ProgressBarWrapper.ProgressBar = styled.div`
  background-color: ${({itemColor}) => itemColor || '#f26bf5'};
  height: 100%;
  width:  ${({itemWidth}) => `${itemWidth || '100'}%`}; 
`
export const SkillsStyle = styled.ul`
padding-left: 15px;
li{

}
`
const Section = styled.div`
padding: 20px 0;
`
;