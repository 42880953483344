import React from 'react';
import Wrapper from "../form.styles/Wrapper";
import HalfWrapper from "../form.styles/HalfWrapper";
import LabelText from "../form.styles/LabelText";
import InputText from "../form.styles/InputText";
import TextEditor from "../editor/TextEditor";
import {ResumeContext} from "../ResumeContext";

const UnnamedSection = (props) => {
  const [untitledTitle, setUntitledTitle] = React.useState('');
  const [untitledActivity, setUntitledActivity] = React.useState('');
  const [untitledStartDate, setUntitledStartDate] = React.useState('');
  const [untitledEndDate, setUntitledEndDate] = React.useState('');
  const [untitledCity, setUntitledCity] = React.useState('');
  const [untitledDescription, setUntitledDescription] = React.useState('');

  const getObj = () => ({
    untitledTitle,
    untitledActivity,
    untitledStartDate,
    untitledEndDate,
    untitledCity,
    untitledDescription
  });
  React.useEffect(() => {
    props.onChange(getObj());
  },[untitledTitle,
    untitledActivity,
    untitledStartDate,
    untitledEndDate,
    untitledCity,
    untitledDescription])
  return(
    <>
      <h2>Custom section</h2>
      <Wrapper>
        <HalfWrapper>
          <LabelText>Untitled</LabelText>
          <InputText
            type='text'
            value={untitledTitle}
            onChange={(e) => setUntitledTitle(e.target.value)}/>
        </HalfWrapper>
        <HalfWrapper>
          <LabelText>Activity name, job title, book title etc.</LabelText>
          <InputText
            type='text'
            value={untitledActivity}
            onChange={(e) => setUntitledActivity(e.target.value)}/>
        </HalfWrapper>


      </Wrapper>
      <Wrapper>
        <HalfWrapper>
          <LabelText>Start & End Date</LabelText>
          <HalfWrapper>
            <InputText
              type='month'
              value={untitledStartDate}
              onChange={(e) => setUntitledStartDate(e.target.value)}/>
          </HalfWrapper>
          <HalfWrapper>
            <InputText
              type='month'
              value={untitledEndDate}
              onChange={(e) => setUntitledEndDate(e.target.value)}/>
          </HalfWrapper>
        </HalfWrapper>
        <HalfWrapper>
          <LabelText>City</LabelText>
          <InputText
            type='text'
            value={untitledCity}
            onChange={(e) => setUntitledCity(e.target.value)}/>
        </HalfWrapper>
      </Wrapper>

      <LabelText>Description</LabelText>
      <div>
        <TextEditor
          html={untitledDescription}
          onChange={(e) => setUntitledDescription(e.target.value)}/>
      </div>
      </>
  )
}
export default UnnamedSection;