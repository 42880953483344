import {makeAutoObservable} from "mobx";
import Axios from 'axios';


class JobsData {
  jobs = [];
  job = {}
  categories = []
  companies = []
  logos = []
  logocompany = []
  countries = [];
  categoriesName = ['All categories','Software Development','Customer Service','Design','Marketing','Sales','Product','Business','Data','DevOps / Sysadmin','Finance / Legal','Human Resources','QA','Writing','All others']

  constructor() {
    makeAutoObservable(this)
  }

  update() {
    return Axios.get('https://remotive.com/api/remote-jobs').then(response =>
      this.jobs = response.data.jobs)
  }

  getJob(id) {
    return Axios.get('https://remotive.com/api/remote-jobs').then(response =>
      response.data.jobs.find((el) => el.id === parseInt(id)))
  }

  getCategories() {
    return Axios.get('https://remotive.com/api/remote-jobs/categories').then(response =>
      this.categories = response.data.jobs)
    // response.data.jobs.filter((el) => el.category !== this.categories)
  }


  updateCompanies() {
    return Axios.get('https://remotive.com/api/remote-jobs').then(response => {
      const companies = response.data.jobs.reduce((prev, el) => {
        prev[el.company_name] = {
          name: el.company_name,
          logo: el.company_logo,
          category: el.category,
          job: el.title
        };
        return prev;
      }, {});
      this.companies = Object.values(companies);
      return this.companies;
    })
  }

  updateLogos() {
    return Axios.get('https://remotive.com/api/remote-jobs').then(response =>
      this.logos = response.data.jobs.map(el => el.company_logo))
  }

  updateCountries() {
    return Axios.get('https://remotive.com/api/remote-jobs').then(response =>
      this.countries = response.data.jobs.map(el => el.candidate_required_location))
  }

  getCompanyJobs(company) {
    return Axios.get('https://remotive.com/api/remote-jobs').then(response =>
      response.data.jobs.filter((el) => el.company_name == company))
  }
  getCategoriesJobs(category) {
    return Axios.get('https://remotive.com/api/remote-jobs').then(response =>
      response.data.jobs.filter((el) => el.category.replace('/ ',' ') == category.replace('/ ',' ')))
  }

  // getCategory(category) {
  //   return Axios.get('https://remotive.com/api/remote-jobs').then(response =>
  //     response.data.jobs.filter((el) => el.category === category))
  // }
}

const jobsData = new JobsData;
export default jobsData;