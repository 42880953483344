import styled from 'styled-components'

const HeaderStyle = styled.div`
width: 100%;
height: 60px;
background-color: var(--orange);
color: white;
position: sticky;
margin: 0;
top: 0;
z-index:999;
`
export const HeaderStyleContent = styled.div`
margin-right: 20px;
margin-left: 20px;
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
`
export default HeaderStyle;
