import styled from 'styled-components'

const ButtonCategories = styled.button`
outline: none;
background-color: var(--grey);
color: var(--darkblue);
font-weight: bold;
padding: 7px;
margin: 7px;
border: none;

&:hover{
background-color: var(--darkgrey);
}
&:focus{
background-color: var(--orange);
color: white;
}

`;
export default ButtonCategories;
