import React from 'react'
import TextOrange from "../styles/TextOrange";
import Container from "../styles/Container";
import img1 from './images/foto1.jpg'
import img2 from './images/foto2.jpg'
import BlogImage, {BlogImageLeft, BlogImageRight} from "./BlogImage";
import Breadcrumbs from "../styles/Breadcrumbs";
import Helmet from "react-helmet";



const BestResumeTips = () => {
  return(
    <>
      <Helmet>
        <title>Top Work | The Best Resume Tips</title>
        <meta name="description" content="Out of all of the resume building tips listed, this may seem too obvious. But it’s worth mentioning because the use of an unprofessional email address will get you rejected 76% of the time. So, if you haven’t already done so, ditch that email address you’ve been using since high school. Choose a professional email provider like Gmail or Outlook for creating a professional alternative. Use your name." />
        <meta name='keywords' content="Resume Tips, Best Resume Tips, Best Resume, hired, find a job, skills for resume, cv, how to write cv, tips for cv"/>
      </Helmet>
      <Container>
        <Breadcrumbs>
          <Breadcrumbs.Link to='/'>Home</Breadcrumbs.Link> >
          <Breadcrumbs.Link to='/blog'>Blog</Breadcrumbs.Link> >
          <Breadcrumbs.Link to=''>The Best Resume Tips</Breadcrumbs.Link>
        </Breadcrumbs>
      <h1>The Best Resume Tips</h1>
        <BlogImageRight src={img1}/>
      <TextOrange><h2>1. Use a professional email address.</h2></TextOrange>
      <p>Out of all of the resume building tips listed, this may seem too obvious.
        But it’s worth mentioning because the use of an unprofessional email address will get you rejected 76% of the time.
        So, if you haven’t already done so, ditch that email address you’ve been using since high school. Choose a professional email provider like Gmail or Outlook for creating a professional alternative. Use your name. </p>
      <TextOrange><h2>2. Make sure your contact information is up to date.</h2></TextOrange>
      <p>After you change your email address, double check that the rest of your contact information on your resume is accurate.
        You don’t want to miss an interview because you put the wrong phone number on your resume.
        Also, exclude information like your birth date or marital status. You do not have to respond to questions about religion, race, or gender on an application. US employers cannot take these aspects into consideration when accessing you.
        If you’re applying for a job out of state or country, also consider omitting your current address on your resume. That way a hiring manager won’t think you’re confused about the location of the job.</p>
    <TextOrange><h2>3. Set your font size to 10-12 points.</h2></TextOrange>
      <p>While choosing a font is important, making sure that it is the right size is paramount.
        Keep your resume font size between 10-12 points so that a hiring manager can easily read it without squinting.</p>
      <TextOrange><h2>4. Use reverse-chronological order.</h2></TextOrange>
      <p>That means starting from your most recent job and work your way back.
        For your experience section, put your current job first.
        For your education section, put your highest degree first.</p>
      <TextOrange><h2>5. Align your content to the left to make it skimmable.</h2></TextOrange>
      <p>The first thing a hiring manager is going to do is skim your resume for relevant keywords from the job description.
        Aligning your text to the left makes this easier for them to do. </p>
      <TextOrange><h2>6. Make strategic use of bold, caps, and italics.</h2></TextOrange>
      <p>Be consistent with your choices. If you’ve made one of your subheadings bold - make them all bold. Try not to overuse anything. The point is to make important information easier to find. </p>
      <TextOrange><h2>7. Choose an attractive and readable font.</h2></TextOrange>
    <p>You may think resume fonts are trivial in the larger scheme of things, but the right font is going to do a lot for your resume. </p>
      <TextOrange><h2>8. Only add jobs you’ve had in the past 10-15 years.</h2></TextOrange>
      <p>You do not need to list every job you’ve ever had on your resume. Make sure you go back on your resume no more than 10 or 15 years. </p>
      <TextOrange><h2>9. Give your sections simple subheadings.</h2></TextOrange>
      <p>Regardless of what layout you choose, make sure your resume sections are visible and easy to find.
        You can do that by giving them simple subheadings.</p>
      <TextOrange><h2>10. Include URLs to social media profiles, personal websites, and your blog.</h2></TextOrange>
      <p>If you have a professional website or blog, include the URL in your contact section.
        Add any relevant social media handles as well. For most professionals, that will include your LinkedIn URL and your Twitter handle.
        Creative professionals could also consider adding relevant links to Instagram, Youtube, or Pinterest profiles.
        Take an extra couple of minutes to make sure that your URLs are live and to hyperlink them in the text so they are accessible.</p>
        <BlogImageLeft src={img2}/>
        <TextOrange><h2>11. Choose a resume format that works for you. </h2></TextOrange>
      <p>There are three types of resume formats:</p>
      <ul>
        <li>Reverse-chronological</li>
        <li>Combination</li>
        <li>Functional</li>
      </ul>
      <TextOrange><h2>12. Consider using a professionally designed template. </h2></TextOrange>
      <p>Resume templates can save you a lot of time and effort. Imagine not having to fool around with margins in Word. Pick a modern resume template out, and you’re ready to go. </p>
      <TextOrange><h2>13. Consider putting your education section first.</h2></TextOrange>
      <p>Once you’ve chosen a format, it’s a good idea to make a quick decision about the layout.
        How do you build a strong resume that stands out?
        After your contact information, start your resume with either a resume summary or a resume objective. More on that later.
        But what should come next? Your education or your experience section?
        If you’re a professional with tons of experience, your experience should come first.
        But let’s say you’re a student and your educational background is your strongest selling point. In that case, consider putting your education section first. </p>
      <TextOrange><h2>14. Lose the phrase “References Available Upon Request.” </h2></TextOrange>
   <p>It is no longer necessary to place "references available upon request" at the bottom of your resume, as hiring managers know that they can request your list of references.
     Adding it only takes up valuable space that you could use for something else.</p>
      <TextOrange><h2>15. Read the job description and then read it again.</h2></TextOrange>
      <p>Okay, reading the job description may sound like one of the most obvious resume-building tips ever.
        Of course, you’ve read the job description. Right?
        In fact, most people spend an average of 76 seconds reading a job description. And that’s why hiring managers find that 50% of applicants are unqualified for the job.
        You’ve got to make sure you have the skills necessary for the job in the first place.</p>
      </Container>
    </>
  )
}
export default BestResumeTips;