import React from 'react'
import {ResumeContext} from "../ResumeContext";
import {
  ColorContainer,
  Container,
  ContainerColumn,
  ContainerRow,
  PaddingContainer,
  ProgressBarWrapper, SideContainer, SkillsStyle,
  TextCenter
} from "./Template1Style";
import {ColorText, ContainerMain} from "./Template15Style";
import Parallelogram, {ContainerParallelogram, ParallelogramFree} from "./Parallelogram";


const Template1 = React.forwardRef((props, ref) => {
  const context = React.useContext(ResumeContext)
  let height

  const [customHeight, setCustomHeight] = React.useState(1122);
  React.useEffect(() => {
    let rect = document.getElementById('container').getBoundingClientRect();
    height = rect.height;
    console.log(height)
    if(height >= 900){

      setCustomHeight(2245)
    } else
    if (height < 1123){
      setCustomHeight(1123)
    }
  }, [context]);

  return (
    <>

      <div ref={ref}>
        <Container itemHeight={customHeight}>
          <div id='container'>
          <ContainerRow>
            <ContainerColumn>
              <SideContainer>
              {context.userAvatar === '' ? null
                : <img src={context.userAvatar}/>}
              <TextCenter>
                <h2>{context.userFirstName} {context.userLastName}</h2>
                <h4>{context.userWantedJobTitle}</h4>
              </TextCenter>

              <ColorContainer itemBackground={context.backgroundColor}>
                <PaddingContainer>
                  <h3>Personal Details</h3>
                  {context.userEmail.length > 0 ?
                  <p><b>Email:</b><br/>
                    {context.userEmail}</p>: null}

                  {context.userPhone.length > 0 ?
                  <p><b>Phone:</b><br/>
                    {context.userPhone}</p>: null}

                  {context.userCountry.length > 0 && context.userCity.length > 0 && context.userAddress.length > 0 && context.userPostalCode.length > 0 ?
                    <p><b>Address:</b><br/>
                      {context.userCountry}
                      {context.userCity}
                      {context.userAddress}
                      {context.userPostalCode}</p>
                  : null}


                  {context.userDrivingLicense.length > 0 ?
                  <p><b>Driving licence:</b><br/>
                     {context.userDrivingLicense}</p> : null}

                  {context.userNationality > 0 ? <p>{context.userNationality}<br/>
                    {context.userPlaceOfBirth}<br/></p> : null}


                  {context.userDateOfBirth.length > 0 ?
                  <p><b>Date of birth</b><br/>
                    {context.userDateOfBirth}</p>: null}

                  {context.userLinkedin.length > 0 ?
                    <p><b>Linkedin</b><br/>
                      <a href={context.userLinkedin} target='_blank'>{context.userLinkedin}</a></p>: null}




                  {context.userLinkedin.length > 0 ?
                    <p><b>Linkedin</b><br/>
                      <a href={`https:/www.+ ${context.userLinkedin}`} target='_blank'>{context.userLinkedin}</a></p>: null}

                  {context.userTwitter.length > 0 ?
                    <p><b>Twitter</b><br/>
                      <a href={context.userTwitter} target='_blank'>{context.userTwitter}</a></p>: null}

                  {context.userWeb.length > 0 ?
                    <p><b>Personal website</b><br/>
                      <a href={context.userWeb} target='_blank'>{context.userWeb}</a></p>: null}



                  <p><b>Links</b><br/>
                  {context.socials.length > 0 ? <>
                    {context.socials.map((el, index) => <a href={el.socialLink} target='_blank'>{el.socialLabel}</a>)}
                  </> : null}</p>

                  {context.skills[0].skill === '' ?
                    null : <>
                      <h3>Skills</h3>
                      <SkillsStyle>{context.skills.map((el, index) => <><li>{el.skill}</li> </>)}</SkillsStyle>
                    </>}





                  {context.professionalSkills[0].professionalSkill === '' && context.professionalSkills[0].professionalSkillPercent === '' ?
                    null
                    :
                    <>
                      <h3>Professional skills</h3>
                      <p>{context.professionalSkills.map((el, index) => <>
                        {el.professionalSkill}
                        {parseInt(el.professionalSkillPercent) > 0 && parseInt(el.professionalSkillPercent) <= 20 ? <ContainerParallelogram>
                          <Parallelogram/>
                          <ParallelogramFree/>
                          <ParallelogramFree/>
                          <ParallelogramFree/>
                          <ParallelogramFree/></ContainerParallelogram> : null}
                        {parseInt(el.professionalSkillPercent) > 21 && parseInt(el.professionalSkillPercent) <= 40 ? <ContainerParallelogram>
                          <Parallelogram/>
                          <Parallelogram/>
                          <ParallelogramFree/>
                          <ParallelogramFree/>
                          <ParallelogramFree/></ContainerParallelogram> : null}
                        {parseInt(el.professionalSkillPercent) > 41 && parseInt(el.professionalSkillPercent) <= 60 ? <ContainerParallelogram>
                          <Parallelogram/>
                          <Parallelogram/>
                          <Parallelogram/>
                          <ParallelogramFree/>
                          <ParallelogramFree/></ContainerParallelogram> : null}
                        {parseInt(el.professionalSkillPercent) > 61 && parseInt(el.professionalSkillPercent) <= 80 ? <ContainerParallelogram>
                          <Parallelogram/>
                          <Parallelogram/>
                          <Parallelogram/>
                          <Parallelogram/>
                          <ParallelogramFree/></ContainerParallelogram> : null}
                        {parseInt(el.professionalSkillPercent) > 81 && parseInt(el.professionalSkillPercent) <= 100 ? <ContainerParallelogram>
                          <Parallelogram/>
                          <Parallelogram/>
                          <Parallelogram/>
                          <Parallelogram/>
                          <Parallelogram/></ContainerParallelogram> : null}
                      </>)}</p></>}



                  {context.languages[0].language === '' && context.languages[0].languageLevel === '' ?
                    null
                    :
                    <>
                      <h3>Languages</h3>
                      <p>{context.languages.map((el, index) => <>
                        {el.language} - {el.languageLevel}<br/>
                      </>)}</p>
                    </>}

                  {context.references[0].referenceName === '' && context.references[0].referencePosition === '' && context.references[0].referencePhone === '' &&
                  context.references[0].referenceEmail === '' ?
                    null
                    :
                    <><h3>References</h3>
                      <div><p> {context.references.map((el, index) => <>
                        {el.referenceName}<br/>
                        {el.referencePosition}<br/>
                        {el.referencePhone}<br/>
                        {el.referenceEmail}<br/><br/>
                      </>)}</p></div>
                    </>}
                </PaddingContainer>
              </ColorContainer>
              </SideContainer>
            </ContainerColumn>

            <ContainerColumn>


              {/*{context.professionalSummary > 0 ?*/}
              {/*  <><ColorContainer>*/}
              {/*    <PaddingContainer>*/}
              {/*      <h3>Summary</h3>*/}
              {/*    </PaddingContainer>*/}
              {/*  </ColorContainer>*/}
              {/*    <div dangerouslySetInnerHTML={{__html: context.professionalSummary}}/>*/}
              {/*  </>*/}
              {/*  : null}*/}

              {context.professionalSummary === '' ? null :
                <><ColorContainer>
                  <PaddingContainer>
                  <h3>Summary</h3>
                  </PaddingContainer>
                </ColorContainer>
                 <p> <div dangerouslySetInnerHTML={{__html: context.professionalSummary}}/></p>
                </>}<br/>


              {context.jobsExperiences[0].jobExperienceJobTitle === '' && context.jobsExperiences[0].jobExperienceStartDate === '' && context.jobsExperiences[0].jobExperienceEndDate === '' &&
              context.jobsExperiences[0].jobExperienceEmployer === '' && context.jobsExperiences[0].jobExperienceCity === '' && context.jobsExperiences[0].jobExperienceDescription === '' ?
                null
                :
                <><ColorContainer itemBackground={context.backgroundColor}>
                  <PaddingContainer>
                    <h3>Job experience</h3>
                  </PaddingContainer>
                </ColorContainer>
                  <p>{context.jobsExperiences.map((el, index) => <>
                    <b>{el.jobExperienceJobTitle}</b>
                     {el.jobExperienceStartDate === '' && el.jobExperienceEndDate === '' ?
                      null :
                      <> {el.jobExperienceStartDate} - {el.jobExperienceEndDate}<br/></>}

                    <b>{el.jobExperienceEmployer}</b> {el.jobExperienceCity}
                    <div dangerouslySetInnerHTML={{__html: el.jobExperienceDescription}}/>
                    <br/>
                  </>)}</p></>}



              {context.educations[0].educationSchool === '' && context.educations[0].educationCity === '' && context.educations[0].educationDegree === '' &&
              context.educations[0].educationStartDate === '' && context.educations[0].educationEndDate === '' && context.educations[0].educationDescription === '' ?
                null
                :
                <>
                  <ColorContainer itemBackground={context.backgroundColor}>
                    <PaddingContainer>
                      <h3>Education</h3>
                    </PaddingContainer>
                  </ColorContainer>
                  <p> {context.educations.map((el, index) =>
                    <>
                      <b>{el.educationSchool}</b> {el.educationCity}<br/>
                        <b>{el.educationDegree}</b> {el.educationStartDate} - {el.educationEndDate}
                        <div dangerouslySetInnerHTML={{__html: el.educationDescription}}/>
                      <br/>
                    </>)} </p>
                </>}



              {context.internships[0].internshipsJobTitle === '' && context.internships[0].internshipsStartDate === '' && context.internships[0].internshipsEndDate === '' &&
              context.internships[0].internshipsEmployer === '' && context.internships[0].internshipsCity === '' && context.internships[0].internshipsDescription === '' ?
                null
                :
                <>
                  <ColorContainer>
                    <PaddingContainer>
                      <h3>Internships</h3>
                    </PaddingContainer>
                  </ColorContainer>
                  <p>{context.internships.map((el, index) =>
                    <>
                      <b>{el.internshipsJobTitle}</b> {el.internshipsStartDate} - {el.internshipsEndDate} <br/>
                      <b>{el.internshipsEmployer}</b> {el.internshipsCity}
                      <div dangerouslySetInnerHTML={{__html: el.internshipsDescription}}/>
                      <br/>
                    </>)}</p>
                </>}


              {context.courses[0].course === '' && context.courses[0].courseInstitution === '' && context.courses[0].courseStartDate === '' &&
              context.courses[0].courseEndDate === '' ?
                null
                :
                <>
                  <ColorContainer style={{
                    backgroundColor: context.backgroundColor,
                  }}>
                    <PaddingContainer>
                      <h3>Courses</h3>
                    </PaddingContainer>
                  </ColorContainer>
                  <p> {context.courses.map((el, index) => <>
                    <b> {el.course} {el.courseInstitution}</b> {el.courseStartDate} - {el.courseEndDate}<br/>
                  </>)}</p>
                </>}


              {context.untitled[0].untitledTitle === '' && context.untitled[0].untitledActivity === '' && context.untitled[0].untitledCity === '' &&
              context.untitled[0].untitledStartDate === '' && context.untitled[0].untitledEndDate === '' && context.untitled[0].untitledDescription === '' ?
                null
                : <>
                  {context.untitled.map((el, index) => <>
                    <ColorContainer>
                      <PaddingContainer><h3>{el.untitledTitle}</h3>
                      </PaddingContainer>
                    </ColorContainer>
                    <p><b>{el.untitledActivity}</b> {el.untitledCity}<br/>
                      {el.untitledStartDate} - {el.untitledEndDate}

                      <div dangerouslySetInnerHTML={{__html: el.untitledDescription}}/>
                    </p>
                  </>)}
                </>}

              {context.hobby.length > 0 ?
                <>

                  {/*<ColorContainer style={{backgroundColor: `${context.textColor}`}}>*/}
                  <ColorContainer itemBackground={context.backgroundColor}>
                    <PaddingContainer>
                      <h3>Hobbies</h3>
                    </PaddingContainer>
                  </ColorContainer>

                  <div dangerouslySetInnerHTML={{__html: context.hobby}}/>
                </>
                : null}


            </ContainerColumn>
          </ContainerRow>

          </div>
        </Container>
      </div>
    </>
  )
})
export default Template1;