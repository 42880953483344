import styled from 'styled-components'


const LabelText = styled.label`
color: rgb(130, 139, 162);
display: inline-block;
font-size: 14px;
line-height: 16px;
`;
export default LabelText;
