import styled from 'styled-components'


const ResumePreview = styled.div`
width: 210mm;
min-height: 297mm;
background-color: white;
//padding: 20px;
margin: 20px auto;

//margin: 10mm auto;
box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);

@media (max-width: 1800px) {
margin: -2em 0 -3em -2.5em;
    transform: scale(0.9);
  }

@media (max-width: 1700px) {
margin: -6em 0 -5em -5em;
    transform: scale(0.8);
  }
@media (max-width: 1600px) {
margin: -7em 0 -7em -7em;
    transform: scale(0.7);
  }
  @media (max-width: 1200px) {
margin: -10em 0 -13em -10em;
    transform: scale(0.6);
    } 
  @media (max-width: 1024px) {
margin: -15em 0 -15em -11.5em;
    transform: scale(0.5);
    } 
  @media (max-width: 900px) {
margin: -15em 0 -15em -11.5em;
    transform: scale(0.5);
  }
\`
`

export default ResumePreview;