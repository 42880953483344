import styled from 'styled-components'


const ButtonLinkEdit = styled.button`
outline:none;
background: none;
color: var(--darkblue);
border: none;
cursor: pointer;
&:hover{
color: var(--orange);
}
`;
export default ButtonLinkEdit;
