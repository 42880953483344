import React from 'react';
import {ResumeContext} from "../ResumeContext";
import {
  Child,
  ColorText,
  Container, ContainerColumn,
  ContainerRow, ContainerRowChild,
  PaddingContainer,
  ProgressBarWrapper, SkillsStyle,
  TextCenter
} from "./Template4Style";
import CenterBlock from "../form.styles/CenterBlock";


const Template4 = React.forwardRef((props, ref) => {
  const context = React.useContext(ResumeContext)
  let height

  const [customHeight, setCustomHeight] = React.useState(1122);
  React.useEffect(() => {
    let rect = document.getElementById('container').getBoundingClientRect();
    height = rect.height;
    if(height >= 1123){

      setCustomHeight(2245)
    } else
    if (height < 1123){
      setCustomHeight(1123)
    }
  }, [context]);

  return (
    <>

      <div ref={ref}>

        <Container itemHeight={customHeight}>
          <div id='container'>
          <TextCenter>
            <ColorText itemColor={context.textColor}><h2>{context.userFirstName} {context.userLastName}</h2></ColorText>
            <h3>{context.userWantedJobTitle}</h3>
          </TextCenter>


          <ContainerRow>
            <ContainerColumn itemColumnWidth='30%'>
              {context.userAvatar === '' ? null
                : <CenterBlock><img src={context.userAvatar}/></CenterBlock>}

              {context.socials.length > 0 ? <>
                <p>{context.socials.map((el, index) => <><a href={el.socialLink} target='_blank'><ColorText
                  itemColor={context.textColor}>{el.socialLabel}</ColorText></a>
                  {el.socialLink}</>)}</p>
              </> : null}


              {context.languages[0].language === '' && context.languages[0].languageLevel === '' ?
                null
                :
                <>
                  <ColorText itemColor={context.textColor}>
                    <h3>Languages</h3>
                    <hr itemHrColor={context.textColor}/>
                  </ColorText>
                  {context.languages.map((el, index) => <>
                    {el.language} {el.languageLevel}
                    <ProgressBarWrapper>
                      <ProgressBarWrapper.ProgressBar itemColor={context.textColor}
                                                      itemWidth={el.languageLevel === 'Beginner' ? 16 :
                                                        el.languageLevel === 'Pre Intermediate' ? 36 :
                                                          el.languageLevel === 'Intermediate' ? 60 :
                                                            el.languageLevel === 'Proficient' ? 80 :
                                                              el.languageLevel === 'Fluent' ? 92 :
                                                                el.languageLevel === 'Native' ? 100 : null
                                                      }
                        // {el.languageLevel === 'Beginner' ? 16 : null}
                        //
                        // 'Beginner', 'Pre Intermediate', 'Intermediate', 'Proficient', 'Fluent', 'Native'
                      />
                    </ProgressBarWrapper><br/>
                  </>)}
                </>}
            </ContainerColumn>

            <ContainerColumn>
              <PaddingContainer>
                <ContainerRowChild>

                    {context.userEmail === '' ? null :
                      <Child><p><b>Email:</b><br/>
                        {context.userEmail}</p></Child>}

                  {context.userPhone > 0 ?
                    <Child><p><b>Phone:</b><br/>
                      {context.userPhone}</p></Child> : null}


                    {context.userDateOfBirth > 0 ?
                      <Child><p><b>Date of birth</b><br/>
                        {context.userDateOfBirth}</p></Child> : null}



                  {context.userCountry === '' && context.userCity === '' && context.userAddress === '' && context.userPostalCode === '' ?
                    null : <Child><p><b>Address:</b><br/>
                      {context.userCountry}, {context.userCity}, {context.userAddress} {context.userPostalCode}</p></Child>}

                    {context.userDrivingLicense === '' ? null :
                      <Child><p><b>Driving licence:</b><br/>
                        {context.userDrivingLicense}</p></Child>}


                    {/*{context.userNationality === '' ? null :*/}
                    {/*  <Child><p>{context.userNationality}<br/>*/}
                    {/*    {context.userPlaceOfBirth}<br/></p></Child>}*/}

                </ContainerRowChild>


                {context.professionalSummary === '' ? null :
                  <><ColorText itemColor={context.textColor}>

                    <h3>Summary</h3>
                    <hr itemHrColor={context.textColor}/>
                  </ColorText>
                    <div dangerouslySetInnerHTML={{__html: context.professionalSummary}}/>
                  </>}<br/>


                {context.skills[0].skill === '' ?
                  null : <>
                    <ColorText itemColor={context.textColor}>
                      <h3>Skills</h3>
                      <hr itemHrColor={context.textColor}/>
                    </ColorText>
                    <SkillsStyle>{context.skills.map((el, index) => <>
                      <li>{el.skill}</li>
                    </>)}</SkillsStyle>
                  </>}

                {context.professionalSkills[0].professionalSkill === '' && context.professionalSkills[0].professionalSkillPercent === '' ?
                  null
                  :
                  <>
                    <ColorText itemColor={context.textColor}>
                      <h3>Professional skills</h3>
                      <hr itemHrColor={context.textColor}/>
                    </ColorText>

                    <p>{context.professionalSkills.map((el, index) => <>
                      {el.professionalSkill}
                      <ProgressBarWrapper>
                        <ProgressBarWrapper.ProgressBar itemColor={context.textColor}
                                                        itemWidth={parseInt(el.professionalSkillPercent)}/>
                      </ProgressBarWrapper><br/>
                    </>)}</p></>}


                {context.references[0].referenceName === '' && context.references[0].referencePosition === '' && context.references[0].referencePhone === '' &&
                context.references[0].referenceEmail === '' ?
                  null
                  :
                  <>
                    <ColorText itemColor={context.textColor}>
                      <h3>References</h3>
                      <hr itemHrColor={context.textColor}/>
                    </ColorText>
                    <div><p> {context.references.map((el, index) => <>
                      {el.referenceName}<br/>
                      {el.referencePosition}<br/>
                      {el.referencePhone}<br/>
                      {el.referenceEmail}<br/><br/>
                    </>)}</p></div>
                  </>}


              </PaddingContainer>
            </ContainerColumn>
          </ContainerRow>


          {context.jobsExperiences[0].jobExperienceJobTitle === '' && context.jobsExperiences[0].jobExperienceStartDate === '' && context.jobsExperiences[0].jobExperienceEndDate === '' &&
          context.jobsExperiences[0].jobExperienceEmployer === '' && context.jobsExperiences[0].jobExperienceCity === '' && context.jobsExperiences[0].jobExperienceDescription === '' ?
            null
            :
            <><ColorText itemColor={context.textColor}>

              <h3>Job experience</h3>
              <hr itemHrColor={context.textColor}/>
            </ColorText>
              {context.jobsExperiences.map((el, index) => <>
                <ContainerRow>
                  <ContainerColumn itemColumnWidth='22%'>
                    <p>{el.jobExperienceStartDate === '' && el.jobExperienceEndDate === '' ?
                      null :
                      <> {new Date(el.jobExperienceStartDate).toLocaleString('en-us',{month:'short', year:'numeric'})} - {new Date(el.jobExperienceEndDate).toLocaleString('en-us',{month:'short', year:'numeric'})}<br/></>}</p>
                  </ContainerColumn>
                  <ContainerColumn>
                    <p><b>{el.jobExperienceJobTitle}</b><br/>
                      {el.jobExperienceEmployer}{el.jobExperienceCity}
                      <div dangerouslySetInnerHTML={{__html: el.jobExperienceDescription}}/>
                      <br/></p>

                  </ContainerColumn>
                </ContainerRow>
              </>)}</>}


          {context.educations[0].educationSchool === '' && context.educations[0].educationCity === '' && context.educations[0].educationDegree === '' &&
          context.educations[0].educationStartDate === '' && context.educations[0].educationEndDate === '' && context.educations[0].educationDescription === '' ?
            null
            :
            <>
              <ColorText itemColor={context.textColor}>
                <h3>Education</h3>
                <hr itemHrColor={context.textColor}/>
              </ColorText>

              {context.educations.map((el, index) =>
                <>
                  <ContainerRow>
                    <ContainerColumn itemColumnWidth='22%'>
                      <p>{el.educationStartDate === '' && el.educationEndDate === '' ?
                        null :
                        <> {new Date(el.educationStartDate).toLocaleString('en-us',{month:'short', year:'numeric'})} - {new Date(el.educationEndDate).toLocaleString('en-us',{month:'short', year:'numeric'})}<br/></>}</p>
                    </ContainerColumn>
                    <ContainerColumn>
                      <p>
                        <b>{el.educationDegree}</b><br/>
                        {el.educationSchool} {el.educationCity}<br/>
                        <div dangerouslySetInnerHTML={{__html: el.educationDescription}}/>
                      </p>
                    </ContainerColumn>
                  </ContainerRow>
                </>)}
            </>}


          {context.internships[0].internshipsJobTitle === '' && context.internships[0].internshipsStartDate === '' && context.internships[0].internshipsEndDate === '' &&
          context.internships[0].internshipsEmployer === '' && context.internships[0].internshipsCity === '' && context.internships[0].internshipsDescription === '' ?
            null
            :
            <>
              <ColorText itemColor={context.textColor}>

                <h3>Internships</h3>
                <hr itemHrColor={context.textColor}/>
              </ColorText>
              {context.internships.map((el, index) =>
                <>
                  <ContainerRow>
                    <ContainerColumn itemColumnWidth='22%'>
                      <p>{el.internshipsStartDate === '' && el.internshipsEndDate === '' ?
                        null :
                        <> {new Date(el.internshipsStartDate).toLocaleString('en-us',{month:'short', year:'numeric'})} - {new Date(el.internshipsEndDate).toLocaleString('en-us',{month:'short', year:'numeric'})}<br/></>}</p>
                    </ContainerColumn>
                    <ContainerColumn>
                      <p><b>{el.internshipsJobTitle}</b>
                        {el.internshipsEmployer} {el.internshipsCity}
                        <div dangerouslySetInnerHTML={{__html: el.internshipsDescription}}/>
                      </p>
                    </ContainerColumn>
                  </ContainerRow>
                </>
              )}
            </>}


          {context.courses[0].course === '' && context.courses[0].courseInstitution === '' && context.courses[0].courseStartDate === '' &&
          context.courses[0].courseEndDate === '' ?
            null
            :
            <>
              <ColorText itemColor={context.textColor}>

                <h3>Courses</h3>
                <hr itemHrColor={context.textColor}/>
              </ColorText>
              {context.courses.map((el, index) => <>
                <ContainerRow>
                  <ContainerColumn itemColumnWidth='22%'>
                    <p>{el.courseStartDate === '' && el.courseEndDate === '' ?
                      null :
                      <> {new Date(el.courseStartDate).toLocaleString('en-us',{month:'short', year:'numeric'})} - {new Date(el.courseEndDate).toLocaleString('en-us',{month:'short', year:'numeric'})}<br/></>}</p>
                  </ContainerColumn>
                  <ContainerColumn>
                    <p><b> {el.course}</b>
                      {el.courseInstitution}
                    </p>
                  </ContainerColumn>
                </ContainerRow>
              </>)}
            </>}


          {context.untitled[0].untitledTitle === '' && context.untitled[0].untitledActivity === '' && context.untitled[0].untitledCity === '' &&
          context.untitled[0].untitledStartDate === '' && context.untitled[0].untitledEndDate === '' && context.untitled[0].untitledDescription === '' ?
            null
            : <>
              {context.untitled.map((el, index) => <>
                <ColorText itemColor={context.textColor}>
                  <h3>{el.untitledTitle}</h3>
                  <hr itemHrColor={context.textColor}/>
                </ColorText>
                <ContainerRow>
                  <ContainerColumn itemColumnWidth='22%'>
                    <p>{el.untitledStartDate === '' && el.untitledEndDate === '' ?
                      null :
                      <> {new Date(el.untitledStartDate).toLocaleString('en-us',{month:'short', year:'numeric'})} - {new Date(el.untitledEndDate).toLocaleString('en-us',{month:'short', year:'numeric'})}<br/></>}</p>
                  </ContainerColumn>
                  <ContainerColumn>
                    <p><b>{el.untitledActivity}</b>{el.untitledCity}
                      <div dangerouslySetInnerHTML={{__html: el.untitledDescription}}/>
                    </p>
                  </ContainerColumn>
                </ContainerRow>
              </>)}
            </>}

          {context.hobby.length > 0 ?
            <>

              {/*<ColorContainer style={{backgroundColor: `${context.textColor}`}}>*/}
              <ColorText itemColor={context.textColor}>
                <h3>Hobbies</h3>
                <hr itemHrColor={context.textColor}/>
              </ColorText>
              <p>
                <div dangerouslySetInnerHTML={{__html: context.hobby}}/>
              </p>
            </>
            : null}

          </div>
        </Container>
      </div>
    </>
  )
})
export default Template4;