import styled from 'styled-components'

const ImagesContainer = styled.div`
display: flex;
flex-direction: row;
flex-wrap: nowrap;
left: ${({ left }) => `${left}vw`};
position: relative;
transition: all 0.5s;


`;
export default ImagesContainer;