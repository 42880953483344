import React from 'react';
import jobsData from "../data/JobsData";
import {observer} from "mobx-react-lite";


const Countries = () => {
  React.useEffect(() => {
    jobsData.updateCountries()
  },[])
  let newr =  [...new Set(jobsData.countries)]
  return(
    <>
      {newr.map(el => <p>{el}</p>)}
      </>
  )
}
export default observer(Countries);