import styled from 'styled-components'


export const DropDownContainer = styled("div")`
position: relative;
  //width: 10.5em;
  //margin: 0 auto;
`;

export const DropDownHeader = styled("div")`
width: 100%;
border-radius: 3px;
padding: 10px 12px;
display: block;
font-size: 13px;
caret-color: var(--orange);
background-color: rgb(239, 242, 249);
outline: currentcolor none medium;
border: 0px none;
transition: color 0.1s ease 0s;
`;

export const DropDownListContainer = styled("div")`
z-index: 1;
position: absolute;
width: 100%;
`;

export const DropDownList = styled("ul")`
background-color: rgb(239, 242, 249);
width: 100%;
  padding: 0;
  margin: 0;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  color: black;
 
  &:first-child {
    padding: 10px 12px;
  }
`;

export const ListItem = styled("li")`
padding: 10px 12px;
list-style: none;
 &:hover{
color: var(--orange);
  }
`;
