import React from 'react';
import {Link} from "react-router-dom";
import jobsData from "./data/JobsData";
import CompaniesName from "./jobs/CompaniesName";
import CompaniesLogo from "./jobs/CompaniesLogo";
import Countries from "./jobs/Countries";
import Container from "./styles/Container";
import Slider from "./slider/Slider";
import SliderLogo from "./sliderlogo/SliderLogo";
import CompaniesList from "./jobs/CompaniesList";
import InterviewQuestions from "./pages/InterviewQuestions";
import JobTemplate from "./pages/JobTemplate";
import CategoriesName from "./jobs/CategoriesName";
import SearchBar from "./search/SearchBar";
import BestResumeTips from "./pages/BestResumeTips";
import InterviewSkills from "./pages/InterviewSkills";
import ExportPdfComponent from "./exportpdf/ExportPdfComponent";
import ResumeForm from "./exportpdf/ResumeForm";
import ResumeCreator from "./exportpdf/ResumeCreator";
import Page from "./exportpdf/try/Page";
import banner from './images/banner.png';
import {MainBanner} from "./styles/MainBanner";
import ContainerRow from "./styles/ContainerRow";
import ContainerColumn from "./styles/ContainerColumn";
import createresume from './images/create-resume.png'
import findjob from './images/find-job.png'
import companies from './images/companies.png'
import blog from './images/blog.png'
import Column from "./styles/Column";
import {ContainerRowMain, ContainerRowMainReverse} from "./styles/ContainerRowMain";
import ButtonOrange from "./styles/ButtonOrange";
import {DropDownList, ListItem} from "./header/NavDropdown";
import NavStyle from "./header/NavStyle";
import ContainerRowStart from "./styles/ContainerRowStart";
import ButtonCategories from "./styles/ButtonCategories";

const Main = () => {

   React.useEffect(() => {
    jobsData.update()
    // const arr = jobsData.jobs.map(el => el.candidate_required_location)

  }, [])

  const unique = ([...new Set(jobsData.jobs.map(el => el.candidate_required_location))]);

  const onClickToTop = () => {
    window.scrollTo(0,0);
  }

  return(
    <>
      <MainBanner src={banner}/>
<Container>


  <ContainerRowMain>
    <Column itemWidth='49%'>
      <h1>Create your professional Resume now!</h1>
      <p>Save time with our easy 3-step resume builder. No more writer’s block or formatting difficulties in Word. Rapidly make a perfect resume employers love.
      </p>
      <Link to={`resume`} onClick={onClickToTop}><ButtonOrange itemWidth='25%'>Create resume</ButtonOrange></Link>
    </Column>
    <Column itemWidth='49%'>
<img src={createresume}/>
    </Column>
  </ContainerRowMain>


  <ContainerRowMainReverse>
    <Column itemWidth='49%'>
      <img src={findjob}/>
    </Column>
    <Column itemWidth='49%'>
      <h1>Find your dream job without the hassle!</h1>
      <p>TopWork is where top talents go to easily access active and fully job opportunities from vetted tech companies.
      </p>
      <ContainerRowStart>
        {jobsData.categoriesName.map(el => el == 'All categories' ? <Link key={el} to={`categories`} onClick={onClickToTop}><ButtonCategories>{el}</ButtonCategories></Link>
          : <Link key={el} to={`categories/${el.replace('/ ',' ')}`} onClick={onClickToTop}><ButtonCategories>{el}</ButtonCategories></Link>)}
      </ContainerRowStart>
      <br/>
      <Link to={`categories`} onClick={onClickToTop}><ButtonOrange itemWidth='25%'>Find a job</ButtonOrange></Link>
    </Column>
  </ContainerRowMainReverse>


  <ContainerRowMain>
    <Column itemWidth='49%'>
      <h1>Find the Company You'll Love!</h1>
      <p>You can find a huge list of international companies in different fields that are looking for you!    </p>
      <Link to={`companies`} onClick={onClickToTop}><ButtonOrange itemWidth='25%'>Find the company</ButtonOrange></Link>
    </Column>
    <Column itemWidth='49%'>
      <img src={companies}/>
    </Column>
  </ContainerRowMain>

  <ContainerRowMainReverse>
    <Column itemWidth='49%'>
      <img src={blog}/>
    </Column>
    <Column itemWidth='49%'>
      <h1>Useful articles for your successful employment!</h1>
      <p>Useful articles on resume writing, interview questions and much more for your successful employment
      </p>
      <Link to={`blog`} onClick={onClickToTop}><ButtonOrange itemWidth='25%'>Read articles</ButtonOrange></Link>
    </Column>
  </ContainerRowMainReverse>


  {/*<ListItem><NavStyle.Item to='blog/interview-skills' onClick={onClickToTop}>7 interview skills that will get you hired in 2022</NavStyle.Item></ListItem>*/}
  {/*<ListItem><NavStyle.Item to='blog/resume-tips' onClick={onClickToTop}>The Best Resume Tips</NavStyle.Item></ListItem>*/}
  {/*<ListItem><NavStyle.Item to='blog/job-template' onClick={onClickToTop}>Job Template</NavStyle.Item></ListItem>*/}
  {/*<ListItem><NavStyle.Item to='blog/interview-questions' onClick={onClickToTop}>Interview Questions</NavStyle.Item></ListItem>*/}
  {/*<ListItem><NavStyle.Item to='blog/skills-for-resume' onClick={onClickToTop}>Top 10 skills for resumes</NavStyle.Item></ListItem>*/}
  {/*<ListItem><NavStyle.Item to='blog/hired-now' onClick={onClickToTop}>How To Get Hired Now: 12 Actionable Tips That Actually Work</NavStyle.Item></ListItem>*/}


{/*{unique.map(el => <p>{el}</p>)}*/}

  {/*<Link to='/jobs-list'>Jobs</Link>*/}
  {/*    <Link to='/categories'>categories</Link>*/}
  {/*    <Link to='/cars'>Cars</Link>*/}




  {/*<SearchBar/>*/}


  {/*<CategoriesName/>*/}


      
</Container>


      {/*<CompaniesList/>*/}

      {/*<SliderLogo/>*/}

      {/*<Slider/>*/}

      {/*<Countries/>*/}
      {/*{jobsData.jobs.map(job => <span><img src={job.company_logo}/>{job.company_name}</span>)}*/}

      {/*<div>*/}
      {/*  <input type="color" id="head" name="head"*/}
      {/*         value="#e66465"/>*/}
      {/*    <label htmlFor="head">Head</label>*/}
      {/*</div>*/}
      {/*<input type="date" id="start" name="trip-start"*/}
      {/*       value="2018-07-22"*/}
      {/*       min="2018-01-01" max="2018-12-31"/>*/}

      {/*<input type="datetime-local" id="meeting-time"*/}
      {/*       name="meeting-time" value="2018-06-12T19:30"*/}
      {/*       min="2018-06-07T00:00" max="2018-06-14T00:00"/>*/}

      {/*<input type="time" id="appt" name="appt"*/}
      {/*       min="09:00" max="18:00" required/>*/}



    </>
  )
}
export default Main;