import styled from 'styled-components'

const Column = styled.div`
display: flex;
flex-direction: column;
width: ${({itemWidth}) => itemWidth || '100%'};

  @media(max-width: 800px) {
width: 100%;
  }

`;

export default Column;
