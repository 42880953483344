import styled from 'styled-components';

const SliderStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  overflow-X: hidden;
  align-items: center;
      * {
    transition: all 0.5s;  
  }
`;

export default SliderStyled;
