import React from 'react'
import LabelText from "../form.styles/LabelText";
import InputText from "../form.styles/InputText";
import TextEditor from "../editor/TextEditor";
import {ResumeContext} from "../ResumeContext";


const Hobbies = (props) => {
  const context = React.useContext(ResumeContext)
  return(
    <>

      <LabelText>What do you like?</LabelText>
      <div>
        <TextEditor
          html={context.hobby}
          onChange={(e) => context.setHobby(e.target.value)}/>
      </div>
      </>
  )
}
export default Hobbies