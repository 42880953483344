import React from 'react'
import Wrapper from "../form.styles/Wrapper";
import HalfWrapper from "../form.styles/HalfWrapper";
import LabelText from "../form.styles/LabelText";
import InputText from "../form.styles/InputText";
import {ResumeContext} from "../ResumeContext";


const ProfessionalSkills = (props) => {
  const [professionalSkill, setProfessionalSkill] = React.useState('');
  const [professionalSkillPercent, setProfessionalSkillPercent] = React.useState('');

  const getObj = () => ({
    professionalSkill,
    professionalSkillPercent
  });
  React.useEffect(() => {
    props.onChange(getObj());
  },[professionalSkill,
    professionalSkillPercent])
  return(
    <>

      <Wrapper>
        <HalfWrapper>
          <LabelText>Skill name</LabelText>
          <InputText
            type='text'
            value={professionalSkill}
            onChange={(e) => setProfessionalSkill(e.target.value)}/>
        </HalfWrapper>
        <HalfWrapper>
          <LabelText>Percent (number up to 100)</LabelText>
          <InputText
            type='text'
            value={professionalSkillPercent}
            onChange={(e) => setProfessionalSkillPercent(e.target.value)}/>
        </HalfWrapper>
      </Wrapper>
      </>
  )
}
export default ProfessionalSkills;