import React from 'react';
import JobsList from "./jobs/JobsList";
import {Route, Routes} from "react-router";
import Main from "./Main";
import Job from "./jobs/Job";
import OneJob from "./jobs/OneJob";
import ByCategory from "./jobs/ByCategory";
import CategoriesTry from "./jobs/CategoriesTry";
import Cars from "./jobs/Cars";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import CompaniesList from "./jobs/CompaniesList";
import OneCompany from "./jobs/OneCompany";
import AllCategories from "./jobs/AllCategories";
import ResumeCreator from "./exportpdf/ResumeCreator";
import ResumeForm from "./exportpdf/ResumeForm";
import CompaniesName from "./jobs/CompaniesName";
import Resume from "./exportpdf/Resume";
import JobTemplate from "./pages/JobTemplate";
import InterviewQuestions from "./pages/InterviewQuestions";
import InterviewSkills from "./pages/InterviewSkills";
import BestResumeTips from "./pages/BestResumeTips";
import HeaderMain from "./header/HeaderMain";
import Blog from "./pages/Blog";
import SkillsForResume from "./pages/SkillsForResume";
import HiredNow from "./pages/HiredNow";
import ContainerMargin from "./styles/ContainerMargin";
import Helmet from "react-helmet";



function App() {
  return (
    <>

      <HeaderMain/>
      <Helmet>
        <title>Top Work</title>
        <meta name="description" content="Here you can find thousands of jobs and create your professional resume as a bonus" />
        <meta name='keywords' content="jobs, job, hiring, jobs hiring, find a job, resume, cv, create resume, create cv, Software Development, Customer Service, Design, Marketing, Sales, Product, Business, Data, DevOps, Sysadmin, Finance, Legal, Human Resources, QA, Writing"/>
      </Helmet>

      <ContainerMargin>
      <Routes>
        <Route index element={<Main/>}/>
        <Route path={'companies'}>
          <Route index element={<CompaniesList/>}/>
          <Route path={':company'} element={<OneCompany/>}/>
        </Route>
        <Route path={'categories'}>
          {/*<Route index element={<CategoriesTry/>}/>*/}
          <Route index element={<AllCategories/>}/>
          {/*<Route path={':id'} element={<OneJob/>}/>*/}
          <Route path={':category'} element={<ByCategory/>}/>
        </Route>


            {/*<Route index element={<AllCategories/>}/>*/}

          {/*<Route path={':company'} element={<OneCompany/>}/>*/}

        <Route path={'resume-creator'} element={<ResumeForm/>}/>
        <Route path={'resume'} element={<Resume/>}/>
        <Route path={'jobs-list'}>
          <Route index element={<JobsList/>}/>
          {/*<Route path={':id'} element={<Job/>}/>*/}
          <Route path={':id'} element={<OneJob/>}/>
          {/*<Route path={':category'} element={<ByCategory/>}/>*/}
        </Route>
        <Route path={'blog'}>
          {/*<Route path={':id'} element={<Job/>}/>*/}
          <Route index element={<Blog/>}/>
          <Route path={'interview-skills'} element={<InterviewSkills/>}/>
          <Route path={'resume-tips'} element={<BestResumeTips/>}/>
          <Route path={'job-template'} element={<JobTemplate/>}/>
          <Route path={'interview-questions'} element={<InterviewQuestions/>}/>
          <Route path={'skills-for-resume'} element={<SkillsForResume/>}/>
          <Route path={'hired-now'} element={<HiredNow/>}/>
        </Route>

      </Routes>
      </ContainerMargin>
      <Footer/>

    </>
  );
}

export default App;
