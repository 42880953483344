import React from 'react'
import HalfWrapper from "../form.styles/HalfWrapper";
import LabelText from "../form.styles/LabelText";
import InputText from "../form.styles/InputText";
import Wrapper from "../form.styles/Wrapper";
import Select from "../form.styles/Select";
import {
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  DropDownListContainer,
  ListItem
} from "../form.styles/DropdownSelect";



const Languages = (props) => {
  const [language, setLanguage] = React.useState('');
  const [languageLevel, setLanguageLevel] = React.useState('');

  const container = React.useRef(null);

  // const [levels, setLevels] = React.useState([
  //   'Beginner', 'Pre Intermediate', 'Intermediate', 'Proficient', 'Fluent', 'Native'
  // ])


  const options = ['Beginner', 'Pre Intermediate', 'Intermediate', 'Proficient', 'Fluent', 'Native'];


  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState('Intermediate');

  // const toggling = () => {
  //   setIsOpen(!isOpen);
  //   props.onChange({
  //     ...props.languages,
  //     level: selectedOption
  //   })};

  const toggling = () => {
    setLanguageLevel(selectedOption)
    setIsOpen(!isOpen);
  };

  const onOptionClicked = value => {
    setSelectedOption(value);
    setIsOpen(false);

  };

  React.useEffect(() => {
    const handler = (e) => {
      if (!container.current.contains(e.target)) {
        // close dropdown
        setIsOpen(false);
      }

      // do nothing
    };
    window.addEventListener("click", handler, {passive: false});
    return () => {
      window.removeEventListener("click", handler, false);
    };
  }, []);


  const getObj = () => ({
    language,
    languageLevel
  });
  React.useEffect(() => {
    props.onChange(getObj());
  },[language,
    languageLevel])

  return (
    <>

      <Wrapper>
        <HalfWrapper>
          <LabelText>Language</LabelText>
          <InputText
            type='text'
            value={language}
            onChange={(e) => setLanguage(e.target.value)}/>
        </HalfWrapper>
        <HalfWrapper>
          <LabelText>Level</LabelText>
          {/*<InputText*/}
          {/*  value={props.languages.percent}*/}
          {/*  onChange={(e) => props.onChange({*/}
          {/*    ...props.level,*/}
          {/*    level: e.target.value*/}
          {/*  })}/>*/}
          <DropDownContainer ref={container}>
            <DropDownHeader onClick={toggling}
              //                 onChange={(selectedOption) => props.onChange({
              //   ...props.languages,
              //   level: selectedOption
              // })}
            >
              {selectedOption}
            </DropDownHeader>
            {isOpen && (
              <DropDownListContainer>
                <DropDownList>
                  {options.map(option => (
                    <ListItem onClick={() => onOptionClicked(option)} key={Math.random()}>
                      {option}
                    </ListItem>
                  ))}
                </DropDownList>
              </DropDownListContainer>
            )}
          </DropDownContainer>
        </HalfWrapper>
      </Wrapper>

    </>
  )
}
export default Languages;