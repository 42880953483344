import styled from 'styled-components'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


export const Container = styled.div`

font-family: 'Lato', sans-serif;
font-size: 10pt;
//line-height: 1.6;
height: ${({itemHeight}) => `${itemHeight || '1123'}px`};

p {
font-size: 10pt;
line-height: 14pt;
}

img{
//border-radius: 50%;
width: 100px;
height: 100px;
object-fit: cover;
margin-top: 0px;
display: block;
margin-left: auto;
margin-right: auto;
margin-bottom: -20px;
  z-index:  999;
 background: linear-gradient(#fff, #fff) padding-box,
              linear-gradient(45deg, #ddc6a4, #ddc6a4) border-box;
  border: 5px solid transparent;
  border-radius: 100px;

}
h1{
font-size: 75pt;
font-weight: bold;
margin-top: 20px;
margin-bottom: 0px;
//text-transform: uppercase;
color: #464a4e;
font-family: 'Tangerine', cursive;

}
h2{
font-size: 16pt;
font-weight: 900;
margin-top: 0;
margin-bottom: 10px;
margin-left: 0em;
color: #464a4e;

}
h3{
font-size: 26pt;
font-weight: 900;
margin-top: 0;
margin-bottom: 10px;
margin-left: 0em;
color: #464a4e;
font-family: 'Tangerine', cursive;
}
h4{
font-size: 16pt;
margin-top: 0;
margin-bottom: 10px;
}
hr{
border-top: 1px solid;
border-color: ${({itemHrColor}) => itemHrColor || '#393E46'};
margin-bottom: 15px;
margin-top: 15px;
}
a{
text-decoration: none;
color: #393E46;
}
strong{
color: #393E46;
font-weight: bold;
}
ul {
  list-style: none;
}

ul li::before {
  content: "\\2022";
  color: #ddc6a4;
  font-weight: bold;
  display: inline-block; 
  width: 20px;
  margin-left: -1em;
}
`
export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: #ddc6a4;
    font-size: 12pt;
    padding-right: 10px;
`;

export const VerticalLine = styled.div`
 border-left: 2px solid #a6a6a6;
  height: 700px;
  margin-top: 30px;
`
export const HorizontallLine = styled.div`
 border-bottom: 2px solid #a6a6a6;
 margin-bottom: 20px;
`
export const ContainerMain = styled.div`
padding: 20px 70px 70px 70px;
`
export const ContainerHeader = styled.div`
padding: 50px 70px 10px 70px;

a{
text-decoration: none;
color: #393E46;
}
`
export const HrText = styled.hr`
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: .5;
&:before {
    content: '';
    // use the linear-gradient for the fading effect
    // use a solid background color for a solid bar
    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
&:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;
    
    padding: 0 .5em;
    line-height: 1.5em;
    // this is really the only tricky part, you need to specify the background color of the container element...
    color: #818078;
    background-color: #fcfcfa;
  }
`

export const ContainerRow = styled.div`
display: flex;
flex-direction: row;
justify-content: flex-start;
gap: 20px;
a{
text-decoration: none;
color: #393E46;
}
`
export const ContainerRowHeader = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: space-between;
width: 100%;

//gap: 10px 0px;
`
export const HeaderChild = styled.div`
width: 31%;
text-align: center;
padding-top: 10px;
`

export const ContainerRowChild = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: space-between;
width: 100%;
gap: 10px 0px;
`
export const Child = styled.div`
margin-left: 0px;
margin-right: 10px;
width: 45%;
`
export const ContainerColumn = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
width: ${({itemColumnWidth}) => itemColumnWidth || '100%'};

`
export const ColorContainer = styled.div`
// background-color: ${({itemBackground}) => itemBackground || 'burlywood'};
background-color: ${({itemBackground}) => itemBackground || '#f4eae9'};
width: 100%;
height: 100%;
bottom: 0;
color: white;
text-align: center;
z-index: -1;

hr{
border-top: 1px solid;
border-color: ${({itemHrColor}) => itemHrColor || 'white'};
margin-bottom: 10px;
margin-top: 15px;
}
`
export const ColorText = styled.div`
// background-color: ${({itemBackground}) => itemBackground || 'burlywood'};
color: ${({itemColor}) => itemColor || '#5e17eb'};
width: 100%;
`
export const PaddingContainer = styled.div`
padding: 5px 20px 5px 40px;
`
export const PaddingContainerSidebar = styled.div`
padding: 5px 40px 5px 20px;
`
export const TextCenter = styled.div`
text-align: center;
`

export const ProgressBarBox = styled.div`
//margin-top: 3px;
//margin-bottom: 3px;
`


export const ProgressBarWrapper = styled.div`
  background: coral;
  margin-top: 5px;
  height: 5px;
  width: 100%;
`

ProgressBarWrapper.ProgressBar = styled.div`
  background-color: ${({itemColor}) => itemColor || '#5e17eb'};
  height: 5px;
  width:  ${({itemWidth}) => `${itemWidth || '100'}%`}; 
`
export const SkillsStyle = styled.ul`
padding-left: 15px;
margin-top: 0px;
li{
padding-left: 0px;
}
`
const Section = styled.div`
padding: 20px 0;
`
;