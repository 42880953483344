import React, {useRef} from "react";
import {ResumeContext} from "./ResumeContext";
import ResumeForm from "./ResumeForm";
import ResumeCreator from "./ResumeCreator";
import ContainerRow from "../styles/ContainerRow";
import ResumePreview from "./form.styles/ResumePreview";
import ButtonOrange from "../styles/ButtonOrange";
import {useReactToPrint} from "react-to-print";
import Template1 from "./templates/Template1";
import Template2 from "./templates/Template2";
import Template3 from "./templates/Template3";
import Template4 from "./templates/Template4";
import Template5 from "./templates/Template5";
import Template6 from "./templates/Template6";
import Template7 from "./templates/Template7";
import {HrText} from "./templates/Template7Style";
import Template8 from "./templates/Template8";
import Template9 from "./templates/Template9";
import Template15 from "./templates/Template15";
import Template14 from "./templates/Template14";
import Template13 from "./templates/Template13";
import Template12 from "./templates/Template12";
import Template11 from "./templates/Template11";
import Template10 from "./templates/Template10";
import {ContainerForPrint} from "./form.styles/ContainerForPrint";
import HalfWrapper from "./form.styles/HalfWrapper";
import CenterBlock from "./form.styles/CenterBlock";
import Breadcrumbs from "../styles/Breadcrumbs";
import Container from "../styles/Container";
import Helmet from "react-helmet";
import MainHalfWrapper from "./form.styles/MainHalfWrapper";
import ContainerRowResume from "../styles/ContainerRowResume";
import Template16 from "./templates/Template16";
import Template17 from "./templates/Template17";
import Template18 from "./templates/Template18";
import Template19 from "./templates/Template19";
import Template20 from "./templates/Template20";





const Resume = (props) => {
  const [userAvatar, setUserAvatar] = React.useState('');
  const [userWantedJobTitle, setUserWantedJobTitle] = React.useState('');
  const [userFirstName, setUserFirstName] = React.useState('');
  const [userLastName, setUserLastName] = React.useState('');
  const [userEmail, setUserEmail] = React.useState('');
  const [userPhone, setUserPhone] = React.useState('');
  const [userCountry, setUserCountry] = React.useState('');
  const [userCity, setUserCity] = React.useState('');
  const [userAddress, setUserAddress] = React.useState('');
  const [userPostalCode, setUserPostalCode] = React.useState('');
  const [userDrivingLicense, setUserDrivingLicense] = React.useState('');
  // const [userNationality, setUserNationality] = React.useState('');
  // const [userPlaceOfBirth, setUserPlaceOfBirth] = React.useState('');
  const [userDateOfBirth, setUserDateOfBirth] = React.useState('');
  const [professionalSummary, setProfessionalSummary] = React.useState('');
  const [userLinkedin, setUserLinkedin] = React.useState('');
  const [userTwitter, setUserTwitter] = React.useState('');
  const [userWeb, setUserWeb] = React.useState('');
  const [userTelegram, setUserTelegram] = React.useState('');
  const [userGithub, setUserGithub] = React.useState('');









  const [hobby, setHobby] = React.useState('');

  const [jobsExperiences, setJobsExperiences] = React.useState([{
    jobExperienceJobTitle: '',
    jobExperienceEmployer: '',
    jobExperienceStartDate: '',
    jobExperienceEndDate: '',
    jobExperienceCity: '',
    jobExperienceDescription: ''
  }]);
  const [educations, setEducations] = React.useState([{
    educationSchool: '',
    educationDegree: '',
    educationStartDate: '',
    educationEndDate: '',
    educationCity: '',
    educationDescription: '',
  }]);
  const [internships, setInternships] = React.useState([{
    internshipsJobTitle: '',
    internshipsEmployer: '',
    internshipsStartDate: '',
    internshipsEndDate: '',
    internshipsCity: '',
    internshipsDescription: ''
  }])
  const [socials, setSocials] = React.useState([{
    socialLabel: '',
    socialLink: ''
  }]);
  const [skills, setSkills] = React.useState([{
    skill: ''
  }]);
  const [professionalSkills, setProfessionalSkills] = React.useState([{
    professionalSkill: '',
    professionalSkillPercent: ''
  }]);
  const [courses, setCourses] = React.useState([{
    course: '',
    courseInstitution: '',
    courseStartDate: '',
    courseEndDate: '',
  }]);

  const [untitled, setUntitled] = React.useState([{
    untitledTitle:'',
    untitledActivity:'',
    untitledStartDate:'',
    untitledEndDate:'',
    untitledCity:'',
    untitledDescription:'',
  }]);

  const [references, setReferences] = React.useState([{
    referenceName:'',
    referencePosition:'',
    referencePhone:'',
    referenceEmail:''
  }])

  const [languages, setLanguages] = React.useState([{
    language: '',
    languageLevel: ''
  }]);
const [textColor, setTextColor] = React.useState('');
const [backgroundColor, setBackgroundColor] = React.useState('');

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [ComponentTemplate, setComponentTemplate] = React.useState(<Template15 />);

  return (
    <>
      <ResumeContext.Provider value={{
        userAvatar, setUserAvatar,
        userWantedJobTitle, setUserWantedJobTitle,
        userFirstName, setUserFirstName,
        userLastName, setUserLastName,
        userEmail, setUserEmail,
        userPhone, setUserPhone,
        userCountry, setUserCountry,
        userCity, setUserCity,
        userAddress, setUserAddress,
        userPostalCode, setUserPostalCode,
        userDrivingLicense, setUserDrivingLicense,
        // userNationality, setUserNationality,
        // userPlaceOfBirth, setUserPlaceOfBirth,
        userDateOfBirth, setUserDateOfBirth,
        userLinkedin, setUserLinkedin,
        userTwitter, setUserTwitter,
        userWeb, setUserWeb,
        userTelegram, setUserTelegram,
        userGithub, setUserGithub,
        professionalSummary, setProfessionalSummary,
        // educationSchool, setEducationSchool,
        // educationDegree, setEducationDegree,
        // educationStartDate, setEducationStartDate,
        // educationEndDate, setEducationEndDate,
        // educationCity, setEducationCity,
        // educationDescription, setEducationDescription,
        // internshipsJobTitle, setInternshipsJobTitle,
        // internshipsEmployer, setInternshipsEmployer,
        // internshipsStartDate, setInternshipsStartDate,
        // internshipsEndDate, setInternshipsEndDate,
        // internshipsCity, setInternshipsCity,
        // internshipsDescription, setInternshipsDescription,
        // socialLabel, setSocialLabel,
        // socialLink, setSocialLink,
        // skill, setSkill,
        // professionalSkill, setProfessionalSkill,
        // professionalSkillPercent, setProfessionalSkillPercent,
        // course, setCourse,
        // courseInstitution, setCourseInstitution,
        // courseStartDate, setCourseStartDate,
        // courseEndDate, setCourseEndDate,
        // untitledTitle, setUntitledTitle,
        // untitledActivity, setUntitledActivity,
        // untitledStartDate, setUntitledStartDate,
        // untitledEndDate, setUntitledEndDate,
        // untitledCity, setUntitledCity,
        // untitledDescription, setUntitledDescription,
        // language, setLanguage,
        // languageLevel, setLanguageLevel,
        hobby, setHobby,
        //arrays
        jobsExperiences, setJobsExperiences,
        educations, setEducations,
        internships, setInternships,
        socials, setSocials,
        skills, setSkills,
        professionalSkills, setProfessionalSkills,
        courses, setCourses,
        untitled, setUntitled,
        languages, setLanguages,
        references, setReferences,
        textColor, setTextColor,
        backgroundColor, setBackgroundColor
      }}>
        <Helmet>
          <title>Top Work | Create your professional Resume now!</title>
          <meta name="description" content="Save time with our easy 3-step resume builder. No more writer’s block or formatting difficulties in Word. Rapidly make a perfect resume employers love." />
          <meta name='keywords' content="create resume, create resume free, create resume online free, how to create resume for freshers, how to create resume in laptop, resume template"/>
        </Helmet>

        <ContainerRowResume>

          <MainHalfWrapper>
            <Container>
            <Breadcrumbs>
              <Breadcrumbs.Link to='/'>Home</Breadcrumbs.Link> >
              <Breadcrumbs.Link to=''>Resume</Breadcrumbs.Link>
            </Breadcrumbs>
            </Container>
            <ResumeForm/>
          </MainHalfWrapper>
          <MainHalfWrapper>

            <div>
              <button onClick={() => setComponentTemplate(<Template1 />)}>Template 1</button>
              <button onClick={() => setComponentTemplate(<Template2 />)}>Template 2</button>
              <button onClick={() => setComponentTemplate(<Template3 />)}>Template 3</button>
              <button onClick={() => setComponentTemplate(<Template4 />)}>Template 4</button>
              <button onClick={() => setComponentTemplate(<Template5 />)}>Template 5</button>
              <button onClick={() => setComponentTemplate(<Template6 />)}>Template 6</button>
              <button onClick={() => setComponentTemplate(<Template7 />)}>Template 7</button>
              <button onClick={() => setComponentTemplate(<Template8 />)}>Template 8</button>
              <button onClick={() => setComponentTemplate(<Template9 />)}>Template 9</button>
              <button onClick={() => setComponentTemplate(<Template10 />)}>Template 10</button>
              <button onClick={() => setComponentTemplate(<Template11 />)}>Template 11</button>
              <button onClick={() => setComponentTemplate(<Template12 />)}>Template 12</button>
              <button onClick={() => setComponentTemplate(<Template13 />)}>Template 13</button>
              <button onClick={() => setComponentTemplate(<Template14 />)}>Template 14</button>
              <button onClick={() => setComponentTemplate(<Template15 />)}>Template 15</button>
              <button onClick={() => setComponentTemplate(<Template16 />)}>Template 16</button>
              <button onClick={() => setComponentTemplate(<Template17 />)}>Template 17</button>
              <button onClick={() => setComponentTemplate(<Template18 />)}>Template 18</button>
              <button onClick={() => setComponentTemplate(<Template19 />)}>Template 19</button>
              <button onClick={() => setComponentTemplate(<Template20 />)}>Template 20</button>
            </div>

            <ResumePreview >
              <ContainerForPrint ref={componentRef}>
                {ComponentTemplate}
              </ContainerForPrint>

              {/*<ResumeCreator ref={componentRef}/>*/}

            </ResumePreview>

              <ButtonOrange itemWidth='20%' onClick={handlePrint}>Print this out!</ButtonOrange>


          </MainHalfWrapper>
        </ContainerRowResume>

        {/*<ResumeForm/>*/}
        {/*<ResumePreview>*/}
        {/*  <ResumeCreator ref={componentRef}/>*/}
        {/*</ResumePreview>*/}
        {/*<ButtonOrange onClick={handlePrint}>Print this out!</ButtonOrange>*/}





        {/*<ResumePreview>*/}
        {/*  <Template1 ref={componentRef}/>*/}
        {/*</ResumePreview>*/}
        {/*<ButtonOrange onClick={handlePrint}>Print this out!</ButtonOrange>*/}

        {/*<ResumePreview>*/}
        {/*  <Template2 ref={componentRef}/>*/}
        {/*</ResumePreview>*/}
        {/*<ButtonOrange onClick={handlePrint}>Print this out!</ButtonOrange>*/}

        {/*<ResumePreview>*/}
        {/*  <Template3 ref={componentRef}/>*/}
        {/*</ResumePreview>*/}
        {/*<ButtonOrange onClick={handlePrint}>Print this out!</ButtonOrange>*/}

        {/*<ResumePreview>*/}
        {/*  <Template4 ref={componentRef}/>*/}
        {/*</ResumePreview>*/}
        {/*<ButtonOrange onClick={handlePrint}>Print this out!</ButtonOrange>*/}

        {/*<ResumePreview>*/}
        {/*  <Template5 ref={componentRef}/>*/}
        {/*</ResumePreview>*/}
        {/*<ButtonOrange onClick={handlePrint}>Print this out!</ButtonOrange>*/}


        {/*<h2>Template 6</h2>*/}
        {/*<ResumePreview>*/}
        {/*  <Template6 ref={componentRef}/>*/}
        {/*</ResumePreview>*/}
        {/*<ButtonOrange onClick={handlePrint}>Print this out!</ButtonOrange>*/}

        {/*<h2>Template 7</h2>*/}
        {/*<ResumePreview>*/}
        {/*  <Template7 ref={componentRef}/>*/}
        {/*</ResumePreview>*/}
        {/*<ButtonOrange onClick={handlePrint}>Print this out!</ButtonOrange>*/}

        {/*<h2>Template 8</h2>*/}
        {/*<ResumePreview>*/}
        {/*  <Template8 ref={componentRef}/>*/}
        {/*</ResumePreview>*/}
        {/*<ButtonOrange onClick={handlePrint}>Print this out!</ButtonOrange>*/}

        {/*<h2>Template 9</h2>*/}
        {/*<ResumePreview>*/}
        {/*    <Template9 ref={componentRef}/>*/}
        {/*</ResumePreview>*/}
        {/*<ButtonOrange onClick={handlePrint}>Print this out!</ButtonOrange>*/}


        {/*<h2>Template 10</h2>*/}
        {/*<ResumePreview>*/}
        {/*  <Template10 ref={componentRef}/>*/}
        {/*</ResumePreview>*/}
        {/*<ButtonOrange onClick={handlePrint}>Print this out!</ButtonOrange>*/}


        {/*<h2>Template 11</h2>*/}
        {/*<ResumePreview>*/}
        {/*  <Template11 ref={componentRef}/>*/}
        {/*</ResumePreview>*/}
        {/*<ButtonOrange onClick={handlePrint}>Print this out!</ButtonOrange>*/}


        {/*<h2>Template 12</h2>*/}
        {/*<ResumePreview>*/}
        {/*  <Template12 ref={componentRef}/>*/}
        {/*</ResumePreview>*/}
        {/*<ButtonOrange onClick={handlePrint}>Print this out!</ButtonOrange>*/}

        {/*<h2>Template 13</h2>*/}
        {/*<ResumePreview>*/}
        {/*  <Template13 ref={componentRef}/>*/}
        {/*</ResumePreview>*/}
        {/*<ButtonOrange onClick={handlePrint}>Print this out!</ButtonOrange>*/}

        {/*<h2>Template 14</h2>*/}
        {/*<ResumePreview>*/}
        {/*  <Template14 ref={componentRef}/>*/}
        {/*</ResumePreview>*/}
        {/*<ButtonOrange onClick={handlePrint}>Print this out!</ButtonOrange>*/}

        {/*<h2>Template 15</h2>*/}
        {/*<ResumePreview>*/}
        {/*  <Template15 ref={componentRef}/>*/}
        {/*</ResumePreview>*/}
        {/*<ButtonOrange onClick={handlePrint}>Print this out!</ButtonOrange>*/}



      </ResumeContext.Provider>


    </>
  )
}
export default Resume;