import React from 'react'
import TextOrange from "../styles/TextOrange";
import Container from "../styles/Container";
import {BlogImageLeft, BlogImageRight} from "./BlogImage";
import img1 from "./images/foto3.jpg";
import img2 from "./images/foto4.jpg";
import Breadcrumbs from "../styles/Breadcrumbs";
import Helmet from "react-helmet";



const InterviewSkills = () => {
  return (
    <>
      <Helmet>
        <title>Top Work | 7 interview skills that will get you hired in 2022</title>
        <meta name="description" content="Interview preparation, Know the company, Speaking skills, Think first, speak second, Listening, Show interest, not desperation, Show gratitude" />
        <meta name='keywords' content="interview skills, hired, hired in 2022, Interview preparation, Soft skills, Communication skills, Analytical skills"/>
      </Helmet>
      <Container>
        <Breadcrumbs>
          <Breadcrumbs.Link to='/'>Home</Breadcrumbs.Link> >
          <Breadcrumbs.Link to='/blog'>Blog</Breadcrumbs.Link> >
          <Breadcrumbs.Link to=''>7 interview skills that will get you hired in 2022</Breadcrumbs.Link>
        </Breadcrumbs>
      <h1>7 interview skills that will get you hired in 2022</h1>
        <BlogImageRight src={img2}/>
      <TextOrange><h2>1. Interview preparation</h2></TextOrange>
      <p>Part of having a successful interview comes from taking the time to prepare . Winging it rarely turns out well, and an interviewer can sense ill preparation from a mile away. So, what should your preparation entail? We suggest you re-read the job description several times so that you are capable of discussing the position and the requirements associated with it comfortably. Think about how you can illustrate the desired skills with the experience you have.</p>
      <p>Review your resume and know it well. It’s what got you to this point so you impressed a recruiter with it. Just be aware that your interviewer may not have read it thoroughly or remember it, so refresh their memory during your interview. One thing you can do to help your chances is make sure what you wrote in your resume matches what you say on the phone or your Zoom ( Skype , Google Meet, FaceTime, whatever) call. </p>
    <p>We realize eye contact is difficult on a video call, but there are a couple of things you can do to improve the impression you make. Take time before your interview to set up your camera so it shows you fully facing the screen and find a spot without concert posters or silly photos on the wall behind you. If you must, blur your background to avoid distractions, but be aware that blurring uses bandwidth.</p>
      <p>According to Forbes, 2022 career trends will move toward video interviews. This means the interview process may take longer than ever before. Employers are nervous about hiring someone they haven’t met in person, and they may now schedule three to eight interviews before making a decision. </p>
    <p>By the way, dress from head-to-toe in interview clothes. Not only will this prevent any mishaps where a hiring manager sees your pajama bottoms, but it will get you in the proper frame of mind.</p>
      <p>Before you get an in-person interview, you may have to pass a phone screening. Check out these commonly asked phone interview questions to help you leap that hurdle. Research common job interview questions asked of candidates in your field. Take some time to formulate appropriate answers to those questions. </p>
      <p>You can also prepare by understanding the types of questions you may be asked. </p>
      <ul>
        <li><TextOrange>Soft skills interview questions or interpersonal skill interview questions:</TextOrange> These questions focus on the attributes that make you a good listener or reliable employee. They include organization, communication (you can show off this skill during your interview), teamwork, punctuality. By the way, there’s no (or only a rare) excuse for being late to a job interview.</li>
        <li><TextOrange>Communication skills interview questions:</TextOrange> These will focus on the style you use to communicate. Be prepared with an anecdote or example of how you send and receive messages at work.</li>
        <li><TextOrange>Analytical skills interview questions:</TextOrange> These questions aim to discover how you problem solve or make decisions in different situations. Once again, be prepared with an example.</li>
      </ul>
      <TextOrange><h2>2. Know the company</h2></TextOrange>
      <p>Being prepared also means learning about the company or organization with whom you are interviewing. You can’t answer the question “Why do you want to work here?” if you don’t know anything about the company. Get a feel for its philosophy and how it goes about business. How? First, if you know someone in the company, talk to them. If not, check out the company’s website, but also peruse LinkedIn for more information. Do a Google search for news articles or manager interviews, too. It’s better to have too much information than too little.</p>
      <p>Consider writing down information you want to mention in your interview because the act of physically writing helps you remember. It can also help to have those notes in front of you if you are worried your nervousness will get the better of you. Just make sure to still have a human conversation with the interviewer and not stare at your notes as if it’s an exam. Remember, they’re as much of a living, breathing person as you are ; )</p>
      <p>Once you have done all of this, you will feel prepared, and feeling prepared means that you are.</p>
      <TextOrange><h2>3. Speaking skills</h2></TextOrange>
    <p>An interview is a lot like an acting audition. You need to think about your speaking skills and work on them prior to the interview. The interview process can be a tense experience, and because of that, many people talk fast, mumble, or speak too softly. This is NOT what you want to do. Take a deep breath and practice mindful speaking. Speak slowly, maintain a sense of calm, and remember that your interviewer should be able to hear every word you say without difficulty. If you speak slowly and confidently, your words will be better absorbed, and your self-confidence will be admired.</p>
      <p>You will be able to answer soft skills interview questions much more easily, or maybe circumvent their being asked at all, if you demonstrate those communication and listening skills during your interview.</p>
      <p>Eye contact is equally important. It connects you to your interviewers and also projects confidence. If too much eye contact makes you nervous, try looking at the interviewer’s eyebrows or chin. It will still seem as though you are looking at their eyes. You can also practice in the mirror or with a friend to become used to eye contact.</p>
      <TextOrange><h2>4. Think first, speak second</h2></TextOrange>
    <p>It’s OK to allow for silence as you take a moment to gather your thoughts before answering a question. That will give you time to formulate a clear statement and keep your cool.</p>
      <p>If public speaking doesn’t come naturally to you - that’s OK, it rarely does to anyone. Try practicing in front of a mirror. It may seem weird, but believe us, it will eventually make you more comfortable. Cut yourself some slack and just be yourself (just a tad more professional).</p>
  <p>To help you speak up, remember your body language, too. Not only does lifting your spine and guiding your shoulders down and away from your ears show confidence, but it helps you breathe easier and project your voice better. Speaking well also demonstrates your communication skills to hiring managers. If you can get your message across well during the stress of a job interview, it speaks volumes about your ability to communicate once you get the job. </p>
        <BlogImageLeft src={img1}/>
        <TextOrange><h2>5. Listening</h2></TextOrange>
      <p>There are going to be two people at your interview, maybe more. Showcasing your ability to be a good listener is incredibly important. This is not just an interview skill. Listening demonstrates your thoughtfulness and ability to work on a team. Furthermore, if you do not practice good listening skills, you may miss the point of a question and give a less-than-impressive answer. Stay in the moment, and give your interviewer the respect that they deserve. Make eye contact and nod your head as you listen and make sure you understand the questions or comments coming from your interviewer.</p>
      <p>Asking clarifying questions also shows that you were paying attention and helps avoid the embarrassment of a misunderstanding. Try to think of the interview as a conversation. Keep in mind that employers may be trying to sell you on the company as much as you are trying to sell them. Listening for important information will allow you to refer to the information in later answers, ask good followup questions, and decide whether it is the right position for you. Don’t forget, you don’t just want out of your current job and into a new job, you want the right job for you!</p>
      <TextOrange><h2>6. Show interest, not desperation</h2></TextOrange>
    <p>It is one thing to be interested in a job, and quite another to be desperate for a job. Desperation does not impress anyone. Your job is to show sincere interest in the job at hand while proving your excellent qualifications for said job. Approach every interview like it is for your dream job. It’s tough when you really NEED the job, but remind yourself that the best way to get it is through a great  interview , not by appealing to your potential employer’s sense of guilt.</p>
      <p>Your interviewer wants to hire the best candidate, not the most desperate candidate. As the interviewee, it’s your job to present yourself as a highly qualified and professional with the work experience that makes you perfect for the position. That is what employers are looking for. No matter how much you need the job, it is crucial that you refrain from pleading or begging, as it only creates an uncomfortable and unprofessional situation. Be cool, calm, and collected. Show self-confidence through your body language and eye contact. It will pay off.</p>
      <TextOrange><h2>7. Show gratitude</h2></TextOrange>
      <p>Leave a great impression as you go.</p>
      <p>Once your interview is over, it is important that you end it on a high note. You want your interviewer to think, “I’m putting that person at the top of my “Hire Them!” list. Smile, say thank you, and reiterate your interest in the job if you can fit it in organically. There may be a next interview on the horizon and you want to be at the top of the call-back list. Your interview skills are important, but so is your attitude. </p>
      </Container>
      </>
  )
}
export default InterviewSkills;