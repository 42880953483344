import styled from 'styled-components'


const CardCategory = styled.div`
display: flex;
justify-content: center;
align-items: center;
background-image: linear-gradient(to right, #f5ce62, #FF8C32);
color: white;
text-align: center;
font-weight: bold;
padding: 20px;
width: 100px;
height: 50px;
margin: 10px;
font-size: 16px;
&:hover{
background-image: linear-gradient(to right top , #FF8C32, #f5ce62);

}
`;
export default CardCategory;
