import React from 'react';
import Container from "../styles/Container";
import TextOrange from "../styles/TextOrange";
import img1 from "./images/foto14.jpg";
import img2 from "./images/foto11.jpg";
import img3 from "./images/foto5.jpg";
import {BlogImageLeft, BlogImageRight} from "./BlogImage";
import Breadcrumbs from "../styles/Breadcrumbs";
import LinkStyle from "../styles/LinkStyle";
import Helmet from "react-helmet";


const HiredNow = () => {
  return (
    <>

      <Helmet>
        <title>Top Work | How To Get Hired Now: 12 Actionable Tips That Actually Work</title>
        <meta name="description" content="How To Get Hired Now: 12 Actionable Tips That Actually Work" />
        <meta name='keywords' content="Hired Now, Get Hired Now, Actionable Tips, hired, find job"/>
      </Helmet>

      <Container>
        <Breadcrumbs>
          <Breadcrumbs.Link to='/'>Home</Breadcrumbs.Link> >
          <Breadcrumbs.Link to='/blog'>Blog</Breadcrumbs.Link> >
          <Breadcrumbs.Link to=''>How To Get Hired Now: 12 Actionable Tips That Actually Work</Breadcrumbs.Link>
        </Breadcrumbs>


        <h1>How To Get Hired Now: 12 Actionable Tips That Actually Work</h1>
        <BlogImageRight src={img1}/>
        <p>When you're looking for a job, you often face challenges, like automated application processes

          and a pool of qualified applicants, which can make standing out as a viable candidate challenging.

          In this article, we share 12 strategies to boost your competitive value and help you get the job.</p>


        <TextOrange><h2>12 tips for your job search</h2></TextOrange>
        <p>From performing the proper research to enhancing your resume, there are several ways you can improve your chances of getting hired. Here are some ways you can get noticed by hiring managers:</p>

        <TextOrange><h4>1. Get to know the company</h4></TextOrange>
        <p>Before you apply for the job, research the company to understand who they are and what they do. Study the job description to align your skills with what the company is looking for, and highlight relevant skills on your resume.</p>
<p>Find out who is in charge of hiring or heads a department so you can address them by name when inquiring about the position or in subsequent communications. Thorough knowledge of the company gives you the advantage so you can demonstrate how you can improve business or fill a need.</p>

        <TextOrange><h4>2. Compose a compelling cover letter</h4></TextOrange>
        <p>Your cover letter is your chance to provide evidence of why you are the right candidate for the position. Use keywords and phrases from the job description to demonstrate your awareness of the role's responsibilities and how you meet its expectations. Try to anticipate what questions the hiring manager may ask and answer them in your cover letter.</p>


        <TextOrange><h4>3. Redefine your resume</h4></TextOrange>
        <p>Resumes that list job duties lack the vital information that tells the hiring manager why you are the best one for the position. When evaluating your resume, be sure it is outlining not only your duties but how you performed them in ways others might not have. Use job-related keywords and action verbs such as "created" or "designated" throughout your resume to lend impact to your statements.</p>

        <TextOrange><h4>4. Make your social media profile look professional</h4></TextOrange>
        <p>Employers may inspect social media profiles to determine a candidate's qualifications or fit for the company.</p>
        <p>As with your cover letter, use relevant keywords or phrases that match the job and demonstrate why you are a capable employee. Make it easy for employers to contact you by prominently displaying your phone number or email address.</p>
        <p>Include a friendly photograph that shows your face so employers recognize you when you arrive for an interview. Depending on the social media platform, you may be able to ask for recommendations or reviews from colleagues to reinforce your qualifications.</p>

        <TextOrange><h4>5. Build a personal website</h4></TextOrange>
        <p>A personal website is a great way to present a portfolio of work and provide evidence of your abilities. Web building templates make it relatively simple to build a website within a few hours.</p>
        <p>Be sure to include a photograph of your face and provide contact links to make it easy to reach you. Build pages that highlight your passion for the industry or the skills you have acquired along the way.</p>
        <p>Consider adding a FAQ page that answers questions about you and your experience. Once your site is online, use your website address in all communications.</p>


        <TextOrange><h4>6. Turn to your network</h4></TextOrange>
        <p>Use your network of key individuals and enlist their help to gain insight into where or how you can improve your resume or cover letter. Send a mass email to friends, family, teachers and former employers or colleagues.</p>
        <p>Tell them about your goals and obstacles and ask for referrals. Ask former employers or supervisors to refer you for positions or write letters of recommendation.</p>


        <TextOrange><h4>7. Work with a career coach</h4></TextOrange>
        <p>Consider working with a career coach to help you strategize your approach to a job proposition. A coach can inspect your resumes and cover letters and help with applications.</p>
        <p>Your career coach can also challenge you to set professional goals and form plans to reach them, including suggestions for ideal jobs or complementary industries. Working with a coach can keep you motivated and optimistic while you improve your skills.</p>

        <TextOrange><h4>8. Rehearse the interview</h4></TextOrange>
        <p>Research the company you're interested in to determine what kind of interviews the company conducts. Some hiring managers may ask you to present a case study, provide a sample of a project or tell a story of how you accomplished a task.</p>
        <p>Perform searches to find out what other candidates have experienced in similar interviews, and rehearse your answers with a friend or colleague.</p>

        <TextOrange><h4>9. Prepare a persuasive narrative</h4></TextOrange>
        <p>During an interview, you might be asked to tell the interviewer about yourself. While it's fine to talk about hobbies and interests, keep the focus on the position and tailor your answers to fit the job description. Craft a compelling story that illustrates how you solved a problem, made improvements or overcame an obstacle at work.</p>

        <TextOrange><h4>10. Be aware of nonverbal cues</h4></TextOrange>
        <p>Present yourself in a way that conveys openness and willingness to learn and grow. Give the interviewer your attention and remember to smile. Stay aware of your nonverbal cues, such as your expressions or gestures.</p>

        <TextOrange><h4>11. Promote your soft skills</h4></TextOrange>
        <p>While your resume might list your hard skills, be sure to highlight soft skills such as teamwork, communication, problem-solving and time management in your cover letter and interview. Qualifying for a position means you have both the technical skills the job requires and the ability to be flexible, work independently or collaborate with colleagues.</p>

        <TextOrange><h4>12. Volunteer or seek additional training</h4></TextOrange>
        <p>Consider volunteering in your spare time to gain additional experience or on-the-job training. Volunteering demonstrates compassion and a willingness to work selflessly.</p>
        <p>If volunteering isn't practical, use the time to pursue relevant education or training that will add to your experience and expertise. Remember to include volunteering and training on your resume and mention them in your cover letter, website or interview.</p>

      </Container>
    </>
  )
}
export default HiredNow;