import styled from 'styled-components'


const ButtonLink = styled.button`
outline:none;
background: none;
color: var(--orange);
border: none;
font-size: 16px;
font-weight: bold;
cursor: pointer;

`;
export default ButtonLink;
