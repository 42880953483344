import React from 'react';
import styled from 'styled-components';

const SliderImageStyled = styled.div`
  position: relative;
  margin: auto;
  padding: 10px;
 
  img{
    border: 3px solid white;
    width: 80px;
    height: auto;
  }
`;
SliderImageStyled.Label = styled.div`
  text-align: center!important;
  font-size: 14px;
  font-weight: bold;
  color: var(--darkblue);
  padding: 3px;
`
export default SliderImageStyled;