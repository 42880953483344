import React from 'react'
import ContainerRow from "../../styles/ContainerRow";
import Column from "../../styles/Column";
import Wrapper from "../form.styles/Wrapper";
import HalfWrapper from "../form.styles/HalfWrapper";
import LabelText from "../form.styles/LabelText";
import InputText, {InputFile} from "../form.styles/InputText";
import ContainerForm from "../form.styles/ContainerForm";
import {ResumeContext} from "../ResumeContext";
import User from "../User";

const PersonalDetails = (props) => {
  const context = React.useContext(ResumeContext)

  // const onImageChange = (e) => {
  //   const reader = new FileReader();
  //   reader.onload = (file) => {
  //     props.onChange({ ...props.user, image: reader.result });
  //   };
  //
  //   reader.readAsDataURL(e.target.files[0]);
  // };
  const onImageChange = (e) => {
    const reader = new FileReader();
    reader.onload = (file) => {
      context.setUserAvatar(reader.result);
    };

    reader.readAsDataURL(e.target.files[0]);
  };
  return (

    <>



      <ContainerRow>
        <Column itemWidth='50%'>
        </Column>
        <Column itemWidth='50%'>
        </Column>
      </ContainerRow>
      <Wrapper>
        <HalfWrapper>
          <LabelText>Wanted Job Title</LabelText>
          <InputText
            type='text'
            value={context.userWantedJobTitle}
            onChange={(e) => context.setUserWantedJobTitle(e.target.value)}/>
        </HalfWrapper>
        <HalfWrapper>
          <LabelText>Choose file</LabelText>
          {/*<InputText type='file'*/}
          {/*           onChangeImage={(e) => props.onChangeImage({*/}
          {/*             ...props.user,*/}
          {/*             image: e.target.value})}/>*/}

          <InputFile type='file'
                     onChange = {onImageChange}/>
        </HalfWrapper>


      </Wrapper>
      <Wrapper>
        <HalfWrapper>
          <LabelText>First Name</LabelText>
          <InputText
            type='text'
            value={context.userFirstName}
            onChange={(e) => context.setUserFirstName(e.target.value)}/>
        </HalfWrapper>
        <HalfWrapper>
          <LabelText>Last Name</LabelText>
          <InputText
            type='text'
            value={context.userLastName}
            onChange={(e) => context.setUserLastName(e.target.value)}/>
        </HalfWrapper>
      </Wrapper>

      <Wrapper>
        <HalfWrapper>
          <LabelText>Email</LabelText>
          <InputText
            type='email'
            value={context.userEmail}
            onChange={(e) => context.setUserEmail(e.target.value)}/>
        </HalfWrapper>
        <HalfWrapper>
          <LabelText>Phone</LabelText>
          <InputText
            type='tel'
            value={context.userPhone}
            onChange={(e) => context.setUserPhone(e.target.value)}/>
        </HalfWrapper>
      </Wrapper>

      <Wrapper>
        <HalfWrapper>
          <LabelText>Country</LabelText>
          <InputText
            type='text'
            value={context.userCountry}
            onChange={(e) => context.setUserCountry(e.target.value)}/>
        </HalfWrapper>
        <HalfWrapper>
          <LabelText>City</LabelText>
          <InputText
            type='text'
            value={context.userCity}
            onChange={(e) => context.setUserCity(e.target.value)}/>
        </HalfWrapper>
      </Wrapper>

      <Wrapper>
        <HalfWrapper>
          <LabelText>Address</LabelText>
          <InputText
            type='text'
            value={context.userAddress}
            onChange={(e) => context.setUserAddress(e.target.value)}/>
        </HalfWrapper>

        <HalfWrapper>
          <LabelText>Postal Code</LabelText>
          <InputText
            type='text'
            value={context.userPostalCode}
            onChange={(e) => context.setUserPostalCode(e.target.value)}/>
        </HalfWrapper>
      </Wrapper>

      <Wrapper>
        <HalfWrapper>
          <LabelText>Driving License</LabelText>
          <InputText
            type='text'
            value={context.userDrivingLicense}
            onChange={(e) => context.setUserDrivingLicense(e.target.value)}/>
        </HalfWrapper>

      {/*  <HalfWrapper>*/}
      {/*    <LabelText>Nationality</LabelText>*/}
      {/*    <InputText*/}
      {/*      type='text'*/}
      {/*      value={context.userNationality}*/}
      {/*      onChange={(e) => context.setUserNationality(e.target.value)}/>*/}
      {/*  </HalfWrapper>*/}
      {/*</Wrapper>*/}

      {/*<Wrapper>*/}
      {/*  <HalfWrapper>*/}
      {/*    <LabelText>Place Of Birth</LabelText>*/}
      {/*    <InputText*/}
      {/*      type='text'*/}
      {/*      value={context.userPlaceOfBirth}*/}
      {/*      onChange={(e) => context.setUserPlaceOfBirth(e.target.value)}/>*/}
      {/*  </HalfWrapper>*/}
        <HalfWrapper>
          <LabelText>Date Of Birth</LabelText>
          <InputText
            type='text'
            value={context.userDateOfBirth}
            onChange={(e) => context.setUserDateOfBirth(e.target.value)}/>
        </HalfWrapper>
      </Wrapper>

      <Wrapper>
        <HalfWrapper>
          <LabelText>Linkedin</LabelText>
          <InputText
            type='text'
            value={context.userLinkedin}
            onChange={(e) => context.setUserLinkedin(e.target.value)}/>
        </HalfWrapper>

        <HalfWrapper>
          <LabelText>Twitter</LabelText>
          <InputText
            type='text'
            value={context.userTwitter}
            onChange={(e) => context.setUserTwitter(e.target.value)}/>
        </HalfWrapper>
      </Wrapper>
      <Wrapper>
        <HalfWrapper>
          <LabelText>Personal website</LabelText>
          <InputText
            type='text'
            value={context.userWeb}
            onChange={(e) => context.setUserWeb(e.target.value)}/>
        </HalfWrapper>

        <HalfWrapper>
          <LabelText>Telegram</LabelText>
          <InputText
            type='text'
            value={context.userTelegram}
            onChange={(e) => context.setUserTelegram(e.target.value)}/>
        </HalfWrapper>
      </Wrapper>
      <Wrapper>
        <HalfWrapper>
          <LabelText>GitHub</LabelText>
          <InputText
            type='text'
            value={context.userGithub}
            onChange={(e) => context.setUserGithub(e.target.value)}/>
        </HalfWrapper>

        <HalfWrapper>

        </HalfWrapper>
      </Wrapper>
    </>
  )
}
export default PersonalDetails;