import styled from 'styled-components'


const CardLogo = styled.div`
display: flex;
flex-direction: row;
flex-flow: wrap;
justify-content: space-between;
`;
CardLogo.Card = styled.div`
display: flex;
flex-direction: column;
text-align: center;
justify-content: center;
width: 80px;
height: auto;
`
export default CardLogo;
