import styled from 'styled-components'
import {Link} from "react-router-dom";
import NavStyle from "./NavStyle";


export const MobileMenu = styled.div`
display: none;
position: relative;



 @media(max-width: 800px) {
display: block;
  }
`;
export const DropdownMenuHeader = styled("button")`
//width: 100%;
right: 0;
//margin-left: 300px;
font-size: 16px;
font-weight: bold;
background: none;
outline: none;
border: none;
display: block;
color: white;
//margin: 5px;
text-decoration: none;
transition: color 0.1s ease 0s;
  &:hover{
  color:var(--darkblue);
  }
`;

export const DropDownMenuListContainer = styled("div")`
//z-index: 1;
position: absolute;
width: 480px;
//margin-left: -82vh;
//margin-right: 0;
right: 0;
//margin-left: -600px;
background-color: var(--orange);
color: black;
overflow: hidden;
`;

export const DropDownMenuList = styled("ul")`
background-color: var(--orange);
width: 100%;
  padding: 0;
  margin: 0;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  color: black;
 
  &:first-child {
    padding: 10px 12px;
  }
`;

export const MenuListItem = styled("li")`
padding: 5px 12px;
text-align: left;
list-style: none;
  &:hover{
  color: var(--orange);
    }
`;

export const MenuListlink = styled(Link)`
color: white;
margin: 5px;
text-decoration: none;
  @media(max-width: 800px) {
  color: white;
  margin: 5px;
  text-decoration: none;
    }

  &:hover{
  color:var(--darkblue);
  }
`

