import React, {useRef} from 'react';
import ContainerRow from "../styles/ContainerRow";
import ContainerForm from "./form.styles/ContainerForm";
import PersonalDetails from "./form-components/PersonalDetails";
import EmploymentHistory from "./form-components/EmploymentHistory";
import Education from "./form-components/Education";
import ProfessionalSummary from "./form-components/ProfessionalSummary";
import Social from "./form-components/Social";
import Internships from "./form-components/Internships";
import Skills from "./form-components/Skills";
import ProfessionalSkills from "./form-components/ProfessionalSkills";
import Courses from "./form-components/Courses";
import UnnamedSection from "./form-components/UnnamedSection";
import Hobbies from "./form-components/Hobbies";
import {ResumeContext} from "./ResumeContext";
import Languages from "./form-components/Languages";
import ButtonLink from "./form.styles/ButtonLink";
import {ButtonColorPicker, InputColor} from "./form.styles/InputColor";
import Colors from "./form-components/Colors";
import References from "./form-components/References";
import Accordion from "../components/Accordion";


const ResumeForm = (props) => {


  const context = React.useContext(ResumeContext);


  const [color, setColor] = React.useState('#5e72e4');
  const [range, setRange] = React.useState();
  const [textColor, setTextColor] = React.useState('');
  const [backgroundColor, setBackgroundColor] = React.useState('');

  // const onClickBlue = () => {
  //   setTextColor('#5e72e4')
  // }
  // const onClickYellow = () => {
  //   setTextColor('#eefd26')
  // }
  // const onClickPink = () => {
  //   setTextColor('#ffaca3')
  // }
  // const onClickGreen = () => {
  //   setTextColor('#46ec5a')
  // }

  const onClickAddJobsExperiences = () => {
    const it = {
      jobExperienceJobTitle: '',
      jobExperienceEmployer: '',
      jobExperienceStartDate: '',
      jobExperienceEndDate: '',
      jobExperienceCity: '',
      jobExperienceDescription: ''
    };
    context.setJobsExperiences([...context.jobsExperiences, it]);
  }

  const onEmploymentHistoryChange = (index, job) => {
    const jobs = [...context.jobsExperiences];
    jobs[index] = job;
    context.setJobsExperiences(jobs);
  };


  const onClickAddEducation = () => {
    const it = {
      educationSchool: '',
      educationDegree: '',
      educationStartDate: '',
      educationEndDate: '',
      educationCity: '',
      educationDescription: '',
    };
    context.setEducations([...context.educations, it]);
  }

  const onEducationsChange = (index, school) => {
    const schools = [...context.educations];
    schools[index] = school;
    context.setEducations(schools);
  };

  const onClickAddInternships = () => {
    const it = {
      internshipsJobTitle: '',
      internshipsEmployer: '',
      internshipsStartDate: '',
      internshipsEndDate: '',
      internshipsCity: '',
      internshipsDescription: ''
    };
    context.setInternships([...context.internships, it]);
  }

  const onInternshipsChange = (index, internship) => {
    const internshipsJobs = [...context.internships];
    internshipsJobs[index] = internship;
    context.setInternships(internshipsJobs);
  };


  const onClickAddSocials = () => {
    const it = {
      socialLabel: '',
      socialLink: ''
    };
    context.setSocials([...context.socials, it]);
  }

  const onSocialsChange = (index, social) => {
    const socials = [...context.socials];
    socials[index] = social;
    context.setSocials(socials);
  };


  const onClickAddLanguages = () => {
    const it = {
      language: '',
      languageLevel: ''
    };
    context.setLanguages([...context.languages, it]);
  }

  const onLanguagesChange = (index, langauge) => {
    const languages = [...context.languages];
    languages[index] = langauge;
    context.setLanguages(languages);
  };

  const onClickAddSkills = () => {
    const it = {
      skill: ''
    };
    context.setSkills([...context.skills, it]);
  }

  const onSkillsChange = (index, skill) => {
    const skills = [...context.skills];
    skills[index] = skill;
    context.setSkills(skills);
  };


  const onClickAddProfessionalSkills = () => {
    const it = {
      professionalSkill: '',
      professionalSkillPercent: ''
    };
    context.setProfessionalSkills([...context.professionalSkills, it]);
  }

  const onProfessionalSkillsChange = (index, professionalSkill) => {
    const professionalSkills = [...context.professionalSkills];
    professionalSkills[index] = professionalSkill;
    context.setProfessionalSkills(professionalSkills);
  };



  const onClickAddCourses = () => {
    const it = {
      course: '',
      courseInstitution: '',
      courseStartDate: '',
      courseEndDate: '',
    };
    context.setCourses([...context.courses, it]);
  }

  const onCoursesChange = (index, course) => {
    const courses = [...context.courses];
    courses[index] = course;
    context.setCourses(courses);
  };


  const onClickAddUntitled = () => {
    const it = {
      untitledTitle:'',
      untitledActivity:'',
      untitledStartDate:'',
      untitledEndDate:'',
      untitledCity:'',
      untitledDescription:'',
    };
    context.setUntitled([...context.untitled, it]);
  }

  const onUntitledChange = (index, untitle) => {
    const untitled = [...context.untitled];
    untitled[index] = untitle;
    context.setUntitled(untitled);
  };


  const onClickAddReferences = () => {
    const it = {
      referenceName:'',
      referencePosition:'',
      referencePhone:'',
      referenceEmail:''
    };
    context.setReferences([...context.references, it]);
  }

  const onReferencesChange = (index, reference) => {
    const references = [...context.references];
    references[index] = reference;
    context.setReferences(references);
  };

  return (
    <>
      <ContainerRow>

        <ContainerForm>

          <Colors/>


          {/*PERSONAL DETAILS*/}
          <h2>Personal Details</h2>
          <PersonalDetails/>

          {/*PROFESSIONAL SUMMARY*/}
          <h2>Professional Summary</h2>
          <ProfessionalSummary/>

          {/*JOB EXPERIENCE*/}




          <h2>Employment History</h2>
          {context.jobsExperiences.map((it, index) => (
            <EmploymentHistory key={index} data={it} onChange={(newObj) => onEmploymentHistoryChange(index, newObj)}/>
          ))}
          <ButtonLink onClick={onClickAddJobsExperiences}>+ add one more item</ButtonLink>


          {/*EDUCATION*/}
          <h2>Education</h2>
          {context.educations.map((it, index) => (
            <Education key={index} data={it} onChange={(newObj) => onEducationsChange(index, newObj)}/>
          ))}
          <ButtonLink onClick={onClickAddEducation}>+ add one more item</ButtonLink>

          {/*INTERNSHIPS*/}
          <h2>Internships</h2>
          {context.internships.map((it, index) => (
            <Internships key={index} data={it} onChange={(newObj) => onInternshipsChange(index, newObj)}/>
          ))}
          <ButtonLink onClick={onClickAddInternships}>+ add one more item</ButtonLink>

          {/*SOCIAL*/}
          <h2>Websites & Social Links</h2>
          {context.socials.map((it, index) => (
            <Social key={index} data={it} onChange={(newObj) => onSocialsChange(index, newObj)}/>
          ))}
          <ButtonLink onClick={onClickAddSocials}>+ add one more item</ButtonLink>

          {/*LANGUAGES*/}
          <h2>Languages</h2>
          {context.languages.map((it, index) => (
            <Languages key={index} data={it} onChange={(newObj) => onLanguagesChange(index, newObj)}/>
          ))}
          <ButtonLink onClick={onClickAddLanguages}>+ add one more item</ButtonLink>


          {/*SKILLS*/}
          <h2>Skills</h2>
          {context.skills.map((it, index) => (
            <Skills key={index} data={it} onChange={(newObj) => onSkillsChange(index, newObj)}/>
          ))}
          <ButtonLink onClick={onClickAddSkills}>+ add one more item</ButtonLink>

          {/*PROFESSIONAL SKILLS*/}
          <h2>Professional Skills</h2>
          {context.professionalSkills.map((it, index) => (
            <ProfessionalSkills key={index} data={it} onChange={(newObj) => onProfessionalSkillsChange(index, newObj)}/>
          ))}
          <ButtonLink onClick={onClickAddProfessionalSkills}>+ add one more item</ButtonLink>

          {/*COURSES*/}
          <h2>Courses</h2>
          {context.courses.map((it, index) => (
            <Courses key={index} data={it} onChange={(newObj) => onCoursesChange(index, newObj)}/>
          ))}
          <ButtonLink onClick={onClickAddCourses}>+ add one more item</ButtonLink>

          {/*UNTITLED SECTION*/}
          {context.untitled.map((it, index) => (
            <UnnamedSection key={index} data={it} onChange={(newObj) => onUntitledChange(index, newObj)}/>
          ))}
          <ButtonLink onClick={onClickAddUntitled}>+ add one more item</ButtonLink>

          {/*HOBBIES*/}
          <h2>Hobbies</h2>
          <Hobbies/>

          <h2>References</h2>
          {context.references.map((it, index) => (
            <References key={index} data={it} onChange={(newObj) => onReferencesChange(index, newObj)}/>
          ))}
          <ButtonLink onClick={onClickAddReferences}>+ add one more item</ButtonLink>


          {/*<h2>Skills</h2>*/}
          {/*<input type="range" min="0" max="100" step="1" value={range} onChange={(e) => setRange(e.target.value)}/>*/}
          {/*{range}*/}



        </ContainerForm>


        {/*<ResumePreview>*/}
        {/*  <ResumeCreator ref={componentRef}/>*/}
        {/*</ResumePreview>*/}
        {/*<ButtonOrange onClick={handlePrint}>Print this out!</ButtonOrange>*/}


      </ContainerRow>


    </>
  )
}
export default ResumeForm;