import React from 'react';
import {ResumeContext} from "../ResumeContext";
import {
  Child, ColorContainer,
  ColorText,
  Container, ContainerColumn, ContainerHeader, ContainerMain,
  ContainerRow, ContainerRowChild, ContainerRowHeader, HeaderChild,
  PaddingContainer, ProgressBarBox,
  ProgressBarWrapper, SkillsStyle,
  TextCenter
} from "./Template10Style";
import CenterBlock from "../form.styles/CenterBlock";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarDays, faClock} from "@fortawesome/free-regular-svg-icons";
import {faCar, faEnvelope, faLocationDot, faPhone} from "@fortawesome/free-solid-svg-icons";


const Template10 = React.forwardRef((props, ref) => {
  const context = React.useContext(ResumeContext)
  let height

  const [customHeight, setCustomHeight] = React.useState(1122);
  React.useEffect(() => {
    let rect = document.getElementById('container').getBoundingClientRect();
    height = rect.height;
    if(height >= 1123){

      setCustomHeight(2245)
    } else
    if (height < 1123){
      setCustomHeight(1123)
    }
  }, [context]);

  return (
    <>

      <div ref={ref}>
        <Container itemHeight={customHeight}>
          <div id='container'>

          <ContainerMain>

              <TextCenter>
                <h2>{context.userFirstName} {context.userLastName}</h2>
                <h3>{context.userWantedJobTitle}</h3>
              </TextCenter>

              {context.userAvatar === '' ? null
                : <CenterBlock><img src={context.userAvatar}/></CenterBlock>}
              <hr/>
              <ContainerRowHeader>
                {context.userPhone.length > 0 ?
                  <HeaderChild><p><FontAwesomeIcon icon={faPhone}/> <a href="tel:context.userPhone">{context.userPhone}</a></p></HeaderChild> : null}

                {context.userEmail === '' ? null :
                  <HeaderChild><p><FontAwesomeIcon icon={faEnvelope}/> <a href="mailto:context.userEmail">{context.userEmail}</a></p></HeaderChild>}



                {context.userCountry === '' && context.userCity === '' && context.userAddress === '' && context.userPostalCode === '' ?
                  null : <HeaderChild><p><FontAwesomeIcon icon={faLocationDot}/> {context.userCountry} {context.userCity} {context.userAddress} {context.userPostalCode}</p></HeaderChild>}


                {context.userDateOfBirth.length > 0 ?
                  <HeaderChild><p><FontAwesomeIcon icon={faCalendarDays}/> {context.userDateOfBirth}</p></HeaderChild> : null}

                {context.userDrivingLicense === '' ? null :
                  <HeaderChild><p><FontAwesomeIcon icon={faCar}/> Driving licence: {context.userDrivingLicense}</p></HeaderChild>}



                {context.socials.length > 0 ? <>
                  {context.socials.map((el, index) => <HeaderChild><p><a href={el.socialLink} target='_blank'>{el.socialLabel} </a></p></HeaderChild>)}
                </> : null}
              </ContainerRowHeader>











            {context.professionalSummary === '' ? null :
              <>
                <hr/>
                <ContainerRow>
                <ContainerColumn itemColumnWidth='22%'>
                  <ColorText itemColor={context.textColor}>
                    {/*<hr itemHrColor={context.textColor}/>*/}
                    <h3>Summary</h3>
                  </ColorText>
                </ContainerColumn>
                <ContainerColumn>
                 <p><div dangerouslySetInnerHTML={{__html: context.professionalSummary}}/></p>
                </ContainerColumn>
              </ContainerRow></>
              }<br/>


            {context.languages[0].language === '' && context.languages[0].languageLevel === '' ?
              null
              :
              <>
                <hr/>
                <ContainerRow>
                  <ContainerColumn itemColumnWidth='22%'>
                    <ColorText itemColor={context.textColor}>
                      {/*<hr itemHrColor={context.textColor}/>*/}
                      <h3>Languages</h3>
                    </ColorText>
                  </ContainerColumn>
                  <ContainerColumn>
                    <p><ContainerRowChild>
                      {context.languages.map((el, index) => <>
                        <Child>{el.language} - {el.languageLevel}
                          {/*<ProgressBarWrapper>*/}
                          {/*  <ProgressBarWrapper.ProgressBar itemColor={context.textColor}*/}
                          {/*                                  itemWidth={el.languageLevel === 'Beginner' ? 16 :*/}
                          {/*                                    el.languageLevel === 'Pre Intermediate' ? 36 :*/}
                          {/*                                      el.languageLevel === 'Intermediate' ? 60 :*/}
                          {/*                                        el.languageLevel === 'Proficient' ? 80 :*/}
                          {/*                                          el.languageLevel === 'Fluent' ? 92 :*/}
                          {/*                                            el.languageLevel === 'Native' ? 100 : null*/}
                          {/*                                  }*/}
                          {/*    // {el.languageLevel === 'Beginner' ? 16 : null}*/}
                          {/*    //*/}
                          {/*    // 'Beginner', 'Pre Intermediate', 'Intermediate', 'Proficient', 'Fluent', 'Native'*/}
                          {/*  />*/}
                          {/*</ProgressBarWrapper>*/}
                        </Child><br/>
                      </>)}</ContainerRowChild></p>
                  </ContainerColumn>
                </ContainerRow>
              </>}<br/>



            {context.skills[0].skill === '' ?
              null : <>
              <hr/>
              <ContainerRow>
                <ContainerColumn itemColumnWidth='22%'>
                  <ColorText itemColor={context.textColor}>
                    {/*<hr itemHrColor={context.textColor}/>*/}
                    <h3>Skills</h3>
                  </ColorText>
                </ContainerColumn>
                <ContainerColumn>
                 <SkillsStyle>
                   <p><ContainerRowChild>{context.skills.map((el, index) => <>
                    <Child><li>{el.skill}</li></Child>
                  </>)}</ContainerRowChild></p> </SkillsStyle>
                </ContainerColumn>
              </ContainerRow>

              </>}<br/>

            {context.professionalSkills[0].professionalSkill === '' && context.professionalSkills[0].professionalSkillPercent === '' ?
              null
              :
              <>
              <hr/>
              <ContainerRow>
                <ContainerColumn itemColumnWidth='22%'>
                  <ColorText itemColor={context.textColor}>
                    {/*<hr itemHrColor={context.textColor}/>*/}
                    <h3>Professional skills</h3>
                  </ColorText>
                </ContainerColumn>
                <ContainerColumn>
                  <p><ContainerRowChild>
                    {context.professionalSkills.map((el, index) => <>
                      <Child>
                        <ProgressBarBox>{el.professionalSkill}
                        <ProgressBarWrapper>
                          <ProgressBarWrapper.ProgressBar itemColor={context.textColor}
                                                          itemWidth={parseInt(el.professionalSkillPercent)}/>
                        </ProgressBarWrapper>
                        </ProgressBarBox>
                      </Child>
                      <br/>
                    </>)}</ContainerRowChild></p>
                </ContainerColumn>
              </ContainerRow>
              </>}<br/>












            {context.jobsExperiences[0].jobExperienceJobTitle === '' && context.jobsExperiences[0].jobExperienceStartDate === '' && context.jobsExperiences[0].jobExperienceEndDate === '' &&
            context.jobsExperiences[0].jobExperienceEmployer === '' && context.jobsExperiences[0].jobExperienceCity === '' && context.jobsExperiences[0].jobExperienceDescription === '' ?
              null
              :
              <>        <hr/>
              <ContainerRow>
                <ContainerColumn itemColumnWidth='22%'>
                  <ColorText itemColor={context.textColor}>
                    {/*<hr itemHrColor={context.textColor}/>*/}
                    <h3>Job experience</h3>
                  </ColorText>
                </ContainerColumn>
                <ContainerColumn>
                  {context.jobsExperiences.map((el, index) => <>
                        <p><b>{el.jobExperienceJobTitle}</b> {el.jobExperienceStartDate === '' && el.jobExperienceEndDate === '' ?
                          null :
                          <> {new Date(el.jobExperienceStartDate).toLocaleString('en-us',{month:'short', year:'numeric'})} - {new Date(el.jobExperienceEndDate).toLocaleString('en-us',{month:'short', year:'numeric'})}<br/></>}
                          {el.jobExperienceEmployer}{el.jobExperienceCity}
                          <div dangerouslySetInnerHTML={{__html: el.jobExperienceDescription}}/>
                          </p>
                  </>)}
                </ContainerColumn>
              </ContainerRow>
              </>}


            {context.educations[0].educationSchool === '' && context.educations[0].educationCity === '' && context.educations[0].educationDegree === '' &&
            context.educations[0].educationStartDate === '' && context.educations[0].educationEndDate === '' && context.educations[0].educationDescription === '' ?
              null
              :
              <>
              <hr/>
              <ContainerRow>
                <ContainerColumn itemColumnWidth='22%'>
                  <ColorText itemColor={context.textColor}>
                    {/*<hr itemHrColor={context.textColor}/>*/}
                    <h3>Education</h3>
                  </ColorText>
                </ContainerColumn>
                <ContainerColumn>
                  {context.educations.map((el, index) =>
                    <>
                      <p>
                        <b>{el.educationDegree}</b> {el.educationStartDate === '' && el.educationEndDate === '' ?
                            null :
                            <> {new Date(el.educationStartDate).toLocaleString('en-us',{month:'short', year:'numeric'})} - {new Date(el.educationEndDate).toLocaleString('en-us',{month:'short', year:'numeric'})}<br/></>}<br/>
                            {el.educationSchool} {el.educationCity}<br/>
                            <div dangerouslySetInnerHTML={{__html: el.educationDescription}}/>
                          </p>

                    </>)}
                </ContainerColumn>
              </ContainerRow>
              </>}


            {context.internships[0].internshipsJobTitle === '' && context.internships[0].internshipsStartDate === '' && context.internships[0].internshipsEndDate === '' &&
            context.internships[0].internshipsEmployer === '' && context.internships[0].internshipsCity === '' && context.internships[0].internshipsDescription === '' ?
              null
              :
              <>
              <hr/>
              <ContainerRow>
                <ContainerColumn itemColumnWidth='22%'>
                  <ColorText itemColor={context.textColor}>
                    {/*<hr itemHrColor={context.textColor}/>*/}
                    <h3>Internships</h3>
                  </ColorText>
                </ContainerColumn>
                <ContainerColumn>
                  {context.internships.map((el, index) =>
                    <>
                          <p><b>{el.internshipsJobTitle}</b> {el.internshipsStartDate === '' && el.internshipsEndDate === '' ?
                            null :
                            <> {new Date(el.internshipsStartDate).toLocaleString('en-us',{month:'short', year:'numeric'})} - {new Date(el.internshipsEndDate).toLocaleString('en-us',{month:'short', year:'numeric'})}<br/></>}
                            {el.internshipsEmployer} {el.internshipsCity}
                            <div dangerouslySetInnerHTML={{__html: el.internshipsDescription}}/>
                          </p>
                    </>
                  )}
                </ContainerColumn>
              </ContainerRow>

              </>}


            {context.courses[0].course === '' && context.courses[0].courseInstitution === '' && context.courses[0].courseStartDate === '' &&
            context.courses[0].courseEndDate === '' ?
              null
              :
              <>
              <hr/>
              <ContainerRow>
                <ContainerColumn itemColumnWidth='22%'>
                  <ColorText itemColor={context.textColor}>
                    {/*<hr itemHrColor={context.textColor}/>*/}
                    <h3>Courses</h3>
                  </ColorText>
                </ContainerColumn>
                <ContainerColumn>
                  {context.courses.map((el, index) => <>
                        <p><b> {el.course}</b> {el.courseStartDate === '' && el.courseEndDate === '' ?
                          null :
                          <> {new Date(el.courseStartDate).toLocaleString('en-us',{month:'short', year:'numeric'})} - {new Date(el.courseEndDate).toLocaleString('en-us',{month:'short', year:'numeric'})}<br/></>}
                          {el.courseInstitution}
                        </p>

                  </>)}
                </ContainerColumn>
              </ContainerRow>
              </>}


            {context.untitled[0].untitledTitle === '' && context.untitled[0].untitledActivity === '' && context.untitled[0].untitledCity === '' &&
            context.untitled[0].untitledStartDate === '' && context.untitled[0].untitledEndDate === '' && context.untitled[0].untitledDescription === '' ?
              null
              : <>
                {context.untitled.map((el, index) => <>

                <hr/>
                <ContainerRow>
                  <ContainerColumn itemColumnWidth='22%'>
                    <ColorText itemColor={context.textColor}>
                      {/*<hr itemHrColor={context.textColor}/>*/}
                      <h3>{el.untitledTitle}</h3>
                    </ColorText>
                  </ContainerColumn>
                  <ContainerColumn>

                        <p><b>{el.untitledActivity}</b> {el.untitledStartDate === '' && el.untitledEndDate === '' ?
                          null :
                          <> {new Date(el.untitledStartDate).toLocaleString('en-us',{month:'short', year:'numeric'})} - {new Date(el.untitledEndDate).toLocaleString('en-us',{month:'short', year:'numeric'})}<br/></>}
                          {el.untitledCity}
                          <div dangerouslySetInnerHTML={{__html: el.untitledDescription}}/>
                        </p>

                  </ContainerColumn>
                </ContainerRow>
                </>)}
              </>}

            {context.hobby.length > 0 ?
              <>

                {/*<ColorContainer style={{backgroundColor: `${context.textColor}`}}>*/}
                <hr/>
                <ContainerRow>
                  <ContainerColumn itemColumnWidth='22%'>
                    <ColorText itemColor={context.textColor}>
                      {/*<hr itemHrColor={context.textColor}/>*/}
                      <h3>Hobbies</h3>
                    </ColorText>
                  </ContainerColumn>
                  <ContainerColumn>
                    <p>
                      <div dangerouslySetInnerHTML={{__html: context.hobby}}/>
                    </p>
                  </ContainerColumn>
                </ContainerRow>

              </>
              : null}


            {context.references[0].referenceName === '' && context.references[0].referencePosition === '' && context.references[0].referencePhone === '' &&
            context.references[0].referenceEmail === '' ?
              null
              :
              <>
                <hr/>

                <ContainerRow>
                  <ContainerColumn itemColumnWidth='22%'>
                    <ColorText itemColor={context.textColor}>
                      {/*<hr itemHrColor={context.textColor}/>*/}
                      <h3>References</h3>
                    </ColorText>
                  </ContainerColumn>
                  <ContainerColumn>
                    <div><p> {context.references.map((el, index) => <>
                      <b>{el.referenceName} - {el.referencePosition}</b> - <a href="tel:{el.referencePhone}">{el.referencePhone}</a> - <a href="mailto:el.referenceEmail">{el.referenceEmail}</a><br/><br/>
                    </>)}</p></div>
                  </ContainerColumn>
                </ContainerRow>


              </>}

          </ContainerMain>
          </div>
        </Container>
      </div>
    </>
  )
})
export default Template10;