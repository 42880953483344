import React from 'react';
import jobsData from "../data/JobsData";
import {observer} from "mobx-react-lite";
import {Link} from "react-router-dom";
import ContainerColumn from "../styles/ContainerColumn";
import Container from "../styles/Container";
import ContainerRowTop from "../styles/ContainerRowTop";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCircleCheck,
  faClock, faEarthAmericas,
  faFileInvoiceDollar,
  faLocationDot
} from "@fortawesome/free-solid-svg-icons";
import CardList from "../styles/CardList";
import Column from "../styles/Column";
import TextOrange from "../styles/TextOrange";
import ButtonCategories from "../styles/ButtonCategories";
import {faCalendarDays} from "@fortawesome/free-regular-svg-icons";
import ButtonOrange from "../styles/ButtonOrange";
import LinkStyle from "../styles/LinkStyle";
import Breadcrumbs from "../styles/Breadcrumbs";
import ContainerRowStart from "../styles/ContainerRowStart";
import CardCategory from "../styles/CardCategory";


const itemPerRow = 40;

const CategoriesTry = () => {
  const [filteredList, setFilteredList] = React.useState(jobsData.jobs);
  const [selectedCategory, setSelectedCategory] = React.useState();

  const onOtherJobClick = () => {
    window.scrollTo(0, 0);
  };

  React.useEffect(() => {
    jobsData.getCategories()
  }, [])

  React.useEffect(() => {
    jobsData.update()
  }, [])

  // const onClick = (e) => {
  //   e.preventDefault();
  //   console.log(selectedCategory)
  // }
  const hangleChange = event => {
    setSelectedCategory(event.target.value);

    console.log('value is:', event.target.value);
  };

  const handleClick = (e) => {
    e.preventDefault();
    console.log('hi')
  }
  let countArray = jobsData.jobs.length;



  const [next, setNext] = React.useState(itemPerRow);

  const handleMoreItems = () => {
    setNext(next + itemPerRow);
  }


  return (
    <>
      <Container>
        <Breadcrumbs>
          <Breadcrumbs.Link to='/'>Home</Breadcrumbs.Link> >
          <Breadcrumbs.Link to=''>Jobs</Breadcrumbs.Link>
        </Breadcrumbs>
        <h2>{countArray} jobs</h2>
      {/*<p><Link key={job.id} to={`${job.id}`}>View job</Link></p>*/}
      {jobsData.categories.map((category, id) => (<ButtonCategories key={category.id} onClick={hangleChange} value={category.name}>{category.name}</ButtonCategories>))}
        <TextOrange><h2>{selectedCategory ? selectedCategory : 'All categories'}</h2></TextOrange>


        <ContainerRowStart>
          {jobsData.categoriesName.map(el => el == 'All categories' ? <Link key={el} to={`./all-categories`}><CardCategory>{el}</CardCategory></Link>
            : <Link key={el} to={`./${el.replace('/ ',' ')}`}><CardCategory>{el}</CardCategory></Link>)}
        </ContainerRowStart>


      {jobsData.jobs.slice(0,next).map((job) => <p>
        {/*{el.title} - {el.category}*/}
        {!selectedCategory ?
          <LinkStyle key={job.id} to={`/jobs-list/${job.id}`} onClick={onOtherJobClick}>
            <CardList>
            <ContainerRowTop>
              <Column itemWidth='80%'>
                <h2>{job.title}</h2>
                <h4><TextOrange><FontAwesomeIcon icon={faBuilding}/></TextOrange> {job.company_name}      {job.salary !== '' ? <><TextOrange><FontAwesomeIcon icon={faFileInvoiceDollar}/></TextOrange> {job.salary}</> : null}</h4>
                <p>{job.candidate_required_location !== '' ? <> <TextOrange><FontAwesomeIcon icon={faEarthAmericas}/></TextOrange>  {job.candidate_required_location}</> : null}    {job.job_type !== '' ? <><TextOrange><FontAwesomeIcon icon={faClock}/></TextOrange> {job.job_type.replace('_',' ')}</> : null}</p>
                <p><TextOrange><FontAwesomeIcon icon={faCircleCheck}/> {job.category}</TextOrange></p>
                <p><TextOrange><FontAwesomeIcon icon={faCalendarDays}/></TextOrange> Published: {job.publication_date.slice(0, 10)}</p>
              </Column>
              <Column itemWidth='20%'>
                <CardList.Logo> <img src={job.company_logo}/></CardList.Logo>
                <p><Link key={job.id} to={`/jobs-list/${job.id}`} onClick={onOtherJobClick}><ButtonOrange>VIEW JOB</ButtonOrange></Link></p>
              </Column>
            </ContainerRowTop>
            {/*<ContainerRowTop>*/}
            {/*  /!*<Column itemWidth='50%'>*!/*/}
            {/*  */}
            {/*  /!*  <p><TextOrange><FontAwesomeIcon icon={faCalendarDays}/></TextOrange> Published: {job.publication_date.slice(0, 10)}</p>*!/*/}
            {/*  /!*</Column>*!/*/}
            {/*  <Column itemWidth='20%'>*/}
            {/*    <p><Link key={job.id} to={`${job.id}`} onClick={onOtherJobClick}><ButtonOrange>VIEW JOB</ButtonOrange></Link></p>*/}
            {/*  </Column>*/}
            {/*</ContainerRowTop>*/}

            {/*<CardList.TagsContainer>*/}
            {/*  {job.tags.map((tag) => <li>{tag}</li>)}*/}
            {/*</CardList.TagsContainer>*/}
            </CardList></LinkStyle> :
        job.category === selectedCategory ?
          <LinkStyle key={job.id} to={`/jobs-list/${job.id}`} onClick={onOtherJobClick}><CardList>
          <ContainerRowTop>
            <Column itemWidth='80%'>
              <h2>{job.title}</h2>
              <h4><TextOrange><FontAwesomeIcon icon={faBuilding}/></TextOrange> {job.company_name}  {job.salary !== '' ? <><TextOrange><FontAwesomeIcon icon={faFileInvoiceDollar}/></TextOrange> {job.salary}</> : null}</h4>
              <p>{job.candidate_required_location !== '' ? <> <TextOrange><FontAwesomeIcon icon={faEarthAmericas}/></TextOrange>  {job.candidate_required_location}</> : null}  {job.job_type !== '' ? <><TextOrange><FontAwesomeIcon icon={faClock}/></TextOrange> {job.job_type.replace('_',' ')}</> : null}</p>
              <p><TextOrange><FontAwesomeIcon icon={faCircleCheck}/> {job.category}</TextOrange></p>
              <p><TextOrange><FontAwesomeIcon icon={faCalendarDays}/></TextOrange> Published: {job.publication_date.slice(0, 10)}</p>
            </Column>
            <Column itemWidth='20%'>
              <CardList.Logo> <img src={job.company_logo}/></CardList.Logo>
              <p><Link key={job.id} to={`/jobs-list/${job.id}`} onClick={onOtherJobClick}><ButtonOrange>VIEW JOB</ButtonOrange></Link></p>
            </Column>
          </ContainerRowTop>
          {/*<ContainerRowTop>*/}
          {/*  <Column itemWidth='50%'>*/}
          {/*    <p><TextOrange><FontAwesomeIcon icon={faCalendarDays}/></TextOrange> Published: {job.publication_date.slice(0, 10)}</p>*/}
          {/*  </Column>*/}
          {/*  <Column itemWidth='20%'>*/}
          {/*    <p><Link key={job.id} to={`${job.id}`} onClick={onOtherJobClick}><ButtonOrange>VIEW JOB</ButtonOrange></Link></p>*/}
          {/*  </Column>*/}
          {/*</ContainerRowTop>*/}

          {/*<CardList.TagsContainer>*/}
          {/*  {job.tags.map((tag) => <li>{tag}</li>)}*/}
          {/*</CardList.TagsContainer>*/}
          </CardList></LinkStyle> : null}
      </p>)}


        {next < jobsData.jobs.length && (
          <button onClick={handleMoreItems}>Load more</button>
        )}
      </Container>
    </>
  )
}
export default observer(CategoriesTry);