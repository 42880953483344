import styled from 'styled-components'


const Select = styled.select`
width: 100%;
border-radius: 3px;
padding: 10px 12px;
display: block;
caret-color: var(--orange);
background-color: rgb(239, 242, 249);
outline: currentcolor none medium;
border: 0px none;
transition: color 0.1s ease 0s;
&:focus{
border-bottom: 2px solid var(--orange);
}

option{
padding: 10px 12px;
 &:focus-within {
    border: 2px solid red;
}
}
`;
export default Select;
