import styled from 'styled-components'



const CompanyProfileStyle = styled.div`
background-image: linear-gradient(to right , #f5ce62, #FF8C32);
height: 200px;
color: white;
text-align: center;
vertical-align: middle;
h1{
font-size: 50px;
line-height: 40px;
padding-top: 10px;
}
img{
background-color: white;
box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
}
`;

export default CompanyProfileStyle;
