import React from 'react';
import Container from "../styles/Container";
import jobsData from "../data/JobsData";
import ContainerRowTop from "../styles/ContainerRowTop";
import Column from "../styles/Column";
import TextOrange from "../styles/TextOrange";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCircleCheck,
  faClock,
  faEarthAmericas,
  faFileInvoiceDollar, faMagnifyingGlass
} from "@fortawesome/free-solid-svg-icons";
import CardList from "../styles/CardList";
import {faCalendarDays} from "@fortawesome/free-regular-svg-icons";
import {Link} from "react-router-dom";
import ButtonOrange from "../styles/ButtonOrange";
import LinkStyle from "../styles/LinkStyle";
import ButtonCategories from "../styles/ButtonCategories";
import CardCategory from "../styles/CardCategory";
import Breadcrumbs from "../styles/Breadcrumbs";
import ContainerRowStart from "../styles/ContainerRowStart";
import Helmet from "react-helmet";
import InputText from "../exportpdf/form.styles/InputText";
import {InputSearch, SearchBar, SearchIcon} from "../exportpdf/form.styles/InputSearch";
import {ButtonLoadMore, LoadMoreBox} from "../exportpdf/form.styles/ButtonLoadMore";


const itemPerRow = 10;

const AllCategories = () => {
  const [query, setQuery] = React.useState("");

  React.useEffect(() => {
    jobsData.update()
  },[])

  const handleClick = (e) => {
    e.preventDefault();
    console.log('hi')
  }

  const onOtherJobClick = () => {
    window.scrollTo(0, 0);
  };

  const count = jobsData.jobs.length

  const [next, setNext] = React.useState(itemPerRow);

  const handleMoreItems = () => {
    setNext(next + itemPerRow);
  }

  return(
    <>
      <Helmet>
        <title>Top Work | All jobs</title>
        <meta name="description" content="Here you can find thousands of jobs and create your professional resume as a bonus" />
        <meta name='keywords' content="jobs, job, hiring, jobs hiring, find a job, resume, cv, create resume, create cv, Software Development, Customer Service, Design, Marketing, Sales, Product, Business, Data, DevOps, Sysadmin, Finance, Legal, Human Resources, QA, Writing"/>
      </Helmet>
      <Container>
        <Breadcrumbs>
          <Breadcrumbs.Link to='/'>Home</Breadcrumbs.Link> >
          <Breadcrumbs.Link to=''>Jobs</Breadcrumbs.Link>
        </Breadcrumbs>

        <SearchBar>
          <SearchIcon><TextOrange><FontAwesomeIcon icon={faMagnifyingGlass}/></TextOrange></SearchIcon>
          <InputSearch placeholder="Search..." onChange={event => setQuery(event.target.value)}/>
        </SearchBar>

        <h1>All categories <TextOrange>{count} jobs</TextOrange></h1>
        {/*{jobsData.categoriesName.map(el => el == 'All categories' ? <LinkStyle key={el} to={`/categories`}><ButtonCategories>{el}</ButtonCategories></LinkStyle>*/}
        {/*  : <LinkStyle key={el} to={`/categories/${el.replace('/ ',' ')}`}><ButtonCategories>{el}</ButtonCategories></LinkStyle>)}*/}


        <ContainerRowStart>
          {jobsData.categoriesName.map(el => el == 'All categories' ? <Link key={el} to={``}><ButtonCategories>{el}</ButtonCategories></Link>
            : <Link key={el} to={`../${el.replace('/ ',' ')}`}><ButtonCategories>{el}</ButtonCategories></Link>)}
        </ContainerRowStart>



        {jobsData.jobs.filter(job => {
          if (query === '') {
            return job;
          } else if (job.title.toLowerCase().includes(query.toLowerCase().trim()) || job.company_name.toLowerCase().includes(query.toLowerCase().trim())) {
            return job;
          }
        }).slice(0,next).map(job =>
          <LinkStyle key={job.id} to={`/jobs-list/${job.id}`} onClick={onOtherJobClick}>
          <CardList>
          <ContainerRowTop>
            <Column itemWidth='80%'>
              <h2>{job.title}</h2>
              <h4><TextOrange><FontAwesomeIcon icon={faBuilding}/></TextOrange> {job.company_name}      {job.salary !== '' ? <><TextOrange><FontAwesomeIcon icon={faFileInvoiceDollar}/></TextOrange> {job.salary}</> : null}</h4>
              <p>{job.candidate_required_location !== '' ? <> <TextOrange><FontAwesomeIcon icon={faEarthAmericas}/></TextOrange>  {job.candidate_required_location}</> : null}    {job.job_type !== '' ? <><TextOrange><FontAwesomeIcon icon={faClock}/></TextOrange> {job.job_type.replace('_',' ')}</> : null}</p>
              <p><TextOrange><FontAwesomeIcon icon={faCircleCheck}/> {job.category}</TextOrange></p>
              <p><TextOrange><FontAwesomeIcon icon={faCalendarDays}/></TextOrange> Published: {job.publication_date.slice(0, 10)}</p>
            </Column>
            <Column itemWidth='20%'>
              <CardList.Logo> <img src={job.company_logo}/></CardList.Logo>
              <p><Link key={job.id} to={`/jobs-list/${job.id}`} onClick={onOtherJobClick}><ButtonOrange>VIEW JOB</ButtonOrange></Link></p>
            </Column>
          {/*  <Column itemWidth='80%'>*/}
          {/*    <h2>{job.title} {job.salary !== '' ? <><TextOrange><FontAwesomeIcon icon={faFileInvoiceDollar}/></TextOrange> {job.salary}</> : null}</h2>*/}
          {/*    <h4><TextOrange><FontAwesomeIcon icon={faBuilding}/></TextOrange> {job.company_name}</h4>*/}
          {/*    {job.candidate_required_location !== '' ? <p> <TextOrange><FontAwesomeIcon icon={faEarthAmericas}/></TextOrange>  {job.candidate_required_location}</p> : null}*/}
          {/*    <p><TextOrange><FontAwesomeIcon icon={faCircleCheck}/> {job.category}</TextOrange></p>*/}
          {/*  </Column>*/}
          {/*  <Column itemWidth='20%'>*/}
          {/*    <CardList.Logo> <img src={job.company_logo}/></CardList.Logo>*/}
          {/*  </Column>*/}
          {/*</ContainerRowTop>*/}
          {/*<ContainerRowTop>*/}
          {/*  <Column itemWidth='50%'>*/}
          {/*    {job.job_type !== '' ? <p><TextOrange><FontAwesomeIcon icon={faClock}/></TextOrange> {job.job_type.replace('_',' ')}</p> : null}*/}
          {/*    <p><TextOrange><FontAwesomeIcon icon={faCalendarDays}/></TextOrange> Published: {job.publication_date.slice(0, 10)}</p>*/}
          {/*  </Column>*/}
          {/*  <Column itemWidth='20%'>*/}
          {/*    <p><Link key={job.id} to={`${job.id}`} onClick={onOtherJobClick}><ButtonOrange>VIEW JOB</ButtonOrange></Link></p>*/}
          {/*  </Column>*/}
          </ContainerRowTop>
        </CardList>
        </LinkStyle>)}

        <LoadMoreBox>{next < jobsData.jobs.length && (
          <ButtonLoadMore onClick={handleMoreItems}>Load more...</ButtonLoadMore>
        )}
        </LoadMoreBox>


      </Container>
      </>
  )
}
export default AllCategories