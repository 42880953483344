import styled from 'styled-components'
import {Link} from "react-router-dom";


export const FooterStyle = styled.div`
position: relative;
width: 100%;
background-color: var(--darkblue);
color: white;
bottom: 0;
height: auto;
margin-top: 50px;
margin-bottom: 0px;

hr{
border-top: 1px solid;
border-color: var(--orange);
margin-bottom: 20px;
margin-top: 30px;
}

p{
text-align: center;
color: var(--orange);
}
`;
export const FooterStyleContent = styled.div`
margin-right: 20px;
margin-left: 20px;
padding: 20px;
`
export const FooterCategories = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: flex-start;
width: 100%;
`

export const FooterCategoriesChild = styled.div`
width: 25%;
text-align: left;
padding-top: 10px;
@media (max-width: 800px) {
width: 50%;;
    }  
`

export const LinkStyleCategory = styled(Link)`
color: white;
text-decoration: none;
&:hover{
color:var(--orange)
}
`