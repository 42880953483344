import React from 'react';
import Container from "../styles/Container";
import TextOrange from "../styles/TextOrange";
import img1 from "./images/foto6.jpg";
import img2 from "./images/foto11.jpg";
import img3 from "./images/foto5.jpg";
import {BlogImageLeft, BlogImageRight} from "./BlogImage";
import Breadcrumbs from "../styles/Breadcrumbs";
import Helmet from "react-helmet";


const InterviewQuestions = () => {
  return (
    <>
      <Helmet>
        <title>Top Work | Interview Questions</title>
        <meta name="description" content="Interview Questions, Basic Interview Questions, Behavioral Interview Questions, Salary Questions, Career Development Questions " />
        <meta name='keywords' content="Interview Questions, Interview, hired, find job"/>
      </Helmet>
      <Container>
        <Breadcrumbs>
          <Breadcrumbs.Link to='/'>Home</Breadcrumbs.Link> >
          <Breadcrumbs.Link to='/blog'>Blog</Breadcrumbs.Link> >
          <Breadcrumbs.Link to=''>Interview Questions</Breadcrumbs.Link>
        </Breadcrumbs>
        <h1>Interview Questions</h1>
        <BlogImageRight src={img1}/>
        <TextOrange><h2>Basic Interview Questions:</h2></TextOrange>
        <ul>
          <li>Tell me about yourself.</li>
          <li>What are your strengths?</li>
          <li>What are your weaknesses?</li>
          <li>Why do you want this job?</li>
          <li>Where would you like to be in your career five years from now?</li>
          <li>What's your ideal company?</li>
          <li>What attracted you to this company?</li>
          <li>Why should we hire you?</li>
          <li>What did you like least about your last job?</li>
          <li>When were you most satisfied in your job?</li>
          <li>What can you do for us that other candidates can't?</li>
          <li>What were the responsibilities of your last position?</li>
          <li>Why are you leaving your present job?</li>
          <li>What do you know about this industry?</li>
          <li>What do you know about our company?</li>
          <li>Are you willing to relocate?</li>
          <li>Do you have any questions for me?</li>
        </ul>
        <TextOrange><h2>Behavioral Interview Questions:</h2></TextOrange>
        <ul>
          <li>What was the last project you led, and what was its outcome?</li>
          <li>Give me an example of a time that you felt you went above and beyond the call of duty at work.</li>
          <li>Can you describe a time when your work was criticized?</li>
          <li>Have you ever been on a team where someone was not pulling their own weight? How did you handle it?</li>
          <li>Tell me about a time when you had to give someone difficult feedback. How did you handle it?</li>
          <li>What is your greatest failure, and what did you learn from it?</li>
          <li>How do you handle working with people who annoy you?</li>
          <li>If I were your supervisor and asked you to do something that you disagreed with, what would you do?</li>
          <li>What was the most difficult period in your life, and how did you deal with it?</li>
          <li>Give me an example of a time you did something wrong. How did you handle it?</li>
          <li>Tell me about a time where you had to deal with conflict on the job.</li>
          <li>If you were at a business lunch and you ordered a rare steak and they brought it to you well done, what
            would you do?
          </li>
          <li>If you found out your company was doing something against the law, like fraud, what would you do?</li>
          <li>What assignment was too difficult for you, and how did you resolve the issue?</li>
          <li>What's the most difficult decision you've made in the last two years and how did you come to that
            decision?
          </li>
          <li>Describe how you would handle a situation if you were required to finish multiple tasks by the end of the
            day, and there was no conceivable way that you could finish them.
          </li>
        </ul>
        <TextOrange><h2>Salary Questions:</h2></TextOrange>
        <ul>
          <li>What salary are you seeking?</li>
          <li>What's your salary history?</li>
          <li>If I were to give you this salary you requested but let you write your job description for the next year,
            what would it say?
          </li>
        </ul>
        <TextOrange><h2>Career Development Questions:</h2></TextOrange>
        <ul>
          <li>What are you looking for in terms of career development?</li>
          <li>How do you want to improve yourself in the next year?</li>
          <li>What kind of goals would you have in mind if you got this job?</li>
          <li>If I were to ask your last supervisor to provide you additional training or exposure, what would she
            suggest?
          </li>
        </ul>
        <BlogImageLeft src={img2}/>
        <TextOrange><h2>Getting Started Questions:</h2></TextOrange>
        <ul>
          <li>How would you go about establishing your credibility quickly with the team?</li>
          <li>How long will it take for you to make a significant contribution?</li>
          <li>What do you see yourself doing within the first 30 days of this job?</li>
          <li>If selected for this position, can you describe your strategy for the first 90 days?</li>
        </ul>
        <TextOrange><h2>More Questions About You:</h2></TextOrange>
        <ul>
          <li>How would you describe your work style?</li>
          <li>What would be your ideal working environment?</li>
          <li>What do you look for in terms of culture—structured or entrepreneurial?</li>
          <li>Give examples of ideas you've had or implemented.</li>
          <li>What techniques and tools do you use to keep yourself organized?</li>
          <li>If you had to choose one, would you consider yourself a big-picture person or a detail-oriented person?
          </li>
          <li>Tell me about your proudest achievement.</li>
          <li>Who was your favorite manager and why?</li>
          <li>What do you think of your previous boss?</li>
          <li>Was there a person in your career who really made a difference?</li>
          <li>What kind of personality do you work best with and why?</li>
          <li>What are you most proud of?</li>
          <li>What do you like to do?</li>
          <li>What are your lifelong dreams?</li>
          <li>What do you ultimately want to become?</li>
          <li>What is your personal mission statement?</li>
          <li>What are three positive things your last boss would say about you?</li>
          <li>What negative thing would your last boss say about you?</li>
          <li>What three character traits would your friends use to describe you?</li>
          <li>What are three positive character traits you don't have?</li>
          <li>If you were interviewing someone for this position, what traits would you look for?</li>
          <li>List five words that describe your character.</li>
          <BlogImageRight src={img3}/>
          <li>Who has impacted you most in your career and how?</li>
          <li>What is your greatest fear?</li>
          <li>What is your biggest regret and why?</li>
          <li>What's the most important thing you learned in school?</li>
          <li>Why did you choose your major?</li>
          <li>What will you miss about your present/last job?</li>
          <li>What is your greatest achievement outside of work?</li>
          <li>What are the qualities of a good leader? A bad leader?</li>
          <li>Do you think a leader should be feared or liked?</li>
          <li>How do you feel about taking no for an answer?</li>
          <li>How would you feel about working for someone who knows less than you?</li>
          <li>How do you think I rate as an interviewer?</li>
          <li>Tell me one thing about yourself you wouldn't want me to know.</li>
          <li>Tell me the difference between good and exceptional.</li>
          <li>What kind of car do you drive?</li>
          <li>There's no right or wrong answer, but if you could be anywhere in the world right now, where would you
            be?
          </li>
          <li>What's the last book you read?</li>
          <li>What magazines do you subscribe to?</li>
          <li>What's the best movie you've seen in the last year?</li>
          <li>What would you do if you won the lottery?</li>
          <li>Who are your heroes?</li>
          <li>What do you like to do for fun?</li>
          <li>What do you do in your spare time?</li>
          <li>What is your favorite memory from childhood?</li>
        </ul>
        <TextOrange><h2>Brainteasers:</h2></TextOrange>
        <ul>
          <li>How many times do a clock's hands overlap in a day?</li>
          <li>How would you weigh a plane without a scale?</li>
          <li>Tell me 10 ways to use a pencil other than writing.</li>
          <li>Sell me this pencil.</li>
          <li>If you were an animal, which one would you want to be?</li>
          <li>Why is there fuzz on a tennis ball?</li>
          <li>If you could choose one superhero power, what would it be and why?</li>
          <li>If you could get rid of any one of the US states, which one would you get rid of and why?</li>
          <li>With your eyes closed, tell me step-by-step how to tie my shoes.</li>
        </ul>
      </Container>
    </>
  )
}
export default InterviewQuestions;