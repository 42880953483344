import styled from 'styled-components'
import {Link} from "react-router-dom";

const NavStyle = styled.div`
display: inline-block;
color: white;
 @media(max-width: 800px) {
display: block;
  }
`;
NavStyle.Item = styled(Link)`
color: white;
margin: 5px;
text-decoration: none;
 @media(max-width: 800px) {
color: white;
margin: 5px;
text-decoration: none;
  }

&:hover{
color:var(--darkblue);
}

  @media(max-width: 800px) {
display: none;
  }
`
export default NavStyle;
