import React from 'react'
import {ResumeContext} from "../ResumeContext";
import {ButtonColorPicker, InputColor} from "../form.styles/InputColor";
import ContainerForm from "../form.styles/ContainerForm";
import ContainerRow from "../form.styles/ContainerRow";



const Colors = (props) => {
  const context = React.useContext(ResumeContext)


  const onClickBackgroundBlue = () => {
    context.setBackgroundColor('#97e3f1')
  }
  const onClickBackgroundYellow = () => {
    context.setBackgroundColor('#e1c340')
  }
  const onClickBackgroundPink = () => {
    context.setBackgroundColor('#fad0b7')
  }
  const onClickBackgroundGreen = () => {
    context.setBackgroundColor('#c4e2ca')
  }


  const onClickTextBlue = () => {
    context.setTextColor('#97e3f1')
  }
  const onClickTextYellow = () => {
    context.setTextColor('#e1c340')
  }
  const onClickTextPink = () => {
    context.setTextColor('#fad0b7')
  }
  const onClickTextGreen = () => {
    context.setTextColor('#c4e2ca')
  }

  return(
    <>
      <h2>Choose background color</h2>
      <ContainerRow>
      <ButtonColorPicker value={context.backgroundColor} style={{backgroundColor: '#97e3f1'}} onClick={onClickBackgroundBlue}></ButtonColorPicker>
      <ButtonColorPicker value={context.backgroundColor} style={{backgroundColor: '#fad0b7'}} onClick={onClickBackgroundPink}></ButtonColorPicker>
      <ButtonColorPicker value={context.backgroundColor} style={{backgroundColor: '#e1c340'}} onClick={onClickBackgroundYellow}></ButtonColorPicker>
      <ButtonColorPicker value={context.backgroundColor} style={{backgroundColor: '#c4e2ca'}} onClick={onClickBackgroundGreen}></ButtonColorPicker>
        <InputColor type="color" value={context.backgroundColor} onChange={(e) => context.setBackgroundColor(e.target.value)}/>
      </ContainerRow>

      <h2>Choose text color</h2>
      <ContainerRow>
        <ButtonColorPicker value={context.textColor} style={{backgroundColor: '#97e3f1'}} onClick={onClickTextBlue}></ButtonColorPicker>
        <ButtonColorPicker value={context.textColor} style={{backgroundColor: '#fad0b7'}} onClick={onClickTextPink}></ButtonColorPicker>
        <ButtonColorPicker value={context.textColor} style={{backgroundColor: '#e1c340'}} onClick={onClickTextYellow}></ButtonColorPicker>
        <ButtonColorPicker value={context.textColor} style={{backgroundColor: '#c4e2ca'}} onClick={onClickTextGreen}></ButtonColorPicker>
        <InputColor type="color" value={context.textColor} onChange={(e) => context.setTextColor(e.target.value)}/>
      </ContainerRow>

      </>
  )
}
export default Colors;