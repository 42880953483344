import styled from 'styled-components'


const ContainerRow = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: space-between;
gap: 20px;
`;
export default ContainerRow;
