import styled from 'styled-components'



const ImageSlider = styled.img`
color: white;
text-align: center;
background: linear-gradient(to bottom, transparent 0%,  rgba(0,0,0,0.9) 100%);

`;
ImageSlider.Container = styled.div`
position: relative;
min-width: 100%;
align-items: center;
z-index: 100;
`
export default ImageSlider;