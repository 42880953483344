import styled from 'styled-components'

const CardList = styled.div`
padding: 20px;
box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
margin-bottom: ${({itemMargin}) => itemMargin || '0px'}; 
margin-top: ${({itemMarginTop}) => itemMarginTop || '30px'}; 
align-content: stretch;
h2{
margin: 0;
}
h4{
margin: 10px 0;
}
p{
margin: 0;
}
&:hover{
box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
`;
CardList.Logo = styled.div`
margin: auto;
img {
width: 70px;
box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
border-radius: 50%;
background-color: var(--grey);
padding: 3px;
}
`
CardList.TagsContainer = styled.ul`
 list-style-type: none;
  margin: 0;
  padding: 0;
 
li{
display: inline-block;
font-size: 12px;
background-color: var(--grey);
margin: 7px 7px 0 0;
padding: 7px;
}  
`
CardList.Company = styled.div`
text-align: center;
img{
width: 128px;
height: 128px;
}
`

export default CardList;
