import React from 'react';
import TextOrange from "../styles/TextOrange";
import Container from "../styles/Container";
import img1 from "./images/foto9.jpg";
import img2 from "./images/foto10.jpg";
import {BlogImageLeft, BlogImageRight} from "./BlogImage";
import Breadcrumbs from "../styles/Breadcrumbs";
import Helmet from "react-helmet";


const JobTemplate = () => {
  return (
    <>
      <Helmet>
        <title>Top Work | Job Template</title>
        <meta name="description" content="Hiring remotely can be challenging. Whenever you list a remote job, what you want is to get top applicants that
        could be a great fit for your team." />
        <meta name='keywords' content="Hired Now, Get Hired Now, Job Template, hired, find job, job, jobs"/>
      </Helmet>
      <Container>
        <Breadcrumbs>
          <Breadcrumbs.Link to='/'>Home</Breadcrumbs.Link> >
          <Breadcrumbs.Link to='/blog'>Blog</Breadcrumbs.Link> >
          <Breadcrumbs.Link to=''>Job Template</Breadcrumbs.Link>
        </Breadcrumbs>
      <h1>Job Template</h1>
        <BlogImageRight src={img1}/>
      <p>Hiring remotely can be challenging. Whenever you list a remote job, what you want is to get top applicants that
        could be a great fit for your team. The last thing you want to do is waste hours sorting through applicants that
        are only interested in "working from home." We've been through thousands of remote job descriptions and studied
        what worked. Then, we got feedback from expert recruiters and our community.

        We love documenting how companies hire! If you're looking for some inspiration, check out our How Top Remote
        Companies Hire blog series, where we study great remote companies.

        We're excited to share this proven job description template with you!</p>
      <TextOrange><h2>Remote Job Title</h2></TextOrange>
      <TextOrange><h2>Location requirement - Company Name - Functional Team</h2></TextOrange>
      <p>Include here a summary of the essential information a candidate needs to know:</p>
      <ul>
        <li>Where do I need to be based?</li>
        <li>What is the salary?</li>
        <li>How long will the interview process be?</li>
        <li>Who am I reporting to? (with links to a LinkedIn page or video presentation, for example)</li>
      </ul>
      <p>Having this information up top will help candidates decide if your remote job is a good fit for them or not.
        The market is very competitive right now, and candidates in tech are spoilt for choice of well-paid remote jobs.
        If they are interested by what they find here, they will read on. </p>
      <TextOrange><h2>Company description</h2></TextOrange>
      <p>Explain why you're awesome (3 paragraphs at the most), including the COMPANY's mission.</p>
      <p>Job descriptions can be a candidate's first contact point with your company. It is essential to provide
        context: </p>
      <ul>
        <li>Why are you as a company?</li>
        <li>What do you believe in?</li>
        <li>What are your highlights?</li>
        <li>What are you learning?</li>
      </ul>
      <TextOrange><h2>As a [X] team member, your goal is to…</h2></TextOrange>
      <p>The TEAM’s mission and the CANDIDATE’s mission go here.</p>
      <p>Share what the candidate will work on, and who will they be working with.</p>
      <TextOrange><h2>Requirements</h2></TextOrange>
      <p>Make a distinction between what is REQUIRED & what is OPTIONAL</p>
      <ul>
        <li>Always include language, time zone and country requirement, if applicable. If you have shared that up top, repeat it here.</li>
          <li>Be upfront about any travels, if applicable (retreats=business trip).</li>
      </ul>
        <BlogImageLeft src={img2}/>
      <TextOrange><h2>Our Remote Work Philosophy</h2></TextOrange>
      <ul>
        <li>Clarify any restrictions ( "US citizens only" ≠ "US time zones only" ≠ "US locations only")</li>
          <li>Collaboration: Is overlap required? How many hours? Any asynchronous work?</li>
      </ul>
      <TextOrange><h2>Benefits</h2></TextOrange>
      <p>Insurance, Vacation, Remote office stipend, Family perks/policies go here...</p>
      <TextOrange><h2>About Us</h2></TextOrange>
      <p>Get the applicant excited about what you’re solving for your customers!</p>
      <TextOrange><h2>Our Values</h2></TextOrange>
      <p>Sharing companies values help qualify candidates in or out.</p>
      <TextOrange><h2>Diversity & Inclusion</h2></TextOrange>
      <p>Your company worked on Diversity & Inclusion over the past year? If yes, do share it!</p>

      <h1>Optional sections (but we highly encourage you to include them)</h1>
  <TextOrange><h2>Who you'll work with (and why)</h2></TextOrange>
      <ul>
        <li>Joe, Director of Engineering loves to...</li>
          <li>Joyce, Design Lead is passionate about...</li>
            <li>Janet, VP of Engineering leads the team that..</li>
      </ul>
      <p>Bonus points: Include a link to a short video where the team leader/manager the successful candidate will report to briefly introduces the team and the role. </p>
      <TextOrange><h2>Tools we use/love...</h2></TextOrange>
      <TextOrange><h2>Salary Range & Employment status</h2></TextOrange>
      <ul>
        <li>Sharing a range will help attract top active & passive candidates. (eg. $80,000-$120,000). It is also mandatory in some US states and other countries. It's also needed for Google to list your job.</li>
          <li>Are you hiring full-time employees or contractors? Is salary calculated locally? Are benefits affected?</li>
      </ul>
      <TextOrange><h2>How to Apply</h2></TextOrange>
      <ul>
        <li>How soon can a candidate expect to hear back? Do you reply to all?</li>
          <li>Summary of the next steps for successful candidates</li>
      </ul>
      <TextOrange><h2>Referral</h2></TextOrange>
      <p>Why not offer $X,000 if someone refers you to the person you hire? Well worth it.</p>
      <TextOrange><h2>S-M-A-R-T Expectations</h2></TextOrange>
      <p>In the first [week/month/quarter], you will achieve...</p>
      <TextOrange><h2>How we help you grow</h2></TextOrange>
      <p>How will the candidate grow at your company? Is management the only way up? Can individual contributors strive?</p>
      </Container>
      </>
  )
}
export default JobTemplate;