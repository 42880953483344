import React from 'react';
import {Bar, Hamburger, Logo, MainNav, NavBarToggle, NavContainer, NavLi, NavLink} from "./NavBarStyle";
import NavStyle from "./NavStyle";
import logo from '../images/logo2.png'
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";


const HeaderMain = (props) => {
  const [displayNav, setDisplayNav] = React.useState(props.displayNav ? 'flex' : 'none')

  const toggleNavBar = () => {
    // setDisplayNav(displayNav ? 'flex' : 'none')
    if (displayNav === 'flex'){
      setDisplayNav('none')
    }
    if(displayNav === 'none'){
      setDisplayNav('flex')
    }
  }

  const onClickClose = () => {
    setDisplayNav('none')
  }
  return(
    <>
      <NavContainer>
      <Bar>
        <NavBarToggle onClick={() => toggleNavBar()}>
          <Hamburger>
            <FontAwesomeIcon icon={faBars} size="lg"/>
          </Hamburger>
        </NavBarToggle>
        <Link to={''}><Logo><img src={logo}/></Logo></Link>
        <MainNav display={displayNav}>
          <NavLi>
            <NavLink to='/categories' onClick={onClickClose}>Jobs</NavLink>
          </NavLi>
          <NavLi>
            <NavLink to='/companies' onClick={onClickClose}>Companies</NavLink>
          </NavLi>
          <NavLi>
            <NavLink to='/resume' onClick={onClickClose}>Resume</NavLink>
          </NavLi>
          <NavLi>
            <NavLink to='/blog' onClick={onClickClose}>Blog</NavLink>
          </NavLi>
        </MainNav>
      </Bar>
      </NavContainer>
      </>
  )
}
export default HeaderMain;