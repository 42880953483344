import styled, {keyframes} from 'styled-components'


const ProgressBarWrapper = styled.div`
  background-color: var(--grey);
  height: 5px;
  width: 100%;
`

ProgressBarWrapper.ProgressBar = styled.div`
  background-color: var(--orange);
  height: 100%;
  width:  ${({itemWidth}) => `${itemWidth || '100'}%`}; 
`
export default ProgressBarWrapper;