import React from 'react';
import jobsData from "../data/JobsData";
import {Link} from "react-router-dom";
import {observer} from "mobx-react-lite";
import Container from "../styles/Container";
import CardList from "../styles/CardList";
import ContainerColumn from "../styles/ContainerColumn";
import ContainerRow from "../styles/ContainerRow";
import Column from "../styles/Column";
import ContainerRowTop from "../styles/ContainerRowTop";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBuilding, faCircleCheck, faClock, faLocation, faLocationDot} from "@fortawesome/free-solid-svg-icons";
import Categories from "./Categories";


const JobsList = () => {
  React.useEffect(() => {
    jobsData.update()
  }, [])

  return (
    <>
      <Container>
        <h1>Jobs</h1>
        <Categories/>
        <ContainerColumn>
          {/*<input type="range" min="0" max="11"/>*/}
          {jobsData.jobs.map(job => (
            <CardList>
              <ContainerRowTop>
                <div>
                  <h2>{job.title}</h2>
                  <h4><b><FontAwesomeIcon icon={faBuilding}/> {job.company_name}</b>
                    <FontAwesomeIcon icon={faLocationDot}/>  {job.candidate_required_location} </h4>
                  <p><FontAwesomeIcon icon={faCircleCheck}/> {job.category}</p>
                </div>
                <div>
                  <CardList.Logo> <img src={job.company_logo}/></CardList.Logo>
                </div>
              </ContainerRowTop>
              <ContainerRowTop>
                <Column itemWidth='50%'>
                  <p><FontAwesomeIcon icon={faClock}/> {job.publication_date.slice(0, 10)}</p>
                </Column>
                <Column itemWidth='50%'>
                  <p><Link key={job.id} to={`/jobs-list/${job.id}`}>View job</Link></p>
                </Column>
              </ContainerRowTop>
              <Column>
                <h1>{job.salary}</h1>


                <span>

            </span>
                <p>{job.salary}</p>
              </Column>
              <CardList.TagsContainer>
                {job.tags.map((tag) => <li>{tag}</li>)}
              </CardList.TagsContainer>
            </CardList>
          ))}
        </ContainerColumn>
      </Container>
    </>
  )
}
export default observer(JobsList);