import React from 'react';
import styled from 'styled-components'
import HeaderStyle, {HeaderStyleContent} from "./HeaderStyle";
import NavStyle from "./NavStyle";
import {Link} from "react-router-dom";
import Container from "../styles/Container";
import {DropDownHeader, NavDpopdown , DropDownList, DropDownListContainer, ListItem} from "./NavDropdown";
import logo from '../images/logo2.png'
import {
  MobileMenu,
  DropdownMenuHeader,
  DropDownMenuListContainer,
  DropDownMenuList,
  MenuListItem,
  MenuListlink,
} from "./MobileMenu";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";

const Logo = styled.img`
padding: 10px;
height: 40px;
width: auto;
`

const Header = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpenMenu, setIsOpenMenu] = React.useState(false);
  // const [selectedOption, setSelectedOption] = React.useState(null);
  const toggling = () => {
    setIsOpen(!isOpen);
  };
  const ref = React.useRef()
  React.useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isOpen && ref.current && !ref.current.contains(e.target)) {
        setIsOpen(false)
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [isOpen])

  const onClickToTop = () => {
    window.scrollTo(0,0);
  }


  const togglingMenu = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  return(
    <>
      <HeaderStyle>
        <HeaderStyleContent>
        <Link to={''}><Logo src={logo}/></Link>
        <NavStyle>
          <NavStyle.Item to='/jobs-list'>Jobs</NavStyle.Item>
          <NavStyle.Item to='/categories'>categories</NavStyle.Item>
          <NavStyle.Item to='/companies'>Companies</NavStyle.Item>
          <NavStyle.Item to='/resume'>RESUME</NavStyle.Item>
          <div className="hover-text">
          <NavDpopdown ref={ref}>
            <DropDownHeader onClick={toggling}>
              Blog
            </DropDownHeader>
            {isOpen && (
              <DropDownListContainer>
                <DropDownList>
                  <ListItem><NavStyle.Item to='blog/interview-skills' onClick={onClickToTop}>7 interview skills that will get you hired in 2022</NavStyle.Item></ListItem>
                  <ListItem><NavStyle.Item to='blog/resume-tips' onClick={onClickToTop}>The Best Resume Tips</NavStyle.Item></ListItem>
                  <ListItem><NavStyle.Item to='blog/job-template' onClick={onClickToTop}>Job Template</NavStyle.Item></ListItem>
                  <ListItem><NavStyle.Item to='blog/interview-questions' onClick={onClickToTop}>Interview Questions</NavStyle.Item></ListItem>
                </DropDownList>
              </DropDownListContainer>
            )}
          </NavDpopdown>
          </div>
        </NavStyle>
          <MobileMenu>
              <DropdownMenuHeader onClick={togglingMenu}>
                <FontAwesomeIcon icon={faBars} size="2x"/>
              </DropdownMenuHeader>
              {isOpenMenu && (
                <DropDownMenuListContainer>
                  <DropDownMenuList>
                    <MenuListItem><MenuListlink to='/jobs-list' onClick={onClickToTop}>Jobs</MenuListlink></MenuListItem>
                    <MenuListItem><MenuListlink to='/categories' onClick={onClickToTop}>Categories</MenuListlink></MenuListItem>
                    <MenuListItem><MenuListlink to='/companies' onClick={onClickToTop}>Companies</MenuListlink></MenuListItem>
                    <MenuListItem><MenuListlink to='/resume' onClick={onClickToTop}>Resume</MenuListlink></MenuListItem>
                  </DropDownMenuList>
                </DropDownMenuListContainer>
              )}

          </MobileMenu>
        </HeaderStyleContent>
      </HeaderStyle>

      </>
  )
}
export default Header;