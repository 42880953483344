import styled from 'styled-components'


const ContainerRowResume = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: start;
gap: 1%;
`;
export default ContainerRowResume;