import styled from 'styled-components'


export const NavDpopdown = styled("span")`
position: relative;
  //width: 10.5em;
  //margin: 0 auto;
`;

export const DropDownHeader = styled("button")`
font-size: 16px;
font-weight: bold;
background: none;
outline: none;
border: none;
display: block;
color: white;
margin: 5px;
text-decoration: none;
transition: color 0.1s ease 0s;
&:hover{
color:var(--darkblue);
}
`;

export const DropDownListContainer = styled("div")`
z-index: 1;
position: absolute;
width: 300px;
margin-left: -260px;
background-color: var(--orange);
color: black;
`;

export const DropDownList = styled("ul")`
background-color: var(--orange);
width: 100%;
  padding: 0;
  margin: 0;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  color: black;
 
  &:first-child {
    padding: 10px 12px;
  }
`;

export const ListItem = styled("li")`
padding: 5px 12px;
text-align: left;
list-style: none;
 &:hover{
color: var(--orange);
  }
`;
