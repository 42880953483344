import React from 'react'
import TextEditor from "../editor/TextEditor";
import ContainerForm from "../form.styles/ContainerForm";
import {ResumeContext} from "../ResumeContext";
import InputText from "../form.styles/InputText";


const ProfessionalSummary = (props) => {
  const context = React.useContext(ResumeContext)


  // const [html,setHtml] = React.useState('');
  //
  // const handleChange = React.useCallback(
  //   function handleChange(event) {
  //     setHtml(event.target.value)
  //   },
  //   [setHtml],
  // )

  return(
    <>
      {/*<TextEditor html={html} handleChange={handleChange}/>*/}

      <label>Write 2-4 short & energetic sentences to interest the reader! Mention your role, experience & most
        importantly - your biggest achievements, best qualities and skills.</label>
      <TextEditor
        html={context.professionalSummary}
        onChange={(e) => context.setProfessionalSummary(e.target.value)}/>
      </>
  )
}
export default ProfessionalSummary;