import styled from 'styled-components'


const Wrapper = styled.div`
width: 100%;
display: flex;
flex-flow: row wrap;
margin-bottom: 18px;
gap: 40px;

`;
export default Wrapper;
