import styled from 'styled-components'

export const InputColor = styled.input`
width: 30px;
height: 30px;
//border-color: lightskyblue;
color: lightskyblue;
background-color: #fff;
//border: none;
cursor: pointer;
border: none;
outline: 1px solid var(--orange);
        

input[type="color"]::-webkit-color-swatch-wrapper {
padding: 0;
border-radius: 50%;
 }

input[type="color"]::-webkit-color-swatch {
border: none;
border-radius: 50%;
 }

`;
export const ButtonColorPicker = styled.div`
width: 20px;
height: 20px;
margin: 5px;
border-color: lightskyblue;
color: lightskyblue;
background-color: lightskyblue;
border: none;
cursor: pointer;
`;

