import React from 'react'
import Wrapper from "../form.styles/Wrapper";
import HalfWrapper from "../form.styles/HalfWrapper";
import LabelText from "../form.styles/LabelText";
import InputText from "../form.styles/InputText";
import TextEditor from "../editor/TextEditor";
import {ResumeContext} from "../ResumeContext";


const Education = (props) => {
  const [educationSchool, setEducationSchool] = React.useState('');
  const [educationDegree, setEducationDegree] = React.useState('');
  const [educationStartDate, setEducationStartDate] = React.useState('');
  const [educationEndDate, setEducationEndDate] = React.useState('');
  const [educationCity, setEducationCity] = React.useState('');
  const [educationDescription, setEducationDescription] = React.useState('');

  const getObj = () => ({
    educationSchool,
    educationDegree,
    educationStartDate,
    educationEndDate,
    educationCity,
    educationDescription,
  });
  React.useEffect(() => {
    props.onChange(getObj());
  },[educationSchool,
    educationDegree,
    educationStartDate,
    educationEndDate,
    educationCity,
    educationDescription,])
  return(
    <>

      <Wrapper>
        <HalfWrapper>
          <LabelText>School/University</LabelText>
          <InputText
            type='text'
            value={educationSchool}
            onChange={(e) => setEducationSchool(e.target.value)}/>
        </HalfWrapper>
        <HalfWrapper>
          <LabelText>Degree</LabelText>
          <InputText
            type='text'
            value={educationDegree}
            onChange={(e) => setEducationDegree(e.target.value)}/>
        </HalfWrapper>


      </Wrapper>
      <Wrapper>
        <HalfWrapper>
          <LabelText>Start & End Date</LabelText>
          <HalfWrapper>
            <InputText
              type='month'
              value={educationStartDate}
              onChange={(e) => setEducationStartDate(e.target.value)}/>
          </HalfWrapper>
          <HalfWrapper>
            <InputText
              type='month'
              value={educationEndDate}
              onChange={(e) => setEducationEndDate(e.target.value)}/>
          </HalfWrapper>
        </HalfWrapper>
        <HalfWrapper>
          <LabelText>City</LabelText>
          <InputText
            type='text'
            value={educationCity}
            onChange={(e) => setEducationCity(e.target.value)}/>
        </HalfWrapper>
      </Wrapper>

        <LabelText>Description</LabelText>
      <div>
        <TextEditor
          html={educationDescription}
          onChange={(e) => setEducationDescription(e.target.value)}/>
      </div>
      </>
  )
}
export default Education;