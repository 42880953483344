import React from 'react';
import Container from "../styles/Container";
import TextOrange from "../styles/TextOrange";
import img1 from "./images/foto13.jpg";
import img2 from "./images/foto11.jpg";
import img3 from "./images/foto5.jpg";
import {BlogImageLeft, BlogImageRight} from "./BlogImage";
import Breadcrumbs from "../styles/Breadcrumbs";
import Helmet from "react-helmet";


const SkillsForResume = () => {
  return (
    <>
      <Helmet>
        <title>Top Work | Top 10 skills for resumes</title>
        <meta name="description" content="Some important types of skills to cover on a resume include: Active listening, Communication, Computer skills, Customer service skills,
        Interpersonal skills, Leadership skills, Management skills, Problem-solving skills, Time management, Transferable skills" />
        <meta name='keywords' content="skills for resumes, top skills, put on a resume, Active listening, Communication, Computer skills, Customer service skills,
        Interpersonal skills, Leadership skills, Management skills, Problem-solving skills, Time management, Transferable skills"/>
      </Helmet>
      <Container>
        <Breadcrumbs>
          <Breadcrumbs.Link to='/'>Home</Breadcrumbs.Link> >
          <Breadcrumbs.Link to='/blog'>Blog</Breadcrumbs.Link> >
          <Breadcrumbs.Link to=''>Top 10 skills for resumes</Breadcrumbs.Link>
        </Breadcrumbs>
        <h1>Top 10 skills for resumes</h1>
        <BlogImageRight src={img1}/>
        <p>Some important types of skills to cover on a resume include:</p>
        <p><TextOrange><h4>Active listening</h4></TextOrange>

          is the ability to focus completely on a speaker, understand their message, comprehend the information and
          respond thoughtfully.
        </p>
        <p><TextOrange><h4>Communication</h4></TextOrange>

          is the act of sharing information from one person to another person or group of people. It includes verbal,
          written, non-verbal and visual forms of communication.
        </p>
        <p><TextOrange><h4>Computer skills</h4></TextOrange>

          allow you to physically operate a computer and efficiently use computer programs and applications.</p>

        <p><TextOrange><h4>Customer service skills</h4></TextOrange>

          equip you to address customer needs and foster a positive experience.</p>

        <p><TextOrange><h4>Interpersonal skills</h4></TextOrange>

          assist in the face-to-face exchange of thoughts, ideas, feelings and emotions between two or more people.</p>

        <p><TextOrange><h4>Leadership skills</h4></TextOrange>

          are skills you use when organizing other people to reach a shared goal.</p>

        <p><TextOrange><h4>Management skills</h4></TextOrange>

          help you manage projects, lead teams and build and execute plans that will affect company outcomes.</p>

        <p><TextOrange><h4>Problem-solving skills</h4></TextOrange>

          help you determine the source of a problem and find an effective solution.</p>

        <p><TextOrange><h4>Time management</h4></TextOrange>

          helps you to complete work on time, stay engaged during important meetings and give you space to be creative
          and proactive in your tasks.
        </p>
        <p><TextOrange><h4>Transferable skills</h4></TextOrange>
          are qualities that can be transferred from one job to another.</p>

        <TextOrange><h2>How to identify your best skills</h2></TextOrange>
        <p>If you’re not sure which skills you want to share, consider your previous experiences. Where did you excel?
          Where would your peers say you’re especially practiced? Here are a few ways to determine good skills to put on
          a resume:</p>


        <TextOrange><h4>Consider your awards and achievements</h4></TextOrange>
        <p>Did you ever receive recognition for meeting a particular objective or excelling in a specific area? If so,
          your skills likely assisted you in reaching this achievement. Consider what personal talents or attributes
          helped you meet that milestone.</p>


        <TextOrange><h4>Ask former coworkers or fellow students</h4></TextOrange>
        <p>Sometimes others can help note strengths you may not recognize yourself. Reach out to a former manager or
          colleagues who worked closely with you. If you’re new to the professional world, reach out to students you
          worked with, teachers who know you well or someone you consider a mentor.</p>


        <TextOrange><h4>Talk to professionals in the field</h4></TextOrange>
        <p>If you’re having a difficult time determining what skills an employer may want to see, consider contacting a
          professional already working in the industry or position similar to the one you’re applying for. Find out what
          skills they consider most important, and identify which align with your own.

          When creating a list of skills for your resume, only include those you know to be your strengths
          . If there’s something you’re still learning, don’t feel pressured to include it because it appears in the job
          posting. If the employer mentions a skill you didn’t include during the interview process, you can discuss how
          you’re working to learn or improve for the role.</p>

        <TextOrange><h2>Example skills to put on a resume</h2></TextOrange>
        <p>While you can often easily determine hard skills to list based on details in the job description, selecting
          relevant soft skills is not always as clear. To help narrow down which soft skills to put on a resume, review
          the various duties of the position and determine which of your personal strengths will help you successfully
          complete those tasks.</p>
        <p>Here are 10 examples of popular soft and hard skills employers may be seeking:</p>


        <TextOrange><h4>1. Active listening skills</h4></TextOrange>
        <p>Active listening is the ability to focus completely on a speaker, understand their message, comprehend the
          information and respond thoughtfully. Active listeners use verbal and nonverbal techniques to show and keep
          their attention on the speaker. Developing and using active listening skills can show your colleagues that you
          are engaged and have an interest in the project or task at hand.
        </p>
        <p>Related listening skills include:</p>

        <ul>
          <li>Asking questions</li>

          <li>Note-taking</li>

          <li>Organization</li>

          <li>Punctuality</li>

          <li>Verbal/nonverbal communication</li>
        </ul>

        <TextOrange><h4>Communication skills</h4></TextOrange>
        <p>Communication skills are the abilities you use when giving and receiving different kinds of information. Some
          examples include communicating ideas, feelings or what’s happening around you. Communication skills involve
          listening, speaking, observing and empathizing. Having strong communication skills is important in every
          industry at every career level.
        </p>
        <p>Related communications skills include:</p>

        <ul>
          <li>Active listening</li>

          <li>Constructive criticism</li>

          <li>Interpersonal communication</li>

          <li>Public speaking</li>

          <li>Verbal/nonverbal communication</li>

          <li>Written communication</li>
        </ul>

        <TextOrange><h4>3. Computer skills</h4></TextOrange>
        <p>Computer skills involve the ability to learn and operate various technology. Hardware skills allow you to
          physically operate a computer and can be as simple as knowing how to turn devices on and off. Software skills
          help you to efficiently use computer programs and applications. There are some software skills that employers
          may consider as prerequisites to employment, like using spreadsheets or knowing a certain coding language.
        </p>
        <p>Related computer skills include:</p>

        <ul>
          <li>Typing/word processing</li>

          <li>Fluency in coding languages</li>

          <li>Systems administration</li>

          <li>Spreadsheets</li>

          <li>Email management</li>
        </ul>

        <TextOrange><h4>4. Customer service skills</h4></TextOrange>
        <p>Customer service skills are traits and practices that help you address customer needs to create a positive
          experience. In general, customer service skills rely heavily on problem-solving and communication. Customer
          service is often considered a “soft skill,” including traits like active listening and reading both verbal and
          nonverbal cues.
        </p>
        <p>Related customer service skills:</p>

        <ul>
          <li>Active listening</li>

          <li>Empathy</li>

          <li>Interpersonal skills</li>

          <li>Problem-solving</li>

          <li>Reliability</li>
        </ul>

        <TextOrange><h4>5. Interpersonal skills</h4></TextOrange>
        <p>Interpersonal skills are traits you rely on when you interact and communicate with others. They cover a
          variety of scenarios where cooperation is essential. Developing interpersonal skills is important to work
          efficiently with others, solve problems and lead projects or teams.
        </p>
        <p>Related interpersonal skills include:</p>

        <ul>
          <li>Communication</li>

          <li>Empathy</li>

          <li>Flexibility</li>

          <li>Leadership</li>

          <li>Patience</li>
        </ul>

        <TextOrange><h4>6. Leadership skills</h4></TextOrange>
        <p>Leadership skills are skills you use when organizing other people to reach a shared goal. Whether you’re in a
          management position or leading a project, leadership skills require you to motivate others to complete a
          series of tasks, often according to a schedule.
        </p>
        <p>Related leadership skills:</p>

        <ul>
          <li>Ability to teach and mentor</li>

          <li>Flexibility</li>

          <li>Risk-taking</li>

          <li>Team building</li>

          <li>Time management</li>
        </ul>

        <TextOrange><h4>7. Management skills</h4></TextOrange>
        <p>Managerial skills are qualities that help you govern both tasks and people. A good manager is organized,
          empathetic and communicates clearly to support a team or project. Managers should also be adept in both soft
          skills and certain technical skills related to their industry.
        </p>
        <p>Related management skills:</p>

        <ul>
          <li>Decision-making</li>

          <li>Project planning</li>

          <li>Task delegation</li>

          <li>Team communication</li>

          <li>Team leadership</li>
        </ul>

        <TextOrange><h4>8. Problem-solving skills</h4></TextOrange>
        <p>Problem-solving skills are qualities that help you determine the source of a problem and quickly find an
          effective solution. This skill is highly valued in any role for every industry. Solving problems in your role
          might require certain industry or job-specific technical skills.
        </p>
        <p>Related problem-solving skills:</p>

        <ul>
          <li>Attention to detail</li>

          <li>Collaboration</li>

          <li>Communication</li>

          <li>Patience</li>

          <li>Research</li>
        </ul>

        <TextOrange><h4>9. Time management skills</h4></TextOrange>
        <p>Time management skills allow you to complete tasks and projects before deadlines while also maintaining
          work-life balance. Staying organized can help you allocate your workday to specific tasks by importance.
          Deeply understanding your individual, team and company goals can provide a starting point when deciding how to
          manage your time.
        </p>
        <p>Related time management skills:</p>

        <ul>
          <li> Delegating tasks</li>

          <li>Focus</li>

          <li>Goal setting</li>

          <li>Organization</li>

          <li>Prioritization</li>
        </ul>

        <TextOrange><h4>10. Transferable skills</h4></TextOrange>
        <p>Transferable skills are qualities that are useful to any employer as you change jobs or careers. Transferable
          skills often include soft skills like flexibility, organization, teamwork or other qualities employers seek in
          strong candidates. Transferable skills

          can be used to position your past experience when applying for a new job—especially if it’s in a different
          industry.
        </p>
        <p>Related transferable skills:</p>

        <ul>
          <li>Ambition</li>

          <li>Creativity</li>

          <li>Empathy</li>

          <li>Leadership</li>

          <li>Teamwork</li>
        </ul>


      </Container>
    </>
  )
}
export default SkillsForResume;