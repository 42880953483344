import styled from 'styled-components'


export const SearchBar = styled.div`
display: flex;
flex-direction: row;
align-items: center;
gap: 5px;
`
export const SearchIcon = styled.div`
border-radius: 3px;
padding: 10px 12px;
display: block;
caret-color: var(--orange);
background-color: rgb(239, 242, 249);
border: 0px none;
transition: color 0.1s ease 0s;
font-size: 14px;
`

export const InputSearch = styled.input`
width: 100%;
border-radius: 3px;
padding: 10px 12px;
display: block;
caret-color: var(--orange);
background-color: rgb(239, 242, 249);
outline: currentcolor none medium;
border: 0px none;
transition: color 0.1s ease 0s;
font-size: 14px;
&:focus{
outline: 2px solid var(--orange);
}

`;
