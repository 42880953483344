import styled from 'styled-components'
import Content from 'react-contenteditable'

const ContentStyle = styled(Content)`
  background-color: rgb(239, 242, 249);
  min-height: 300px;
  width: 100%;
  border: none;
  font-size: 16px;
  padding: 10px;
  margin-bottom: 10px;
  outline: currentcolor none medium;
  &:focus{
border-bottom: 2px solid var(--orange);
}
`
export default ContentStyle;