import styled from 'styled-components'


export const Container = styled.div`
//padding: 10px;
font-family: 'Arial';
//line-height: 1.6;
width: 210mm;
height: ${({itemHeight}) => `${itemHeight || '1123'}px`};

p {
font-size: 12pt;
line-height: 14pt;
}

img{
width: 200px;
height: 200px;
object-fit: cover;

}
h2{
font-size: 1.5em;
margin-top: 0;
margin-bottom: 5px;
}
h3{
font-size: 16px;
margin-top: 0px;
margin-bottom: -10px;
font-weight: 900;
//text-transform: uppercase;
}
h4{
font-size: 16px;
margin-top: 0;
margin-bottom: 10px;
}
strong{
color: #2a78ab;
}
a{
color: white;
}
`

export const ContainerRow = styled.div`
display: flex;
flex-direction: row;
`
export const ContainerColumn = styled.div`
display: flex;
flex-direction: column;
padding-right: 20px;

`
export const ColorContainer = styled.div`
// background-color: ${({itemBackground}) => itemBackground || 'burlywood'};
background-color: ${({itemBackground}) => itemBackground || '#2a78ab'};
width: 200px;
margin-bottom: 0;
color: white;
height: 100%;
position: absolute;

`
export const ColorText = styled.div`
// background-color: ${({itemBackground}) => itemBackground || 'burlywood'};
color: ${({itemColor}) => itemColor || '#2a78ab'};
width: 100%;
font-weight: bold;
`
export const PaddingContainer = styled.div`
padding: 20px;
`
export const TextCenter = styled.div`
text-align: center;
`
export const ContainerMain = styled.div`
margin-left: 200px;
`

export const ProgressBarWrapper = styled.div`
  background: none;
  padding-top: 3px;
  height: 3px;
  width: 100%;
`

ProgressBarWrapper.ProgressBar = styled.div`
  background-color: black;
  height: 100%;
  width:  ${({itemWidth}) => `${itemWidth || '100'}%`}; 
`
export const SkillsStyle = styled.ul`
padding-left: 15px;
li{

}
`;


