import styled from 'styled-components';
import img1 from './images/2.png';
import header from './images/header.png';
import footer from './images/footer.png';


export const Container = styled.div`
// background-image: url(${img1});
// background-size: contain;
font-family: 'Arial';
font-size: 10pt;
//line-height: 1.6;

page-break-inside: auto;
// background: url(${header});
//background-repeat: no-repeat;

 background-image: 
    url(${header}),
    url(${footer});
  background-position:
    top right, /* this positions the first image */
    bottom right; /* this positions the second image */
    background-size: 
    210mm 250px,
    210mm 240px;
  background-repeat:
    no-repeat; /* this applies to both images */



width: 210mm;
//height: 297mm;
height: ${({itemHeight}) => `${itemHeight || '1123'}px`};
}

p {
font-size: 10pt;
line-height: 14pt;
}

img{
border-radius: 50%;
width: 150px;
height: 150px;
object-fit: cover;
//margin-top: 10px;
display: block;

margin-right: auto;

}
h2{
font-size: 3.5em;
font-weight: bold;
margin-top: 20px;
margin-bottom: 0px;
text-transform: uppercase;
}
h3{
font-size: 1.2em;
font-weight: 900;
margin-top: 0;
margin-bottom: 10px;
margin-left: 0em;
text-transform: uppercase;
}
h4{
font-size: 16pt;
margin-top: 0;
margin-bottom: 10px;
}
hr{
border-top: 1px solid;
border-color: ${({itemHrColor}) => itemHrColor || '#393E46'};
margin-bottom: 15px;
margin-top: 15px;
}
a{
text-decoration: none;
color: #393E46;
}
`
export const ContainerMain = styled.div`
padding: 20px 70px 70px 70px;
`
export const ContainerHeader = styled.div`
padding: 50px 70px 10px 70px;

a{
text-decoration: none;
color: white;
}
`
export const HrText = styled.hr`
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: .5;
&:before {
    content: '';
    // use the linear-gradient for the fading effect
    // use a solid background color for a solid bar
    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
&:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;
    
    padding: 0 .5em;
    line-height: 1.5em;
    // this is really the only tricky part, you need to specify the background color of the container element...
    color: #818078;
    background-color: #fcfcfa;
  }
`

export const ContainerRow = styled.div`
display: flex;
flex-direction: row;
justify-content: flex-start;
gap: 20px;
a{
text-decoration: none;
color: white;
}
`
export const ContainerRowHeader = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: space-between;
width: 100%;

//gap: 10px 0px;
`
export const HeaderChild = styled.div`
width: 31%;
text-align: center;
padding-top: 10px;
`

export const ContainerRowChild = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: space-between;
width: 100%;
gap: 10px 0px;
`
export const Child = styled.div`
margin-left: 0px;
margin-right: 10px;
width: 45%;
`
export const ContainerColumn = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
width: ${({itemColumnWidth}) => itemColumnWidth || '100%'};

`
export const ColorContainer = styled.div`
// background-color: ${({itemBackground}) => itemBackground || 'burlywood'};
background-color: ${({itemBackground}) => itemBackground || '#393E46'};
width: 100%;
bottom: 0;
color: white;

hr{
border-top: 1px solid;
border-color: ${({itemHrColor}) => itemHrColor || 'white'};
margin-bottom: 10px;
margin-top: 15px;
}
`
export const ColorText = styled.div`
// background-color: ${({itemBackground}) => itemBackground || 'burlywood'};
color: ${({itemColor}) => itemColor || '#393E46'};
width: 100%;
`
export const PaddingContainer = styled.div`
padding: 5px 10px;
`
export const TextCenter = styled.div`
text-align: center;
`

export const ProgressBarWrapper = styled.div`
  background: lightgrey;
  margin-top: 5px;
  height: 5px;
  width: 100%;
`

ProgressBarWrapper.ProgressBar = styled.div`
  background-color: ${({itemColor}) => itemColor || '#393E46'};
  height: 5px;
  width:  ${({itemWidth}) => `${itemWidth || '100'}%`}; 
`
export const SkillsStyle = styled.ul`
padding-left: 15px;
margin-top: 0px;
li{
padding-left: 0px;
}
`
const Section = styled.div`
padding: 20px 0;
`
;

export const Header = styled.div`
background-image: url(${header});
background-size: cover;
`
export const Footer = styled.div`
background-image: url(${footer});
background-repeat: no-repeat;
margin-bottom: 0px;
height: 100%;

`