import styled from 'styled-components'


const MainHalfWrapper = styled.div`
width: 49%;
//@media (max-width: 1220px) {
//width: 48%;
//    } 
//@media (max-width: 1024px) {
//width: 48%;
//    } 
//@media (max-width: 800px) {
//width: 98%;
//    }  
@media (max-width: 1024px) {
width: 47%;
    }  
    @media (max-width: 900px) {
width: 46%;
    }
@media (max-width: 800px) {
width: 98%;
    }  
`;
export default MainHalfWrapper;
