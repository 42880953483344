import React from 'react'
import Wrapper from "../form.styles/Wrapper";
import HalfWrapper from "../form.styles/HalfWrapper";
import LabelText from "../form.styles/LabelText";
import InputText from "../form.styles/InputText";


const References = (props) => {
  const [referenceName, setReferenceName] = React.useState('');
  const [referencePosition, setReferencePosition] = React.useState('');
  const [referencePhone, setReferencePhone] = React.useState('');
  const [referenceEmail, setReferenceEmail] = React.useState('');

  const getObj = () => ({
    referenceName,
    referencePosition,
    referencePhone,
    referenceEmail
  });
  React.useEffect(() => {
    props.onChange(getObj());
  },[referenceName,
    referencePosition,
    referencePhone,
    referenceEmail])
  return(
    <>

      <Wrapper>
        <HalfWrapper>
          <LabelText>Referent's Full Name</LabelText>
          <InputText
            type='text'
            value={referenceName}
            onChange={(e) => setReferenceName(e.target.value)}/>
        </HalfWrapper>
        <HalfWrapper>
          <LabelText>Company / position</LabelText>
          <InputText
            type='text'
            value={referencePosition}
            onChange={(e) => setReferencePosition(e.target.value)}/>
        </HalfWrapper>
      </Wrapper>
      <Wrapper>
        <HalfWrapper>
          <LabelText>Phone</LabelText>
          <InputText
            type='tel'
            value={referencePhone}
            onChange={(e) => setReferencePhone(e.target.value)}/>
        </HalfWrapper>
        <HalfWrapper>
          <LabelText>Email</LabelText>
          <InputText
            type='email'
            value={referenceEmail}
            onChange={(e) => setReferenceEmail(e.target.value)}/>
        </HalfWrapper>
      </Wrapper>
      </>
  )
}
export default References;