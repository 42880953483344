import styled from 'styled-components'


export const ContainerRowMain = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: space-between;
gap: 20px;
align-items: center;
margin-top: 40px;
`

export const ContainerRowMainReverse = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap-reverse;
justify-content: space-between;
gap: 20px;
align-items: center;
margin-top: 40px;
`
