import React from 'react';

import LinkStyle from "../styles/LinkStyle";
import jobsData from "../data/JobsData";
import {observer} from "mobx-react-lite";
import {
  FooterCategories,
  FooterCategoriesChild,
  FooterStyle,
  FooterStyleContent,
  LinkStyleCategory
} from "./FooterStyle";
import {Link} from "react-router-dom";
import ButtonCategories from "../styles/ButtonCategories";
import ContainerRowStart from "../styles/ContainerRowStart";
import {faCaretRight, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const Footer = () => {
  // React.useEffect(() => {
  //   jobsData.getCategories()
  // },[])
  return(
    <>
      <FooterStyle>
        <FooterStyleContent>
          <FooterCategories>
        {/*{jobsData.categoriesName.map(el => <FooterCategoriesChild>{el}</FooterCategoriesChild>)}*/}
            {jobsData.categoriesName.map(el => el == 'All categories' ? <FooterCategoriesChild><LinkStyleCategory key={el} to={`categories`}><FontAwesomeIcon icon={faCaretRight}/> {el} jobs</LinkStyleCategory></FooterCategoriesChild>
              : <FooterCategoriesChild><LinkStyleCategory key={el} to={`categories/${el.replace('/ ',' ')}`}><FontAwesomeIcon icon={faCaretRight}/> {el} jobs</LinkStyleCategory></FooterCategoriesChild>)}
          </FooterCategories>
          <hr/>
          <p>Created by Olga</p>
        </FooterStyleContent>
      </FooterStyle>
      </>
  )
}
export default observer(Footer);