import styled from 'styled-components'


const SliderContainer = styled.div`
position: relative;
text-align: center;
color: white;
font-size: 14px;
text-shadow: 5px 5px 20px black;
overflow: hidden;
@media (max-width: 1200px) {
    font-size: 30px;
  }
@media (max-width: 768px) {
    font-size: 14px;
  }

`;
SliderContainer.TextCenter = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
SliderContainer.Left = styled.div`
  position: absolute;
  top: 50%;
  left: 16px;
`
SliderContainer.Right = styled.div`
  position: absolute;
  top: 50%;
  right: 32px;
`
export default SliderContainer;