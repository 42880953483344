import React from 'react';
import {useParams} from "react-router";
import jobsData from "../data/JobsData";
import {observer} from "mobx-react-lite";
import Container from "../styles/Container";
import CardList from "../styles/CardList";
import ContainerRowTop from "../styles/ContainerRowTop";
import Column from "../styles/Column";
import TextOrange from "../styles/TextOrange";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCircleCheck,
  faClock,
  faEarthAmericas,
  faFileInvoiceDollar, faMagnifyingGlass
} from "@fortawesome/free-solid-svg-icons";
import {faCalendarDays} from "@fortawesome/free-regular-svg-icons";
import {Link} from "react-router-dom";
import ButtonOrange from "../styles/ButtonOrange";
import LinkStyle from "../styles/LinkStyle";
import ButtonCategories from "../styles/ButtonCategories";
import ContainerRowStart from "../styles/ContainerRowStart";
import CardCategory from "../styles/CardCategory";
import Breadcrumbs from "../styles/Breadcrumbs";
import Helmet from "react-helmet";
import InputText from "../exportpdf/form.styles/InputText";
import {InputSearch, SearchBar, SearchIcon} from "../exportpdf/form.styles/InputSearch";
import {ButtonLoadMore, LoadMoreBox} from "../exportpdf/form.styles/ButtonLoadMore";



const itemPerRow = 10;

const ByCategory = () => {
  const [query, setQuery] = React.useState("");
  console.log('hello')
  let {category} = useParams();
  const [oneCategory, setOneCategory] = React.useState();
  const [next, setNext] = React.useState(itemPerRow);

  const onOtherJobClick = () => {
    window.scrollTo(0, 0);
  };

  console.log('work')
  React.useEffect(() => {
    jobsData.getCategoriesJobs(category).then(setOneCategory);
    jobsData.update()
  }, [category]);
  if (!oneCategory) return null;

const count = oneCategory.length;


  const handleMoreItems = () => {
    setNext(next + itemPerRow);
  }

  return(
    <>
      <Helmet>
        <title>Top Work | All categories</title>
        <meta name="description" content="Here you can find thousands of jobs and create your professional resume as a bonus" />
        <meta name='keywords' content="jobs, job, hiring, jobs hiring, find a job, resume, cv, create resume, create cv, Software Development, Customer Service, Design, Marketing, Sales, Product, Business, Data, DevOps, Sysadmin, Finance, Legal, Human Resources, QA, Writing"/>
      </Helmet>

      <Container>
        <Breadcrumbs>
          <Breadcrumbs.Link to='/'>Home</Breadcrumbs.Link> >
          <Breadcrumbs.Link to=''>Jobs</Breadcrumbs.Link> >
          <Breadcrumbs.Link to=''>{category}</Breadcrumbs.Link>
        </Breadcrumbs>

        <SearchBar>
          <SearchIcon><TextOrange><FontAwesomeIcon icon={faMagnifyingGlass}/></TextOrange></SearchIcon>
          <InputSearch placeholder="Search..." onChange={event => setQuery(event.target.value)}/>
        </SearchBar>

        <h1> {category} <TextOrange>{count} jobs</TextOrange></h1>
          {/*{category ? oneCategory.map(el => <p>{el.category}</p>) : jobsData.jobs.map(el => <p>{el.category}</p>)}*/}

        {/*{jobsData.categoriesName.map(el => el == 'All categories' ? <LinkStyle key={el} to={`/categories`}><ButtonCategories>{el}</ButtonCategories></LinkStyle>*/}
        {/*  : <LinkStyle key={el} to={`/categories/${el.replace('/ ',' ')}`}><ButtonCategories>{el}</ButtonCategories></LinkStyle>)}*/}



        <ContainerRowStart>
          {jobsData.categoriesName.map(el => el == 'All categories' ? <Link key={el} to={`../`}><ButtonCategories>{el}</ButtonCategories></Link>
            : <Link key={el} to={`../${el.replace('/ ',' ')}`}><ButtonCategories>{el}</ButtonCategories></Link>)}
        </ContainerRowStart>




          {oneCategory.filter(job => {
            if (query === '') {
              return job;
            } else if (job.title.toLowerCase().includes(query.toLowerCase().trim()) || job.company_name.toLowerCase().includes(query.toLowerCase().trim())) {
              return job;
            }
          }).slice(0,next).map(job => <LinkStyle key={job.id} to={`/jobs-list/${job.id}`} onClick={onOtherJobClick}>
              <CardList>
                <ContainerRowTop>
                  <Column itemWidth='80%'>
                    <h2>{job.title}</h2>
                    <h4><TextOrange><FontAwesomeIcon icon={faBuilding}/></TextOrange> {job.company_name}      {job.salary !== '' ? <><TextOrange><FontAwesomeIcon icon={faFileInvoiceDollar}/></TextOrange> {job.salary}</> : null}</h4>
                    <p>{job.candidate_required_location !== '' ? <> <TextOrange><FontAwesomeIcon icon={faEarthAmericas}/></TextOrange>  {job.candidate_required_location}</> : null}    {job.job_type !== '' ? <><TextOrange><FontAwesomeIcon icon={faClock}/></TextOrange> {job.job_type.replace('_',' ')}</> : null}</p>
                    <p><TextOrange><FontAwesomeIcon icon={faCircleCheck}/> {job.category}</TextOrange></p>
                    <p><TextOrange><FontAwesomeIcon icon={faCalendarDays}/></TextOrange> Published: {job.publication_date.slice(0, 10)}</p>
                  </Column>
                  <Column itemWidth='20%'>
                    <CardList.Logo> <img src={job.company_logo}/></CardList.Logo>
                    <p><Link key={job.id} to={`/jobs-list/${job.id}`} onClick={onOtherJobClick}><ButtonOrange>VIEW JOB</ButtonOrange></Link></p>
                  </Column>
                {/*  <Column itemWidth='80%'>*/}
                {/*    <h2>{job.title}</h2>*/}
                {/*    <h4><TextOrange><FontAwesomeIcon icon={faBuilding}/></TextOrange> {job.company_name}  {job.salary !== '' ? <><TextOrange><FontAwesomeIcon icon={faFileInvoiceDollar}/></TextOrange> {job.salary}</> : null}</h4>*/}
                {/*    <p>{job.candidate_required_location !== '' ? <> <TextOrange><FontAwesomeIcon icon={faEarthAmericas}/></TextOrange>  {job.candidate_required_location}</> : null}   {job.job_type !== '' ? <><TextOrange><FontAwesomeIcon icon={faClock}/></TextOrange> {job.job_type.replace('_',' ')}</> : null}</p>*/}
                {/*    <p><TextOrange><FontAwesomeIcon icon={faCircleCheck}/> {job.category}</TextOrange></p>*/}
                {/*  </Column>*/}
                {/*  <Column itemWidth='20%'>*/}
                {/*    <CardList.Logo> <img src={job.company_logo}/></CardList.Logo>*/}
                {/*  </Column>*/}
                {/*</ContainerRowTop>*/}
                {/*<ContainerRowTop>*/}
                {/*  <Column itemWidth='50%'>*/}
                {/*    <p><TextOrange><FontAwesomeIcon icon={faCalendarDays}/></TextOrange> Published: {job.publication_date.slice(0, 10)}</p>*/}
                {/*  </Column>*/}
                {/*  <Column itemWidth='20%'>*/}
                {/*    <p><Link key={job.id} to={`/jobs-list/${job.id}`} onClick={onOtherJobClick}><ButtonOrange>VIEW JOB</ButtonOrange></Link></p>*/}
                {/*  </Column>*/}
                </ContainerRowTop>

              </CardList>
            </LinkStyle>
          )}
        {/*<h2>{oneCategory.name}</h2>*/}


        <LoadMoreBox>{next < oneCategory.length && (
          <ButtonLoadMore onClick={handleMoreItems}>Load more...</ButtonLoadMore>
        )}
        </LoadMoreBox>

      </Container>
      </>
  )
}
export default observer(ByCategory);