import styled from 'styled-components'
import {Link} from "react-router-dom";

const LinkStyle = styled(Link)`
color: var(--darkblue);
text-decoration: none;
font-weight: normal;
&:hover{
color: var(--darkblue);
text-decoration: none;
font-weight: normal;
}
`
export default LinkStyle;