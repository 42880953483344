import React from 'react'
import ButtonEdit from "../form.styles/ButtonEdit";
import ButtonLinkEdit from "../form.styles/ButtonLinkEdit";


const EditLink = React.memo(props => {
  const {el, name, type, tooltip} = props;

  const handleClick = React.useCallback(
    function exec() {
      document.execCommand(type, false, el)
    },
    [el, type]
  )
  return <div className="hover-text">
    <ButtonLinkEdit onClick={handleClick}>{name}</ButtonLinkEdit>
  </div>
})
export default EditLink;