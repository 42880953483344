import React from 'react'
import ButtonEdit from "../form.styles/ButtonEdit";


const Edit = React.memo(props => {
  const {el, name, type, tooltip} = props;

  const handleClick = React.useCallback(
    function exec() {
      document.execCommand(type, false, el)
    },
    [el, type]
  )
  return <div className="hover-text">
    <ButtonEdit onClick={handleClick}>{name}</ButtonEdit>
    <span className="tooltip-text bottom">{tooltip}</span>
  </div>
})
export default Edit;