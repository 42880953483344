import React from 'react';
import jobsData from "../data/JobsData";
import {useParams} from "react-router";
import {observer} from "mobx-react-lite";
import Container from "../styles/Container";
import ContainerRow from "../styles/ContainerRow";
import Column from "../styles/Column";
import TextOrange from "../styles/TextOrange";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCircleCheck,
  faClock,
  faEarthAmericas,
  faFileInvoiceDollar,
  faLocationDot
} from "@fortawesome/free-solid-svg-icons";
import StickyContainer from "../styles/StickyContainer";
import ButtonOrange from "../styles/ButtonOrange";
import CardList from "../styles/CardList";
import {faCalendarDays} from "@fortawesome/free-regular-svg-icons";
import LinkStyle from "../styles/LinkStyle";
import ContainerRowTop from "../styles/ContainerRowTop";
import {Link} from "react-router-dom";
import InterviewQuestions from "../pages/InterviewQuestions";
import Breadcrumbs from "../styles/Breadcrumbs";


const OneJob = () => {
  let {id} = useParams();
  const [job, setJob] = React.useState();

  const onOtherJobClick = () => {
    window.scrollTo(0, 0);
  };

  React.useEffect(() => {
    jobsData.getJob(id).then(setJob);
  }, [id]);

  if (!job) return null;


  const companyObject = jobsData.jobs.filter(company => company.company_name === job.company_name)
  return (
    <>
      <Container>
        <Breadcrumbs>
          <Breadcrumbs.Link to='/'>Home</Breadcrumbs.Link> >
          <Breadcrumbs.Link to='/companies'>Companies</Breadcrumbs.Link> >
          <Breadcrumbs.Link key={job.company_name} to={`/companies/${job.company_name}`}>{job.company_name}</Breadcrumbs.Link> >
          <Breadcrumbs.Link to={``}>{job.title}</Breadcrumbs.Link>
        </Breadcrumbs>
        <ContainerRow>
       <Column itemWidth='70%'>
        <h1>{job.title}</h1>
        <div dangerouslySetInnerHTML={{__html: job.description}} />
         <TextOrange><b>Tags:</b></TextOrange> <CardList.TagsContainer>{job.tags.map((tag) => <li>{tag}</li>)}</CardList.TagsContainer>



         {companyObject.length > 1 ? <><h2>More jobs from {job.company_name}</h2>
         {companyObject.map(company => <>{company.title !== job.title ?
           <LinkStyle key={company.id} to={`/jobs-list/${company.id}`} onClick={onOtherJobClick}>
             <CardList  itemMarginTop='10px'>
               <ContainerRowTop>
                 <Column itemWidth='80%'>
                   <h2>{company.title}</h2>
                   <h4><TextOrange><FontAwesomeIcon icon={faBuilding}/></TextOrange> {company.company_name}</h4>
                   {company.candidate_required_location !== '' ? <p> <TextOrange><FontAwesomeIcon icon={faEarthAmericas}/></TextOrange>  {company.candidate_required_location}</p> : null}
                   <p><TextOrange><FontAwesomeIcon icon={faCircleCheck}/> {company.category}</TextOrange></p>
                 </Column>
                 <Column itemWidth='20%'>
                    <CardList.Logo> <img src={company.company_logo}/></CardList.Logo>
                 </Column>
               </ContainerRowTop>
               <ContainerRowTop>
                 <Column itemWidth='50%'>
                   {company.job_type !== '' ? <p><TextOrange><FontAwesomeIcon icon={faClock}/></TextOrange> {company.job_type.replace('_',' ')}</p> : null}
                   <p><TextOrange><FontAwesomeIcon icon={faCalendarDays}/></TextOrange> Published: {company.publication_date.slice(0, 10)}</p>
                 </Column>
                 <Column itemWidth='20%'>
                   <p><Link key={company.id} to={`/jobs-list/${company.id}`}><ButtonOrange>VIEW JOB</ButtonOrange></Link></p>
                 </Column>
               </ContainerRowTop>
               <Column>
                 {company.salary !== '' ? <p><TextOrange><FontAwesomeIcon icon={faFileInvoiceDollar}/></TextOrange> {company.salary}</p> : null}
               </Column>
               {/*<CardList.TagsContainer>*/}
               {/*  {job.tags.map((tag) => <li>{tag}</li>)}*/}
               {/*</CardList.TagsContainer>*/}
             </CardList></LinkStyle>

           : null}</>)}</> : null}

       </Column>
          <Column itemWidth='27%'>
            <StickyContainer>
              <LinkStyle key={job.company_name} to={`/companies/${job.company_name}`}><img src={job.company_logo}/></LinkStyle>
              <LinkStyle key={job.company_name} to={`/companies/${job.company_name}`}><h3><TextOrange><FontAwesomeIcon icon={faBuilding}/></TextOrange> {job.company_name}</h3></LinkStyle>
            <p>
              <TextOrange><FontAwesomeIcon icon={faLocationDot}/></TextOrange>  <b>{job.candidate_required_location}</b> </p>
            <p><TextOrange><FontAwesomeIcon icon={faCircleCheck}/></TextOrange> <b>{job.category}</b></p>
              <p><TextOrange><FontAwesomeIcon icon={faClock}/></TextOrange> <b>{job.job_type.replace('_',' ')}</b></p>
              <p><TextOrange><FontAwesomeIcon icon={faCalendarDays}/></TextOrange> <b>Published: {job.publication_date.slice(0, 10)}</b></p>

              <a href={job.url} target="_blank"><ButtonOrange>Apply</ButtonOrange></a>
            </StickyContainer>

          </Column>
        </ContainerRow>



      </Container>

    </>
  )
}
export default observer(OneJob);