import styled from 'styled-components'


const ContainerRowCompanies = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: space-between;
//align-content: flex-start;

`;
export default ContainerRowCompanies;