import styled from 'styled-components'

export const BlogImage = styled.img`
width: 100%;
max-width: 800px;
height: auto;
`;
export const BlogImageLeft = styled.img`
width: 100%;
max-width: 800px;
height: auto;
float: left;
  @media (max-width: 2000px) {
max-width: 50%;
  }
  @media (max-width: 800px) {
max-width: 100%;
  }
`
export const BlogImageRight = styled.img`
width: 100%;
max-width: 800px;
height: auto;
float: right;
  @media (max-width: 2000px) {
max-width: 50%;
  }
  @media (max-width: 800px) {
max-width: 100%;
  }
`
